import styles from "./AnalysisPanel.module.css";

import { AskResponse } from "../../api";
import { SupportingContent } from "../SupportingContent";
import { AnalysisPanelTabs } from "./AnalysisPanelTabs";
import { Link } from "@fluentui/react-components";
import { useEffect, useState } from "react";

interface Props {
    className: string;
    onActiveTabChanged: (tab: AnalysisPanelTabs) => void;
    activeCitation: string[] | undefined;
    answer: AskResponse;
    settingsButtonVisible: boolean;
}


export const AnalysisPanel = ({ answer, activeCitation }: Props) => {
    const [webaddress, setWebaddress] = useState<string>();
    //activeCitation is an array that contains 0:filePath, 1:documentID, 2: documentName

    useEffect(() => {
        if (activeCitation?.[2].split(".").pop() == "url") {
            const regex = /webpage-(.*)\.url/;
            const match = activeCitation[2].match(regex);
            const webAddress = match?.[1];
            setWebaddress(`https://${webAddress}`);
        }
    }, [])


    return (
        <>
            <p
                className={styles.sourceHeadingText}
            >
                Blue Edge AI reads through your documents and selects what it feels is relevant source text from each document to use. Below we display the source text used in this document.</p>

            <h3>{activeCitation?.[2]}</h3>
            <div className={styles.sourceViewer}>
                <SupportingContent selectedSource={activeCitation?.[1]} supportingContent={answer.data_points} />
                <div className={styles.documentPreview}>
                    {activeCitation?.[2].split(".").pop() == "doc" || activeCitation?.[2].split(".").pop() == "docx" ?
                        <div className={styles.wordPreview}>
                            <p>No preview available</p>

                            <Link href={activeCitation[0]}>Click here to download</Link>
                        </div>
                        : activeCitation?.[2].split(".").pop() == "url" ? (
                            <div className={styles.wordPreview}>
                                <p>No preview available</p>

                                <Link target="_blank" href={webaddress}>Click here to view web page</Link>
                            </div>
                        )
                            :
                            <iframe
                                title="Citation"
                                src={activeCitation?.[0]}
                                className={styles.iframe}
                                style={{ width: "100%", height: "100%" }}
                            />

                    }
                </div>
            </div>
        </>
    );
};
