/* eslint-disable @typescript-eslint/no-unused-vars */

// This is page that the user is redirected to after logging in.
// We use it to launch into a quick tool or setup or resume a project.

import { useAuth0 } from "@auth0/auth0-react";
import {
    Button,
    Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger,
    Image, Input,
    Spinner,
    Text,
    Textarea
} from '@fluentui/react-components';
import { ChevronRightRegular, DeleteRegular, Dismiss24Regular, FolderRegular, SettingsRegular } from "@fluentui/react-icons";
import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid'; // to generate a new thread_id for each chat session
import { createProjectRecordApi, CustomJwtPayload, deleteProjectRecordApi, getAllTemplateCategoriesApi, getClientLogoUrlApi, getProjectRecordsApi, getPromptTemplatesApi, PersonaTemplate, Project, TemplateCategory, updateProjectFieldApi } from "../../api";
import ClientLogoTransparent from "../../assets/ClientLogo.png";
import { MaintenanceMode } from "../../components/MaintenanceMode";
import { ManagementToolbar } from "../../components/ManagmentToolbar";
import { PersonaIcon } from "../../components/PersonaIcon";
import UserProfile from "../../components/UserProfile/UserProfile";
import styles from "./Hello.module.css";
// tutorial system
import "@sjmc11/tourguidejs/src/scss/tour.scss";
import { Analytics } from "../../components/Analytics";
import { GizmoList } from "../../components/GizmoList/GizmoList";
import ToolSelector from "../../components/ToolSelector/ToolSelector";
import { MyLibrary } from "../../components/MyLibrary/MyLibrary";
import { PageHeader } from "../../components/PageHeader/PageHeader";
import { CollectionManagerPopup } from "../../components/CollectionManagerPopup";
import { PageFooter } from "../../components/PageFooter/PageFooter";





const Hello = () => {
    const { isAuthenticated, user, isLoading, getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    const [isAuthorizedToViewAdminMenu, setIsAuthorizedToViewAdminMenu] = useState(false);
    const [isAuthorizedToViewDocumentCollectionsManager, setIsAuthorizedToViewDocumentCollectionsManager] = useState<boolean>(false);
    const [error, setError] = useState<unknown>(); // error when retreiving projects
    const [personasList, setPersonasList] = useState<PersonaTemplate[]>([])
    const [selectedPersona, setSelectedPersona] = useState<PersonaTemplate>(personasList[0]);
    const [NoRoleError, setNoRoleError] = useState<boolean>(false);
    const [open, setOpen] = React.useState(false); // open the edit project menu
    const [projectSetup, setProjectSetup] = useState(false);
    const [projectName, setProjectName] = useState('') // name of the project
    const [newProjectId, setNewProjectId] = useState('') // name for the backend
    const [projectDescription, setProjectDescription] = useState('') // project description
    const [projectGizmo, setProjectGizmo] = useState<PersonaTemplate>() // selected gizmo for project
    const [projects, setProjects] = useState<Project[]>([]);
    const [loadingProjects, setLoadingProjects] = useState<boolean>(true);
    const [selectedProject, setSelectedProject] = useState<Project>();
    const [refreshCollection, setRefreshCollection] = useState<boolean>(false);
    const [clientLogoURL, setClientLogoURL] = useState<string>('');

    // redirect to login if not authenticated
    useEffect(() => {
        if (isAuthenticated) {
            // If Authenticated Replace the current entry in the history stack.
            window.history.replaceState(null, '', '/#/hello');
        } else {
            console.log("Trigger redirect to login page at [hello.tsx]");
            // navigate("/login")
        }
    }, [isAuthenticated]);

    // Fetch project records
    const fetchProjectRecords = async (): Promise<Project[]> => {
        const access_token = await getAccessTokenSilently();
        try {
            // Call the function to get all project records
            const allProjects = await getProjectRecordsApi(access_token);

            // Check if allProjects is null or undefined
            if (!allProjects || !Array.isArray(allProjects)) {
                console.error('No project records found.');
                return [];
            }

            // Transform the fetched data to match the Project model
            const collectedProjects: Project[] = allProjects
                .filter(myProjects => myProjects.owner === user?.email)
                .map((project) => ({
                    id: project.id,
                    project_name: project.project_name,
                    project_description: project.project_description,
                    owner: project.owner,
                    project_tool_name: project.project_tool_name,
                    project_tool_id: project.project_tool_name,
                    project_collections: project.project_collections,
                    last_updated: project.last_updated,
                    prompts: project.prompts,
                    results: project.results
                }));

            // Log or return the transformed project records
            return collectedProjects;
        } catch (error) {
            // Handle any errors that occur during the fetch
            console.error('Error fetching project records:', error);
            throw error;
            return [];
        }
    };

    // useEffect to fetch project records when loadingProjects changes
    useEffect(() => {
        // Define an async function to fetch and set the data
        const loadProjects = async () => {
            try {
                const fetchedProjects = await fetchProjectRecords();
                setProjects(fetchedProjects); // Set the fetched projects
            } catch (error) {
                setError('Failed to fetch projects');
            } finally {
                setLoadingProjects(false);
                localStorage.removeItem('project')
                localStorage.removeItem('quick')
            }
        };

        loadProjects(); // Call the async function
    }, [loadingProjects]);

    //select a project to continue
    const handleSelectProject = async (project: Project) => {
        setSelectedProject(project);
        localStorage.setItem('project', project.id)
        navigate("/ai")
    }

    // Handle the selection of a quick gizmo
    const handleSelectQuickGizmo = async (gizmo: PersonaTemplate) => {
        setSelectedProject(undefined); // clear selected project
        localStorage.setItem('quick', gizmo.id) // save to local storage
        //   console.log("Gizmo saved: ", gizmo.id);
        navigate("/ai")
    }


    // Update the project name and description when a project is selected or changed
    useEffect(() => {
        if (selectedProject) {
            // Your logic here
            setProjectName(selectedProject.project_name);
            setProjectDescription(selectedProject.project_description);
        }
    }, [selectedProject]); // This will run whenever selectedProject changes

    // handle edit project button
    const handleEditProject = async (project: Project) => {
        await setSelectedProject(project);
        setOpen(true)
    }

    // handle project cancel button
    const handleCancelProject = () => {
        setSelectedProject(undefined);
        setProjectDescription('');
        setProjectName('');
        setProjectGizmo(undefined);
        setProjectSetup(false);
        setOpen(false);
        localStorage.removeItem('project')
    }

    // handle a new project creation
    const handleNewProject = async (persona: PersonaTemplate) => {
        //  console.log("handle new project");
        try {
            await createNewProject(persona);
        } finally {
            navigate("../ai")
        }
    }

    // update project record info
    const updateProjectRecord = async (field: keyof Project, value: string) => {
        if (!selectedProject) {
            console.error("Cannot update record: No project selected")
            return
        }
        const project_id = selectedProject.id;
        //   console.log(`Updating field: "${field}" with value: "${value}" for project: "${project_id}"`);
        try {
            const accessToken = await getAccessTokenSilently();
            const response = await updateProjectFieldApi(project_id, field, value, accessToken);
            //       console.log('Project updated:', field, value, response);
        } catch (error) {
            console.error('Error updating project:', error);
        } finally {
            //       console.log('DONE');
            setOpen(false)
            handleCancelProject();
            setLoadingProjects(true);
        }
    };

    const handleSaveChangesToProject = async () => {
        await updateProjectRecord("project_name", projectName);
        updateProjectRecord("project_description", projectDescription);
    };

    const deleteProject = async (project_id: string) => {
        try {
            const accessToken = await getAccessTokenSilently();
            const response = await deleteProjectRecordApi(project_id, accessToken);
            //      console.log("Project deleted successfully:", response);
        } catch (error) {
            console.error("Error deleting project:", error);
        }
    };

    const handleDeleteProject = async () => {
        if (!selectedProject) {
            console.error("No project selected, cannot delete");
            return
        }
        deleteProject(selectedProject.id);
        setOpen(false)
        handleCancelProject();
        setLoadingProjects(true);
    }


    // Get permissions
    useEffect(() => {
        const checkRole = async () => {
            try {
                const token = await getAccessTokenSilently();
                const decodedToken = jwtDecode<CustomJwtPayload>(token);
                const permissions = decodedToken.permissions;
                // Document Collections Manager
                if (permissions.includes('view:Dashboard')) {
                    setIsAuthorizedToViewDocumentCollectionsManager(true);
                }
                // Admin Settings Menu
                if (permissions.includes('view:QuickSettingsMenu')) {
                    setIsAuthorizedToViewAdminMenu(true);
                }
                //if no role assigned
                if (!permissions.includes('read:Files')) {
                    setNoRoleError(true);
                }
            } catch (e) {
                // Handle errors - e.g. token is invalid, expired, etc.
                console.error(e);
                alert("There was an issue verifying your credentials. Please log in again.")
                navigate("/login");
            }
        }
        checkRole();
    }, [])


    // Get Personas
    const loadPersonas = async () => {
        try {
            const token = await getAccessTokenSilently();
            if (token) {
                const personas = await getPromptTemplatesApi(token);
                setPersonasList(personas);
                // Try to get the selected persona from local storage
                const storedPersona = localStorage.getItem('selectedPersona');
                // If the stored persona exists in the list, select it
                // Otherwise, select the first persona
                const personaToSelect = personas.find(persona => persona.name === storedPersona) || personas[0];
                setSelectedPersona(personaToSelect);
            }
        } catch (error) {
            console.error('Failed to retrieve access token silently', error);
            setPersonasList([]) // blank to avoid render error
        }
    };

    // load personas on mount
    useEffect(() => {
        loadPersonas();
    }, []);


    //project name onchange
    const projectNameOnChange = (event: React.FormEvent<HTMLInputElement>) => {
        const newValue = event.currentTarget.value;

        if (newValue !== undefined) {
            // Update the display name state
            setProjectName(newValue);
        }

    }

    //project description onchange
    const projectDescriptionOnChange = (event: React.FormEvent<HTMLTextAreaElement>) => {
        const newValue = event.currentTarget.value;
        setProjectDescription(newValue);
    }

    //Create new project
    const createNewProject = async (persona: PersonaTemplate) => {
        //    console.log("MAKING NEW PROJECT");
        const projectId = await uuidv4();

        try {
            const token = await getAccessTokenSilently();
            const response: string = await createProjectRecordApi(
                projectId, //id
                '', //project_name
                '', // project_description
                user?.email || 'emailAddress', //owner
                persona?.name || 'none', //project_tool_name
                persona?.id || 'none', //project_tool_id
                '', // project_collections
                Date(), // last_updated
                '', // prompts
                [], // results
                token,
            );
            //      console.log("New Project Record: " + response);
            setNewProjectId(projectId)
            localStorage.setItem('project', projectId);
        } catch (error) {
            console.error('Error creating project:', error);
        }
    }


    // Auth
    if (isLoading) {
        return <div className='loading'><Spinner size='large' label='Loading' labelPosition="below" /></div>;
    }

    // Function to Fetch the client logo url from the server
    const fetchClientLogoSettings = async () => {
        const token = await getAccessTokenSilently();
        const client_logo = await getClientLogoUrlApi(token); // fetch the data
        if (client_logo && client_logo.length > 0) {
            const record = client_logo[0]; // get just the last record if multiple exist
            setClientLogoURL(record.clientLogoID); // any value other than 'true' from the db is considered false
        }
    };

    // load client logo on mount
    useEffect(() => {
        fetchClientLogoSettings();
    }, []);




    return (
        <div className={styles.container}>
            <PageHeader selectedTool={undefined} />

            {/* invisible components */}
            <Analytics />
            <MaintenanceMode />

            {/*error: no role message*/}
            <Dialog open={NoRoleError} modalType="alert">
                <DialogSurface mountNode={undefined}>
                    <DialogBody>
                        <DialogTitle>Account not yet verified</DialogTitle>
                        <DialogContent>
                            You account provisioning has now started and is normally completed within 20 minutes but can take up to 24 hours.
                            You will receive an email notification once your account has been fully setup and you can start using the app. Contact <a href="emailto:support@blue-edge.ai">support@blue-edge.ai</a> if you are still receiving this message 24 hours after sign up.
                        </DialogContent>
                    </DialogBody>
                </DialogSurface>
            </Dialog>

            {/* Edit project popover */}
            <Dialog open={open}>
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle
                            action={
                                <DialogTrigger action="close">
                                    <Button
                                        onClick={() => setOpen(false)}
                                        appearance="subtle"
                                        aria-label="close"
                                        icon={<Dismiss24Regular />}
                                    />
                                </DialogTrigger>
                            }
                        >
                            Edit Project
                        </DialogTitle>
                        <DialogContent>
                            <div className={styles.projectSettings}>
                                <div className={styles.projectSettingsSub}>
                                    <p className={styles.gizmoComponentDescription}>Project Name: </p>
                                    <Input id={"projectName"} value={projectName} onChange={projectNameOnChange} placeholder="Name your project..." />
                                </div>
                                <div className={styles.projectSettingsSub}>

                                    <p className={styles.gizmoComponentDescription}>Project Description: </p>
                                    <Textarea id={"projectName"} value={projectDescription} onChange={projectDescriptionOnChange}
                                        placeholder="A good description can help organise your projects" />
                                </div>
                            </div>
                            <Button icon={<DeleteRegular />} onClick={() => handleDeleteProject()}>DELETE</Button>
                        </DialogContent>
                        <DialogActions>
                            <Button appearance="secondary" onClick={handleCancelProject}>Cancel</Button>
                            <Button appearance="primary" onClick={() => handleSaveChangesToProject()
                            }>Save</Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>

            {/* Main section */}
            <div className={styles.sectionFull}>
                <div className="ms-Grid">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-lg4">
                            <div className={styles.mainContainer}></div>
                            {isAuthorizedToViewAdminMenu ?
                                <ManagementToolbar />
                                : ''
                            }

                            <h1 className={styles.helloHeading} >Hello.</h1>
                            <div className={styles.clientLogoContainer}><Image src={clientLogoURL} className={styles.clientLogo} /></div>
                            <p className={styles.gizmoComponentDescription}>Let's get going...</p>
                            <p className={styles.gizmoComponentDescription}>Choose from the list of tools that best suits your task</p>
                            {/* Toolbar TODO: permissions check if admin to display*/}
                            {/* <Button onClick={() => setLoadingProjects(true)}>Refresh</Button> */}
                            {isAuthorizedToViewDocumentCollectionsManager ?
                                <CollectionManagerPopup
                                    setRefreshCollection={setRefreshCollection}
                                    refreshCollection={refreshCollection}
                                /> : ''
                            }
                            {/*Library section*/}
                            {/* <h3 style={{ color: "var(--primary-color)" }}>My Library <FolderRegular /></h3> */}
                            {/* <MyLibrary
                                projectList={projects}
                                personasList={personasList}
                                loadingProjects={loadingProjects}
                                setLoadingProjects={setLoadingProjects}
                            /> */}

                        </div>

                        {/* My Projects section */}
                        <div className="ms-Grid-col ms-lg8">

                            {/* New tool selector first retrieves all categories then sorts the tools*/}
                            <div className="ms-Grid-col ms-lg12">
                                <h3 className={styles.gizmoComponentSubheading}>What would you like to do today?</h3>
                                <ToolSelector
                                    personasList={personasList}
                                    handleSelectQuickGizmo={handleNewProject}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >

    );
};

export default Hello;
