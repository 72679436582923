import { Stack } from "@fluentui/react";
import { ChatDismissRegular, ChevronRightRegular } from "@fluentui/react-icons";
import { useEffect, useRef, useState } from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { Button, Field, Link, ProgressBar, Textarea, TextareaOnChangeData, Toast, ToastBody, Toaster, ToastTitle, useId, useToastController } from "@fluentui/react-components";
import { improveQuestionApi, PersonaTemplate } from "../../api";
import styles from "./QuestionInput.module.css";



interface Props {
    onSend: (question: string) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    selectedPersona?: PersonaTemplate;
    inputLength?: number;
    setInputLength: (length: number) => void;
    maxInputLength: number;
    originalPrompt?: string;
}



export const QuestionInput = ({ onSend, disabled, placeholder, clearOnSend, setInputLength, selectedPersona, maxInputLength, originalPrompt }: Props) => {
    const { getAccessTokenSilently } = useAuth0();
    const [question, setQuestion] = useState<string>(originalPrompt || "");
    const [numLines, setNumLines] = useState(1);
    const [isMaxInputLengthExceeded, setIsMaxInputLengthExceeded] = useState(false);
    const toasterId = useId("toaster");
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const { dispatchToast } = useToastController(toasterId);
    const notify = (message: string, title: string, intent: "success" | "warning") =>
        dispatchToast(
            <Toast>
                <ToastTitle action={<Link>Undo</Link>}>{title}</ToastTitle>
                <ToastBody subtitle={message}></ToastBody>
            </Toast>,
            { intent }
        );


    // Update numLines every time text changes
    useEffect(() => {
        const lines = question.split('\n').length;
        if (lines < numLines && question.length < maxInputLength) {
            setNumLines(lines);
        } else if (question.length >= maxInputLength) {
            setIsMaxInputLengthExceeded(true);
        } else {
            setIsMaxInputLengthExceeded(false);
            setNumLines(lines);
        }
    }, [question, numLines, maxInputLength]);

    // handle send
    const sendQuestion = () => {
        if (disabled || !question.trim() || isMaxInputLengthExceeded) {
            return;
        }
        onSend(question);
        if (clearOnSend) {
            clearChatInput();
        }
    };

    // handle keypress - shift enter to improve question or enter to submit the question
    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey && !isMaxInputLengthExceeded) {
            ev.preventDefault();
            sendQuestion();
            setInputLength(0); // reset input length
        } else if (ev.key === "I" && ev.ctrlKey) {
            ev.preventDefault();
            improveQuestion(question);
        }
    };

    // New function to improve question
    const improveQuestion = async (question: string) => {
        const access_token = await getAccessTokenSilently();
        try {
            const response = await improveQuestionApi(question, access_token);
            if (response) {
                setQuestion(response);
            }
            notify('Question improved', 'Success!', 'success');
        }
        catch (error) {
            console.error(error);
            notify('Improve Failed', 'Error', 'warning');
        }
    }

    const onQuestionChange = (ev: React.ChangeEvent<HTMLTextAreaElement>, data: TextareaOnChangeData) => {
        const newValue = data.value || "";
        const textarea = textareaRef.current;
        if (newValue.length === 0) {
            clearChatInput();
        } else if (newValue.length <= maxInputLength) {
            setQuestion(newValue);
            setInputLength(newValue.length); // length of input
            if (textarea) {
                // Reset textarea height to auto to correctly calculate the scrollHeight
                textarea.style.height = 'auto';
                // Set the height based on the scrollHeight
                textarea.style.height = `${textarea.scrollHeight}px`;
            }
        }
    };


    // clear input box
    function clearChatInput() {
        setQuestion(""); // clear input
        setNumLines(0);
        const textarea = textareaRef.current;
        if (textarea) {
            // Reset textarea height to auto to correctly calculate the scrollHeight
            textarea.style.height = '76PX';
        }
    }

    const sendQuestionDisabled = disabled || !question.trim() || isMaxInputLengthExceeded;

    return (
        <>
            <Stack horizontal className={`${styles.questionInputContainer}`} style={{ borderColor: selectedPersona?.color }}>
                <Toaster toasterId={toasterId} />

                <Textarea
                    disabled={disabled}
                    aria-label="textarea"
                    ref={textareaRef}
                    className={styles.questionInputTextArea}
                    placeholder={placeholder}
                    value={question}
                    onChange={onQuestionChange}
                    onKeyDown={onEnterPress}
                    maxLength={maxInputLength}
                    appearance="outline"
                    resize="vertical"
                />

                <div className={styles.questionInputButtonsContainer}>
                    {question.length > 1 && (
                        <Button className={styles.clearChatButton} icon={<ChatDismissRegular />} onClick={() => clearChatInput()} appearance="transparent">Clear all text</Button>
                    )}

                </div>
            </Stack>

            <div className={styles.promptQualityIndicator}>
                <ProgressBar
                    value={question.length / 120}
                    color={
                        question.length < 50 ? 'error' :
                            question.length < 100 ? 'warning' :
                                'success'
                    }
                    thickness="large"
                />
                {
                    question.length < 50 ? <p><strong>Low Quality:</strong> A longer prompt with more context will provide better results</p> :
                        question.length < 100 ? <p><strong>Medium Quality:</strong> consider including important keywords and requirements</p> :
                            <p><strong>Good Quality:</strong> The more detail you add to a prompt, the better results you will get.</p>
                }
                {question.length}/{maxInputLength}
            </div >
            <Field
                validationState={originalPrompt && originalPrompt?.length > 0 ? "warning" : 'none'}
                validationMessage={originalPrompt && originalPrompt?.length > 0 ? "Editing the original query will delete all previous results" : ''}
                style={{ width: '50%' }} >
                <Button
                    appearance='primary'
                    onClick={() => sendQuestion()}
                    icon={<ChevronRightRegular />}
                    disabled={sendQuestionDisabled}
                    iconPosition="after"
                >
                    {isMaxInputLengthExceeded ? "Character limit reached" : originalPrompt && originalPrompt?.length > 0 ? "Submit new query" : "Submit"}
                </Button>
            </Field>
        </>
    );
};
