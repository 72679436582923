// This component will be used to create a new index
// it will just be the button and the input field

import { useAuth0 } from '@auth0/auth0-react';
import { MessageBar, MessageBarType, TextField } from '@fluentui/react';
import { Button, Dropdown, Field, Option, Textarea } from '@fluentui/react-components';
import { useEffect, useState } from 'react';
import { createCollectionApi, CollectionCategory, getAllCollectionCategoriesApi } from '../../api';
import styles from './CreateNewIndex.module.css';

interface ErrorResponse {
    response?: {
        data?: {
            message?: string;
        };
    };
    message?: string;
}

type CreateNewIndexProps = {
    hideDialog: () => void;
};


const CreateNewIndex = ({ hideDialog }: CreateNewIndexProps) => {
    const { getAccessTokenSilently, user } = useAuth0();
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null); // the error itself
    const [indexName, setIndexName] = useState<string>('');
    const [indexDescription, setIndexDescription] = useState<string>('');
    const [isProcessing, setIsProcessing] = useState(false);
    const [displayName, setDisplayName] = useState<string>(''); // display name [optional]
    const [categoryOptions, setCategoryOptions] = useState<CollectionCategory[]>([]); // all the options
    const [selectedCategoryName, setSelectedCategoryName] = useState<string>(); // id only

    // Fetch the category options
    const fetchCategoryOptions = async () => {
        // Fetch the categories from the backend
        const accessToken = await getAccessTokenSilently();
        const response = await getAllCollectionCategoriesApi(accessToken);
        setCategoryOptions(response);
    };

    // UseEffect to fetch the categories
    useEffect(() => {
        fetchCategoryOptions();
    }, []);

    // useEffect to reload page after creation
    useEffect(() => {
        if (showSuccessMessage) {
            hideDialog(); // close dialog
        }
    }, [showSuccessMessage]);

    // handle create index
    const handleCreateIndex = async () => {
        setIsProcessing(true);
        try {
            const userTag = user?.name?.toString() || 'unknown'; // create a userTag with the user.name from auth0
            const token = await getAccessTokenSilently();

            const formData = new FormData(); // create new form

            formData.append('index', indexName);
            formData.append('displayName', displayName);
            formData.append('description', indexDescription || '')
            formData.append('user', userTag);
            formData.append('category', selectedCategoryName || '');

            await createCollectionApi(formData, token);

            setShowSuccessMessage(true); // if successful, show success message
            // time out for 5 seconds
            setTimeout(() => {
                setShowSuccessMessage(false);
            }, 5000);
        } catch (error) {
            const typedError = error as ErrorResponse;
            console.error("Error at [createNewIndex]", typedError);
            // Extract the error message from the error object and set it in the state
            setErrorMessage(typedError.response?.data?.message || typedError.message || 'An unexpected error occurred');
            setShowErrorMessage(true); // if failed, show error message
        } finally {
            setIsProcessing(false);
            setTimeout(() => {
                setShowSuccessMessage(false);
                setShowErrorMessage(false);
                hideDialog(); // move hideDialog here
            }, 5000);
        }
    }

    // handle category change
    const handleCategoryChange = (event: React.FormEvent<HTMLDivElement>, option?: CollectionCategory) => {
        if (option) {
            const selectedOption = categoryOptions.find(opt => opt.id === option.id);
            if (selectedOption) {
                setSelectedCategoryName(selectedOption.category_name);
            }
        }
    };

    // handleDisplayNameChange function modification
    const handleDisplayNameChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (newValue !== undefined) {
            // Update the display name state
            setDisplayName(newValue);

            // Convert to lowercase
            let backendName = newValue.toLowerCase();
            // Replace invalid characters with a dash
            backendName = backendName.replace(/[^a-z0-9-]/g, '-');
            // Trim leading and trailing dashes
            backendName = backendName.replace(/^-+|-+$/g, '');

            // Also update the real name (indexName) state to reflect these changes
            setIndexName(backendName);
        }
    };

    // Real name must be lowercase letters, dashes, and numbers only! && it is no more than 32 characters
    const isValidName = /^[a-z0-9-]+$/.test(indexName) && indexName.length <= 32;



    return (
        <div className={styles.dialogContainer}>
            {showSuccessMessage && (
                <MessageBar
                    messageBarType={MessageBarType.success}
                    isMultiline={false}
                    dismissButtonAriaLabel="Close"
                    onDismiss={() => setShowSuccessMessage(false)}
                >
                    Collection created successfully!
                </MessageBar>
            )}
            {showErrorMessage && (
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}
                    dismissButtonAriaLabel="Close"
                    onDismiss={() => setShowSuccessMessage(false)}
                >
                    Failed to create Collection.
                    {errorMessage}
                </MessageBar>
            )}

            {/* Collection options container */}
            <div className={styles.collectionOptionsContainer}>
                <h3>New Collection</h3>
                {/* Display Name */}
                <Field required label="Collection Name" orientation='horizontal' validationState={isValidName ? "success" : "error"} validationMessage={isValidName ? "32 characters max" : "Required: 32 characters max"}
                >
                    <TextField
                        placeholder="Collection Name"
                        onChange={handleDisplayNameChange}
                    />
                </Field>
                <br></br>
                {/* <p>{indexName}</p> */}
                {/* Index description */}
                <Field label="Collection Description" orientation='horizontal' hint="A short description to help users select relevant collections">
                    <Textarea
                        placeholder="Collection Description"
                        onChange={e => setIndexDescription(e.target.value)} />
                </Field>
                <br></br>
                {/* Select category */}
                <Field required label="Collection Category" orientation='horizontal' validationState={selectedCategoryName ? "success" : "error"} validationMessage={isValidName ? "Choose a category" : "Required: Choose a category"}>

                    <Dropdown
                        placeholder='Select a category'
                    >
                        {categoryOptions.map((option) => (
                            <Option key={option.id} onClick={(e) => handleCategoryChange(e, option)}>{option.category_name}</Option>
                        ))}
                    </Dropdown>
                </Field>
            </div>


            <div className={styles.buttonsContainer}>
                {/* Submit - Disabled if no file or name not valid */}
                <Button
                    onClick={handleCreateIndex}
                    disabled={!isValidName || !selectedCategoryName || isProcessing}
                    appearance='primary'
                >
                    {isProcessing ? "Processing..." : "Create Collection"}
                </Button>

                {/* Close */}
                <Button
                    onClick={hideDialog}
                >
                    Cancel
                </Button>

            </div>
        </div >
    );
}


export default CreateNewIndex;