import {
    TableColumnDefinition,
    createTableColumn
  } from "@fluentui/react-components";
  import { IndexFileStatusItem } from '../../api/models';
  
  
  
  /// one of the index file status records we can use to test the datagrid
  export const itemskeith: IndexFileStatusItem[] = [
    {
      "id": "012XM74OEOWISVXXBIYBAITCWTISHITWD6",
      "name": "express-handbook.pdf",
      "created_by": {
        "display_name": "Keith Scheldt",
        "email": "keith.scheldt@blue-edge.co.uk"
      },
      "created_date_time": "2024-07-30T23:19:18+00:00",
      "last_modified_by": {
        "display_name": "Keith Scheldt",
        "email": "keith.scheldt@blue-edge.co.uk"
      },
      "last_modified_date_time": "2024-07-30T23:32:15+00:00",
      "download_url": "https://blueedge365.sharepoint.com/sites/BlueEdgeAI-Dev/_layouts/15/download.aspx?UniqueId=5b25b28e-28dc-40c0-898a-d3448e89d87e&Translate=false&tempauth=v1.eyJzaXRlaWQiOiIxOWRlZjgwOC1jMjU1LTRiZWEtYmY2NS1hODgzOGM2ZDBmOTkiLCJhcHBfZGlzcGxheW5hbWUiOiJTaGFyZVBvaW50IE9ubGluZSBJbmRleGVyIEFwcCIsImF1ZCI6IjAwMDAwMDAzLTAwMDAtMGZmMS1jZTAwLTAwMDAwMDAwMDAwMC9ibHVlZWRnZTM2NS5zaGFyZXBvaW50LmNvbUA4YmUxYmM2MS02MzhiLTRkOWQtODhkNy0xOTE0NWY3ZTdjOTAiLCJleHAiOiIxNzIyNTM0NTU2In0.CgoKBHNuaWQSAjY0EgsI5NOrn_2UmT0QBRoOMjAuMTkwLjE1Mi4xNTMqLEVXY3ZSckh4eHhLTnpkSVY3NWw3MGdiZXZab0IydjNvdHVRc3ZsbCtJRG89MI8BOAFCEKFCEFXbYACQnV9xhwu5MRZKEGhhc2hlZHByb29mdG9rZW56ATG6ASxhbGxzaXRlcy5yZWFkIGFsbGZpbGVzLnJlYWQgYWxscHJvZmlsZXMucmVhZMIBSTYxYmQwOGNjLWEyNjktNGQ4OS05NDEwLWE4ODdjODk1YTM5N0A4YmUxYmM2MS02MzhiLTRkOWQtODhkNy0xOTE0NWY3ZTdjOTDIAQE.1kk_UM2d-cJJjuTjBRMHiPL9HFvp6gmZfmHf5hVNsnk&ApiVersion=2.0",
      "status": "Successfully uploaded to Kernel Memory",
      "collection_name": "indexer-index"
    },
  ];
  
  
  
  export const columns: TableColumnDefinition<IndexFileStatusItem>[] = [
    // createTableColumn({
    //   columnId: "number",
    //   renderHeaderCell: () => "Number",
    //   renderCell: (_, rowIndex) => (rowIndex + 1).toString(), 
    // }),
    createTableColumn<IndexFileStatusItem>({
      columnId: "name",
      compare: (a, b) => a.name.localeCompare(b.name),
      renderHeaderCell: () => "Name",
      renderCell: (item) => item.name,
    }),
    createTableColumn<IndexFileStatusItem>({
      columnId: "status",
      compare: (a, b) => a.status.localeCompare(b.status),
      renderHeaderCell: () => "Status",
      renderCell: (item) => item.status,
    }),
    createTableColumn<IndexFileStatusItem>({
      columnId: "last_modified_date_time",
      compare: (a, b) => new Date(a.last_modified_date_time).getTime() - new Date(b.last_modified_date_time).getTime(),
      renderHeaderCell: () => "Last Modified Date",
      renderCell: (item) => new Date(item.last_modified_date_time).toLocaleString(),
    }),
    createTableColumn<IndexFileStatusItem>({
      columnId: "download_url",
      renderHeaderCell: () => "Download URL",
      renderCell: (item) => (
        <a href={item.download_url} target="_blank" rel="noopener noreferrer">
          Download
        </a>
      ),
    }),
  ];
  