import React from "react";
import { useAuth0 } from "@auth0/auth0-react";


interface LoginButtonProps {
  className?: string;

}

const LoginButton = ({ className }: LoginButtonProps) => {
  const { loginWithRedirect } = useAuth0();

  return <button
    className={className}
    onClick={() => loginWithRedirect()}
  >
    LOGIN
  </button>;
};

export default LoginButton;