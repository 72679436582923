// This component will display the terms of service for the app.
// The TOS is 30 pages, so with performance in mind we will load the text from a file.

// tosmd.md is in /public

import React from 'react';
import styles from './GizmoComponentChangeBtn.module.css';
import { Button } from '@fluentui/react-components';
import { EditRegular } from '@fluentui/react-icons';

interface Props {
    setFocus: React.Dispatch<React.SetStateAction<boolean>>;
    buttonText: string;
}

const GizmoComponentChangeBtn: React.FC<Props> = ({ setFocus, buttonText }) => {

    return (
        <Button appearance='subtle' className={styles.changeButton} onClick={() => setFocus(true)} icon={<EditRegular />}>Change {buttonText}</Button>
    )
}

export default GizmoComponentChangeBtn