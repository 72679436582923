// This component will display the terms of service for the app.
// The TOS is 30 pages, so with performance in mind we will load the text from a file.

// tosmd.md is in /public
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from 'react';
import styles from './GizmoDocumentCollection.module.css';
import GizmoComponentChangeBtn from '../GizmoComponentChangeBtn/GizmoComponentChangeBtn';
import CollectionSelector from '../CollectionSelector/CollectionSelector';
import { CollectionName, updateProjectFieldApi, getCollectionsApi, Project } from '../../api';
import { CollectionManagerPopup } from '../CollectionManagerPopup';

interface Props {
    focusedComponent: boolean;
    setFocusedComponent: (componentName: number) => void;
    componentName: number;
    exportSelectedCollections: (collections: CollectionName[]) => void;
    selectedProject: Project;
    setRefreshCollection: (refreshCollection: boolean) => void;
    refreshCollection: boolean;
}

const GizmoDocumentCollection: React.FC<Props> = ({ focusedComponent, componentName, setFocusedComponent, exportSelectedCollections, selectedProject, setRefreshCollection, refreshCollection }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [ openComponent, setOpenComponent ] = useState(focusedComponent);
    const [ selectedCollections, setSelectedCollections ] = useState<CollectionName[]>([]);
    // const [refreshChatCollectionsList, setRefreshChatCollectionsList] = useState(false);

    useEffect(() => {
        setOpenComponent(focusedComponent);
    }, [ focusedComponent ])

    //export the selected collections when they change
    useEffect(() => {
        const updateCollectionsAsync = async () => {
            exportSelectedCollections(selectedCollections);
            // Save the collection list to the project cosmos record
            const newSelectedCollections = [...selectedCollections];
            const accessToken = await getAccessTokenSilently();
            await updateProjectFieldApi(selectedProject.id, "project_collections", JSON.stringify(newSelectedCollections), accessToken);
        };
        updateCollectionsAsync();
    }, [selectedCollections, exportSelectedCollections]);

    const handleFocusChange = () => {
        setFocusedComponent(componentName);
    };

    // Get collections
    const fetchCollections = async () => {
        const access_token = await getAccessTokenSilently();
        const allCollections = await getCollectionsApi(access_token); // Assume this returns an array of objects with collection_name
        const selectedCollections = selectedProject.project_collections; // get from storage
        try {
            const parsedCollections = selectedCollections ? JSON.parse(selectedCollections) : [];
            // Filter out collections that no longer exist
            const validCollections = parsedCollections.filter((collection: { collection_name: string; }) =>
                allCollections ? allCollections.some((allCollection) => allCollection.collection_name === collection.collection_name) : false
            );
            setSelectedCollections(validCollections);
        } catch (error) {
            console.error("Failed to parse selectedCollections as JSON: ", error);
        }
    };
    useEffect(() => {
        fetchCollections();
    }, []);

    return (
        <div className="ms-Grid">
            <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-lg4" id={!openComponent ? styles.Closed : ''}>
                    <h2 className={styles.gizmoComponentName} >Document Collections</h2>
                    {openComponent ?
                        <>
                            <p className={styles.gizmoComponentDescription}>Document Collections are "folders" of documents that Blue Edge AI will reference to generate your results.</p><br></br>
                            <p className={styles.gizmoComponentDescription}>To get the best output, you should select the most relevant Document Collections for the AI to reference, too many and you will dilute your results. </p><br></br><br></br>
                            <CollectionManagerPopup
                                refreshCollection={refreshCollection}
                                setRefreshCollection={setRefreshCollection}
                            />


                        </>
                        : ''}
                </div>
                <div className="ms-Grid-col ms-lg8">
                    {openComponent ?
                        <>
                            {/* Document Collection Selector */}
                            <div className={styles.collectionSelectorContainer}>
                                <CollectionSelector
                                    selectedCollections={selectedCollections}
                                    setSelectedCollections={setSelectedCollections}
                                    refreshCollection={refreshCollection}
                                    setRefreshCollection={setRefreshCollection}
                                />
                            </div>
                        </> : <>
                            <div className={styles.summaryContainer}>

                                <h3 className={styles.summaryHeading}>Selected Collections:</h3>
                                <ol>
                                    {selectedCollections!.map((collection, index) => (
                                        <li key={index}>{collection.display_name}</li>
                                    ))}
                                </ol>
                            </div>

                            <GizmoComponentChangeBtn setFocus={handleFocusChange} buttonText='Selected Collections' />

                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default GizmoDocumentCollection