// This component needs to work independently of the rest of the app, it will work when backend is down.
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { formatMemory, formatNetwork, formatTime } from '../utility_functions/utility_functions';

import styles from './SystemStatus.module.css';



// Define the interfaces for the data we will receive from the server
interface Uptime {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

interface Memory {
    free: number;
    percent: number;
    total: number;
    used: number;
}

interface DiskUsage {
    free: number;
    percent: number;
    total: number;
    used: number;
}

interface NetworkStats {
    bytes_recv: number;
    bytes_sent: number;
}


const SystemStatus: React.FC = () => {
    const [status, setStatus] = useState('offline');
    const [uptime, setUptime] = useState<Uptime | null>(null);
    const [memory, setMemory] = useState<Memory | null>(null);
    const [cpuUsage, setCpuUsage] = useState(0);
    const [diskUsage, setDiskUsage] = useState<DiskUsage | null>(null);
    const [networkStats, setNetworkStats] = useState<NetworkStats | null>(null);
    const [failureCount, setFailureCount] = useState(0); // 10 fails before alert


    useEffect(() => {
        const getStatus = async () => {
            try {
                const response = await axios.get('/system_status');
                setStatus(response.data.status);
                setUptime(response.data.uptime);
                setMemory(response.data.memory);
                setCpuUsage(response.data.cpu_usage);
                setDiskUsage(response.data.disk_usage);
                setNetworkStats(response.data.network_stats);
                setFailureCount(0); // reset failure count on successful response
            } catch (error) {
                if (failureCount >= 9) { // if failure count reaches 10
                    setStatus('offline');
                    alert('Server went offline at ' + new Date().toLocaleString());
                    setFailureCount(0); // reset failure count after alert
                } else {
                    setFailureCount(failureCount + 1); // increment failure count
                }
            }
        };

        getStatus();
        const intervalId = setInterval(getStatus, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className={styles.systemStatusContainer}>
            <div className={styles.surface}>
                <span style={{ color: status === 'online' ? 'green' : 'red' }} className={styles.headerText}>System Status: {status}</span>
                {uptime && <p><strong>Uptime:</strong> {formatTime(uptime.seconds)}</p>}
                {memory && <p><strong>Memory:</strong> {formatMemory(memory.used)} GB used out of {formatMemory(memory.total)} GB</p>}
                <p><strong>CPU Usage:</strong> {cpuUsage}%</p>
                {diskUsage && <p><strong>Disk Usage:</strong> {formatMemory(diskUsage.used)} GB used out of {formatMemory(diskUsage.total)} GB</p>}
                {networkStats && <p><strong>Network Stats:</strong> {formatNetwork(networkStats.bytes_sent)} MB sent, {formatNetwork(networkStats.bytes_recv)} MB received</p>}
            </div>

        </div>
    );
};

export default SystemStatus;