// /indexer page - Will handle the GraphApi connections. It will have the ability to add a new indexer, delete an indexer, and view the indexer details. The details will come from cosmos db. It will show what stage has been completed.


import { useAuth0 } from '@auth0/auth0-react';
import { Stack } from '@fluentui/react';
import { Button, Checkbox, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridRow, Divider, Image, makeStyles, shorthands, Skeleton, SkeletonItem, Spinner, Text, tokens } from '@fluentui/react-components';
import { ArrowClockwise24Regular, ArrowLeft24Regular, Chat24Regular } from '@fluentui/react-icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ExternalDataCollection, getExternalCollectionsApi, getIndexerFileStatusApi } from '../../api';
import ClientLogo from '../../assets/ClientLogo.png';
import { lightTheme } from '../../BlueEdgeTheme';
import CreateExternalSource from '../../components/CreateExternalSource/CreateExternalSource';
import UserProfile from '../../components/UserProfile/UserProfile';
import { columns } from './columns';
import styles from './IndexerPage.module.css';
import IndexerActions from '../../components/IndexerActions/IndexerActions';


const useStyles = makeStyles({
    invertedWrapper: {
        backgroundColor: tokens.colorNeutralBackground1,
    },
    skeletonRow: {
        alignItems: "center",
        display: "grid",
        paddingBottom: "10px",
        position: "relative",
        ...shorthands.gap("10px"),
        gridTemplateColumns: "min-content 80%",
        height: '5vh' // matches the height of the personaListItem
    },
    text: {
        color: lightTheme.colorBrandForeground1
    },
});




const IndexerPage: React.FC = () => {
    const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const [ allCollections, setAllCollections ] = React.useState<ExternalDataCollection[]>([]);
    const [ selectedCollection, setSelectedCollection ] = React.useState<ExternalDataCollection | null>(null);
    const [ loading, setLoading ] = React.useState(true);

    const [ files, setFiles ] = useState<File[]>([]);
    const [ filesLoading, setFilesLoading ] = useState<boolean>(false);

    const navigate = useNavigate();
    const uistyles = useStyles();

    // Get ALL collections with external data connections
    const get_external_collections = async () => {
        setLoading(true);
        try {
            const token = await getAccessTokenSilently();
            // Fetch list of collections with external connections
            const collections = await getExternalCollectionsApi(token);
            setAllCollections(collections.indexes); // set the list of indexes as the collections
            console.log("Collections: ", collections);
        } catch (error) {
            console.error('Failed to retrieve collections', error);
        }
        setLoading(false);
    }

    // useEffect to trigger the get_external_collections function on load
    useEffect(() => {
        get_external_collections();
    }, []);

    // Fetch files for the selected collection
    const fetchFiles = async (collectionName: string) => {
        setFilesLoading(true);
        console.log("Fetching files for collection: ", collectionName);
        try {
            const token = await getAccessTokenSilently();
            const files = await getIndexerFileStatusApi(token, collectionName); // Adjust the API call as needed
            //testing
            console.log("Files Found: ", files);
            setFiles(files);
        } catch (error) {
            console.error('Failed to retrieve files', error);
        } finally {
            setFilesLoading(false);
        }
    };

    // Handle collection change
    const onCollectionChange = (collection: ExternalDataCollection) => {
        console.log("Collection selected: ", collection);
        // Takes the item selected and sets it to state
        setSelectedCollection(collection);
        setFiles([]); // Clear previous files
        fetchFiles(collection.id); // Fetch new files
        console.log("Selected collection ID: ", collection.id);
    };

    // Check auth
    useEffect(() => {
        if (isAuthenticated) {
            // If Authenticated Replace the current entry in the history stack.
            window.history.replaceState(null, '', '/#/indexer');
        } else {
            console.log("Trigger redirect to login page at [indexer.tsx]");
            navigate("/login")
        }
    }, [ isAuthenticated ]);


    // While auth is loading show indicator
    if (isLoading) {
        return <div><p>Loading...</p></div>;
    }


    return (
        <div className='ms-Grid' dir='ltr'>
            <div className='ms-Grid-row'>
                {/* Side menu - 2/12ths of grid*/}
                <div className='ms-Grid-col ms-lg2'>
                    <div className='ms-Grid' dir='ltr' style={{ height: '100vh' }}>
                        <div className={styles.sideMenu}>
                            <div className={styles.sideMenuTop}>
                                <div className={styles.logoContainer}>
                                    {/* Client Logo */}
                                    <Image className={styles.logo} src={ClientLogo} alt="Logo" />
                                </div>

                                {/* User info - wrap in isAuthenticated to prevent render leakage*/}
                                <div className={styles.userDropdown}>
                                    {isAuthenticated ? (
                                        <UserProfile />
                                    ) : null}
                                </div>
                                <br />

                                {/* Add External Source */}

                                <Stack
                                    horizontal
                                    horizontalAlign='space-between'
                                >
                                    <Text size={400}>Add External Source</Text>
                                    <CreateExternalSource get_external_collections={get_external_collections} />
                                </Stack>

                                {/* Refresh button */}
                                <div>
                                    <Button
                                        appearance="transparent"
                                        onClick={get_external_collections}
                                    ><ArrowClockwise24Regular /></Button>
                                </div>

                                {/* Divider */}
                                <Divider>Collections</Divider>

                                {/* Collection Selector */}
                                <div className={styles.collectionSelectorContainer}>
                                    {loading ? (
                                        <div style={{ textAlign: 'center' }}>
                                            <Skeleton>
                                                <div className={uistyles.skeletonRow}>
                                                    <SkeletonItem shape="square" size={24} />
                                                    <SkeletonItem />
                                                </div>
                                            </Skeleton>
                                            <Skeleton>
                                                <div className={uistyles.skeletonRow}>
                                                    <SkeletonItem shape="square" size={24} />
                                                    <SkeletonItem />
                                                </div>
                                            </Skeleton>
                                            <Skeleton>
                                                <div className={uistyles.skeletonRow}>
                                                    <SkeletonItem shape="square" size={24} />
                                                    <SkeletonItem />
                                                </div>
                                            </Skeleton>
                                            <Text as='p' size={200} className={uistyles.text}>Loading Collections...</Text>
                                        </div>
                                    ) : allCollections.length === 0 ? (
                                        <div style={{ textAlign: 'center' }}>
                                            <p>No collections found.</p>
                                        </div>
                                    ) : (
                                        <Stack className={styles.list}>
                                            <div>
                                                {allCollections.map((collection, index) => (
                                                    <label key={index} style={{ display: 'block' }}>
                                                        <Checkbox
                                                            label={collection.display_name}
                                                            onChange={() => onCollectionChange(collection)}
                                                            checked={selectedCollection?.id === collection.id}
                                                        />
                                                    </label>
                                                ))}
                                            </div>
                                        </Stack>
                                    )}
                                </div>
                            </div>


                            {/* Back button */}
                            <div className={styles.backToChatBtn}>
                                <Button
                                    appearance="secondary"
                                    onClick={() => window.location.href = "/#/hello"}
                                    icon={<Chat24Regular />}
                                >
                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                        <Text
                                            as='span'
                                            size={300}
                                            weight='semibold'
                                        >Back to AI chat</Text>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>





                {/* Main content - ms-lg10 = 10/12ths of grid */}
                <div className='ms-Grid-col ms-lg10' style={{ height: '99vh' }}>
                    {selectedCollection ? (
                        <div>
                            <div>
                                <p>Name: {selectedCollection.display_name}</p>
                                <p>ID: {selectedCollection.id}</p>
                                <br />
                                <p>Created On: {selectedCollection.entry_date}</p>
                                <p>Created by: {selectedCollection.user_id}</p>
                                <br />
                                <p>Folder ID: {selectedCollection.folder_id}</p>
                                <p>Site ID: {selectedCollection.site_id}</p>
                                <p>Drive ID: {selectedCollection.drive_id}</p>
                            </div>

                            <Divider>Files</Divider>

                            {/* Action buttons */}
                            <div>
                                <IndexerActions
                                    selectedCollection={selectedCollection}
                                    fetchFiles={fetchFiles}
                                    get_external_collections={get_external_collections}
                                    setSelectedCollection={setSelectedCollection}
                                />
                            </div>

                            <Divider />

                            <div>
                                {filesLoading ? (
                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                        <Spinner label="Loading files..." />
                                    </div>
                                ) : (
                                    files.length === 0 ? (
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', marginTop: '20px' }}>
                                            <Text size={500}>Nothing Here, Try seeding to get started.</Text>
                                        </div>
                                    ) : (
                                        <DataGrid
                                            items={files}
                                            columns={columns}
                                            sortable
                                            selectionMode="single"
                                            getRowId={(item) => item.id}
                                            focusMode="composite"
                                            style={{ minWidth: "550px" }}
                                        >
                                            <DataGridHeader>
                                                <DataGridRow>
                                                    {({ renderHeaderCell }) => (
                                                        <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                                                    )}
                                                </DataGridRow>
                                            </DataGridHeader>
                                            <DataGridBody>
                                                {({ item, rowId }) => (
                                                    <DataGridRow key={rowId}>
                                                        {({ renderCell }) => (
                                                            <DataGridCell>{renderCell(item)}</DataGridCell>
                                                        )}
                                                    </DataGridRow>
                                                )}
                                            </DataGridBody>
                                        </DataGrid>
                                    )
                                )}
                            </div>
                        </div>
                    ) : (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <ArrowLeft24Regular />
                            <Text size={500}>Please select a collection or create a new one.</Text>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default IndexerPage