// cookie is true by default, a useEffect will check for the cookie on page load and if it is not there, it will display the TOS, once "continue" is clicked a new cookie is saved for 30 days.

import { useAuth0 } from "@auth0/auth0-react";
import { Modal, PrimaryButton } from "@fluentui/react";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import TermsOfService from "../../components/TermsOfService/TermsOfService";
import styles from "./Layout.module.css";

import { Spinner, Text } from "@fluentui/react-components";

import { getFrontendSettingsApi, setTosAcceptanceApi } from "../../api";
import ChangeLog from "../../components/ChangeLog/ChangeLog";

import version from '../../../package.json';
import { PageFooter } from "../../components/PageFooter/PageFooter";


// Define Loading Messages
const loadingMessagesArray = [
    "Calibrating warp drive... Please stand by.",
    "Aligning dilithium crystals for optimal efficiency.",
    "Initiating subspace field harmonics... Hold tight.",
    "Uploading data to the neural network... Thinking hard!",
    "Entangling quantum bits... Hang on to your hat.",
    "Compiling the laws of physics... This won't take long.",
    "Uploading data to the neural network... Thinking hard!",
    "Reticulating splines... Whatever that means...",
];



const Layout = () => {
    const [loadingMessage, setLoadingMessage] = useState<string>('');
    const [tosAccepted, setTosAccepted] = useState<boolean>(true);
    const [clRead, setClRead] = useState<boolean>(false);
    const { isLoading, user } = useAuth0();
    const [environment, setEnvironment] = useState<string>("");

    // useEffect to check for tos cookie
    useEffect(() => {
        const cookie = document.cookie;
        const tosAcceptedCookie = cookie.match("tos_accepted=true");
        if (!tosAcceptedCookie) {
            setTosAccepted(false);
        }
    }, [])

    // useEffect to check for change log cookie
    useEffect(() => {
        const cookie = document.cookie;
        const clReadCookie = cookie.match(`cl_read=${version.version}`);
        if (!clReadCookie) {
            setClRead(false);
        } else {
            setClRead(true);
        }

    })

    // Check version and check for change log cookie
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Split the version number into major, minor, and patch as numbers
                const [major, minor, patch] = version.version.split('.').map(num => parseInt(num, 10));

                // Convert major and minor back to strings for cookieName
                const cookieName = `cl_read=${major.toString()}.${minor.toString()}/${patch}`;

                // Match the cookie name with the existing cookies
                const clReadCookie = document.cookie.match(cookieName);

                if (!clReadCookie) {
                    setClRead(false);
                } else {
                    // If the cookie exists, compare its value with the current version
                    const [cookieMajor, cookieMinor] = clReadCookie[0]?.split('=')[1]?.split('/')?.shift()?.split('.')?.map(num => num.trim()) ?? [];

                    // Convert major and minor to strings for comparison
                    const majorStr = major.toString();
                    const minorStr = minor.toString();

                    if (cookieMajor !== majorStr || cookieMinor !== minorStr) {
                        // If the major or minor version number has incremented, show the change log
                        setClRead(false);
                    }
                }
            } catch (error) {
                console.error('Fetch error: ', error);
            }
        };

        fetchData();
    }, []);


    // UseEffect to fetch frontend settings
    useEffect(() => {
        getFrontendSettingsApi().then(settings => {
            setEnvironment(settings.AZURE_ENV_NAME)
        }).catch(error => {
            console.error('Failed to get frontend settings:', error);
        })
    }, []);

    // Use an effect to select a new saying each time the component renders
    useEffect(() => {
        const randomSaying = loadingMessagesArray[Math.floor(Math.random() * loadingMessagesArray.length)];
        setLoadingMessage(randomSaying);
    }, []);



    // When closing the TOS set a cookie that expires in 100 years
    const handleClose = async () => {
        setTosAccepted(true);
        const date = new Date();
        date.setFullYear(date.getFullYear() + 100); // 100 years in the future
        const expires = "; expires=" + date.toUTCString();
        document.cookie = "tos_accepted=true; path=/; " + expires;

        setTosAcceptanceApi(user?.name || "Unknown")
    }

    // Handle change log close
    const handleChangeLogClose = () => {
        setClRead(true);
        const date = new Date();
        date.setFullYear(date.getFullYear() + 100); // 100 years in the future
        const expires = "; expires=" + date.toUTCString();
        document.cookie = `cl_read=${version.version}; path=/; ${expires}`;
    }

    // If loading, return loading screen - needs to be below the hooks
    if (isLoading) {
        return <div className='loading'><Spinner size='large' label={loadingMessage} labelPosition="below" /></div>;
    }




    return (
        <div className={styles.layout}>
            {/* TOS Screen */}
            {!tosAccepted && (
                <Modal
                    isOpen={!tosAccepted}
                    onDismiss={handleClose}
                    containerClassName={styles.modalContainer}
                >
                    <div className={styles.modalContent}>
                        <Text
                            as="h1"
                            style={{ color: "var(--primary-color)", fontSize: "2em", paddingBottom: "1em" }}
                        >Welcome to Blue-Edge AI.</Text>
                        <TermsOfService />
                        <Text as="p">By continuing you agree to the Terms of Service</Text>
                        <PrimaryButton onClick={handleClose} text="Continue"
                            className={styles.modalButton}
                        />
                    </div>
                </Modal>
            )}

            {/* Change Log modal*/}
            {!clRead && (
                <Modal
                    isOpen={!clRead}
                    onDismiss={handleChangeLogClose}
                    containerClassName={styles.modalContainer}
                >
                    <div className={styles.modalContent}>
                        <Text
                            as="h1"
                            style={{ color: "var(--primary-color)", fontSize: "2em", paddingBottom: "1em" }}
                        >Latest Changes - Version {version.version}</Text>
                        <ChangeLog />
                        <p></p>
                        <PrimaryButton onClick={handleChangeLogClose} text="Continue"
                            className={styles.modalButton}
                        />
                    </div>
                </Modal>
            )}

            <Outlet />
            {/* Display version and environment */}
            <div className={styles.version}>{version.version}<br></br><strong>{environment}</strong></div>
            <PageFooter />
        </div >
    );
};

export default Layout;
