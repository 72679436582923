import React, { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Image } from '@fluentui/react-components';
import styles from './PageHeader.module.css';
import UserProfile from '../UserProfile/UserProfile';
import ClientLogoTransparent from "../../assets/LogoTransparent.png";
import { NavLink } from 'react-router-dom';
import { PersonaTemplate } from '../../api';
import { PersonaIcon } from '../PersonaIcon';

type PageHeaderProps = {
  selectedTool: PersonaTemplate | undefined;
}

export const PageHeader = ({
  selectedTool
}: PageHeaderProps) => {
  const { isAuthenticated } = useAuth0();
  const [scrolled, setScrolled] = useState(false); // State to track if the user has scrolled past a certain point

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) { // Replace 100 with the desired scroll distance
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll); // Cleanup on unmount
    };
  }, []); // Empty dependency array ensures this runs only on mount and unmount

  return (

    <header className={scrolled ? styles.scrolledHeader : ''}>
      <div className={styles.headerContainer}>
        <div className={styles.logoContainer}>
          {/* Client Logo */}
          <NavLink to="/hello">
            <Image className={styles.logo} src={ClientLogoTransparent} alt="Logo" />
          </NavLink>

        </div>
        {/* <div className={styles.closeButton}>
          <NavLink to="/hello">
            <Button icon={<SaveRegular />}>Save and Close</Button>
          </NavLink>
        </div> */}
        {/* User info - wrap in isAuthenticated to prevent render leakage*/}
        <div className={styles.userDropdown}>
          {isAuthenticated ? (
            <UserProfile />
          ) : null}
        </div>
      </div>
      {selectedTool ? (
        <div className={styles.subHeader} style={{ backgroundColor: selectedTool?.color }}>
          {/* Tool Header */}
          <div className={styles.mainToolheader}>
            <div className={styles.mainToolIcon}>
              <PersonaIcon iconName={selectedTool?.iconName} iconSize='auto' iconColor={'white'} />
            </div>
            <h2 className={styles.mainToolName}>
              {selectedTool?.name}
            </h2>
            <br />
            <p
              className={styles.mainToolDescription}
              dangerouslySetInnerHTML={{ __html: selectedTool?.long_description || '' }}
            ></p>
          </div>
        </div>
      ) :
        ('')
      }
    </header>
  );
}