// This component will display the terms of service for the app.
// The TOS is 30 pages, so with performance in mind we will load the text from a file.

// tosmd.md is in /public

import { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import styles from './ChangeLog.module.css';



const ChangeLog = () => {
    const [clContent, setClContent] = useState('');

    useEffect(() => {
        async function fetchTos() {
            try {
                const response = await fetch('/ChangeLog.md');
                const clText = await response.text();
                setClContent(clText);
            } catch (error) {
                console.error('Error fetching TOS:', error);
            }
        }

        fetchTos();
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.tos}>
                <ReactMarkdown children={clContent} />
            </div>
        </div>
    )
}

export default ChangeLog