/* eslint-disable @typescript-eslint/no-unused-vars */
// Collection Manager

import { useAuth0 } from '@auth0/auth0-react';
import { IconButton, TextField } from '@fluentui/react';
import type { MenuButtonProps } from "@fluentui/react-components";
import { Button, Checkbox, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Divider, Image, makeStyles, Menu, MenuItem, MenuList, MenuPopover, MenuTrigger, shorthands, Skeleton, SkeletonItem, Spinner, SplitButton, Text, Popover, PopoverTrigger, PopoverSurface, } from '@fluentui/react-components';
import { Add24Filled, Archive16Regular, ArchiveRegular, ArchiveSettingsRegular, ArrowCircleLeft32Filled, ArrowSync20Regular, ChevronRight16Filled, SearchRegular, DismissRegular, FolderOpenRegular } from '@fluentui/react-icons';
import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { lightTheme } from '../../BlueEdgeTheme';
import { CollectionName, CustomJwtPayload, getCollectionsApi, retrieveFileApi, SelectedFile } from '../../api';
import BEAILogo from "../../assets/BEAILogoFinal.png";
import CreateNewIndex from '../CreateNewIndex/CreateNewIndex';
import { DeleteCollection } from '../DeleteCollection/DeleteCollection';
import FileStatus from '../FileStatus/FileStatus';
import UploadArea from '../UploadArea/UploadArea';
import styles from './CollectionManagerPopup.module.css';
import RenameCollection from '../RenameCollection/RenameCollection';
import UploadUrl from '../UploadUrl/UploadUrl';
import CollectionCategoryManager from '../CollectionCategoryManager/CollectionCategoryManager';

type CollectionManagerPopupProps = {
    setRefreshCollection: (value: boolean) => void;
    refreshCollection: boolean;

}

// Styles for the skeleton
const useStyles = makeStyles({
    skeletonRow: {
        alignItems: "center",
        display: "grid",
        paddingBottom: "10px",
        position: "relative",
        ...shorthands.gap("20px"),
        gridTemplateColumns: "80% 20%",
        height: '5vh', // matches the height of the personaListItem
    },
    text: {
        color: lightTheme.colorBrandForeground1
    },
});

export const CollectionManagerPopup = ({ setRefreshCollection, refreshCollection }: CollectionManagerPopupProps) => {
    const navigate = useNavigate();
    const { isAuthenticated, getAccessTokenSilently, isLoading, user } = useAuth0();
    const [isAuthorizedToCreateIndex, setIsAuthorizedToCreateDb] = React.useState(false);
    const [isAuthorizedToDeleteIndex, setIsAuthorizedToDeleteDb] = React.useState(false);
    const [isAuthorizedToDeleteAll, setIsAuthorizedToDeleteAll] = React.useState(false);
    const [isAuthorizedToUpload, setIsAuthorizedToUpload] = React.useState(false);
    const [isAuthorizedToViewDocumentCollectionsManager, setIsAuthorizedToViewDocumentCollectionsManager] = useState<boolean>(false); // check if user is authorized to view THIS page
    const [isAuthorizedToViewQuickSettingsMenu, setIsAuthorizedToViewQuickSettingsMenu] = useState<boolean>(false);

    const [collections, setCollections] = useState<CollectionName[] | null>(null); // hold list of all collections
    const [selectedPdf, setSelectedPdf] = useState<SelectedFile | null>(null); // track which pdf is selected 
    const [selectedCollection, setSelectedCollection] = useState<CollectionName | undefined>(undefined); // track which collection is selected
    const [dialogVisible, setDialogVisible] = useState(false); // show/hide the dialog



    const [refreshGrid, setRefreshGrid] = useState(false); // refresh the DataGrid after upload/delete
    const [numberOfFilesInSelectedCollection, setNumberOfFilesInSelectedCollection] = useState<number>(0); // hold the number of files in the selected collection
    const [autoRefresh, setAutoRefresh] = useState(true); // track if auto refresh is enabled
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [openUpload, setOpenUpload] = useState(false);
    const [hasUpload, setHasUpload] = useState(false);
    const [managerOpen, setManagerOpen] = useState(false);
    // Fluent UI styles
    const fuistyles = useStyles();
    const [isSearchVisible, setIsSearchVisible] = useState(false);
    const [groupedCollections, setGroupedCollections] = useState<{ [key: string]: CollectionName[] }>({});



    // Show Search Dialog
    const showSearch = () => {
        setIsSearchVisible(true);
    }

    const closeSearch = () => {
        setIsSearchVisible(false);
    }

    // Functions to show/hide the dialog
    const showDialog = () => {
        setDialogVisible(true);
    };

    const hideDialog = () => {
        setDialogVisible(false);
        fetchCollectionNames(); // refresh the collections list
    };

    //show and hide the document collection manager
    const handleOpenManager = () => {
        if (!managerOpen) {
            setManagerOpen(true);
            groupCollectionsByCategory(collections || []);

        }
    }

    // handle close Manager
    const handleCloseManager = () => {
        setManagerOpen(false);
        setSelectedCollection(undefined);
        setOpenUpload(false);
        setRefreshCollection(true)
    }

    // get fresh access token and check roles
    useEffect(() => {
        const checkRole = async () => {
            try {
                const token = await getAccessTokenSilently();
                const decodedToken = jwtDecode<CustomJwtPayload>(token);
                const permissions = decodedToken.permissions;
                if (permissions.includes('view:CollectionsManager')) {
                    setIsAuthorizedToViewDocumentCollectionsManager(true);
                }
                if (permissions.includes('add:Databases')) {
                    setIsAuthorizedToCreateDb(true);
                }
                if (permissions.includes('delete:Databases')) {
                    setIsAuthorizedToDeleteDb(true);
                }
                if (permissions.includes('delete:Files')) {
                    setIsAuthorizedToDeleteAll(true);
                }
                if (permissions.includes('add:Files')) {
                    setIsAuthorizedToUpload(true);
                }
                if (permissions.includes('view:QuickSettingsMenu')) {
                    setIsAuthorizedToViewQuickSettingsMenu(true);
                }
            } catch (e) {
                console.error(e);
                alert("There was an error accessing your user permissions, please try to log in again.")
                navigate("/login");
            }
        }

        if (isAuthenticated) {
            checkRole();
        }
    }, [isAuthenticated, navigate, getAccessTokenSilently]);

    // Define function to fetch collection names
    const fetchCollectionNames = async () => {
        try {
            const token = await getAccessTokenSilently();
            const collectionNames = await getCollectionsApi(token) as CollectionName[];
            setCollections(collectionNames);
        } catch (error) {
            console.error('An error occurred while fetching the indexes:', error);
        };
    };

    //Fetch Collection Names on refresh
    useEffect(() => {
        if (refreshCollection) {
            fetchCollectionNames();
            setSelectedCollection(undefined);
        }
    }, [refreshCollection]);

    // Fetch collection names on load
    useEffect(() => {
        fetchCollectionNames();
    }, []);

    //sort by category
    useEffect(() => {
        if (collections) {
            const grouped = collections.reduce((acc, c) => {
                if (!acc[c.category]) {
                    acc[c.category] = [];
                }
                acc[c.category].push(c);
                return acc;
            }, {} as { [key: string]: CollectionName[] });
            setGroupedCollections(grouped);
        }
    }, [collections])

    // Loading
    if (isLoading) {
        return <div className='loading'><Spinner size='large' label='Loading' labelPosition="below" /></div>;
    }

    // Permissions check
    if (!isAuthorizedToViewDocumentCollectionsManager) {
        return <div>You do not have Permission to view this page. Please contact your admin.</div>;
    }
    //search filters
    //filter the file list with the search box
    const searchFilterOnChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setSearchFilter(newValue || '');
    }

    const clearFilter = () => {
        setSearchFilter('');
        setRefreshGrid(true)
    }

    const openUploadChange = () => {
        if (openUpload) {
            setOpenUpload(false);
        } else {
            setOpenUpload(true);
        }
    }
    //group the collections
    const groupCollectionsByCategory = (cArray: CollectionName[]) => {

    };



    return (

        <Dialog
            modalType='modal'
            open={managerOpen}
        >
            <DialogTrigger disableButtonEnhancement>
                <Button appearance='primary' onClick={handleOpenManager} icon={<ArchiveSettingsRegular />}>Open Collection Manager</Button>
            </DialogTrigger>
            <DialogSurface className={styles.cmBody} >
                <DialogBody >
                    <DialogTitle>Collections Manager
                        <DialogActions>
                            <DialogTrigger disableButtonEnhancement>
                                <Button className={styles.closeBtn} appearance="primary" icon={<DismissRegular />} onClick={handleCloseManager}></Button>
                            </DialogTrigger>
                        </DialogActions>


                    </DialogTitle>

                    <DialogContent >
                        {/* Side menu */}
                        <div className={styles.cmContainer}>
                            <div className={styles.collectionsFoldersToolbar}>
                                {/* Collection Category Manager Button */}
                                {isAuthorizedToViewQuickSettingsMenu ?
                                    <CollectionCategoryManager />
                                    : ''}
                                <div>
                                    <h4>Your Document Collections:</h4>
                                    <p>Document Collections {collections?.length} / 15</p>
                                </div>

                                {/* Collection List */}
                                <div className={styles.collectionsContainer}>
                                    {collections && collections.length > 0 ? (
                                        Object.keys(groupedCollections)
                                            .sort((a, b) => a.localeCompare(b))
                                            .map((category, index) => (
                                                <div key={index}>
                                                    <h3 className={styles.collectionCategory}>{category}</h3>
                                                    <div>
                                                        {groupedCollections[category]
                                                            .sort((a, b) => a.display_name.localeCompare(b.display_name))
                                                            .map((collection, index) => (
                                                                <div
                                                                    key={index}
                                                                    className={styles.collectionItem}
                                                                    onClick={() => setSelectedCollection(collection)}
                                                                    style={collection.id === selectedCollection?.id ? { backgroundColor: '#a5e0e3' } : {}}
                                                                >
                                                                    <Archive16Regular />
                                                                    <Text
                                                                        as='span'
                                                                        size={300}
                                                                        className={styles.collectionName}
                                                                    >
                                                                        {/* Show the display_name of the collection */}
                                                                        {collection.display_name}
                                                                    </Text>
                                                                    <ChevronRight16Filled className={styles.chevron} />
                                                                </div>
                                                            ))}
                                                    </div>
                                                </div>
                                            ))
                                    ) : (
                                        // display skeleton while loading
                                        <div style={{ textAlign: 'center', padding: '20px' }}>
                                            <Text
                                                as='p'
                                                size={200}
                                                className={fuistyles.text}
                                            >Loading Collections...</Text>
                                            <Skeleton >
                                                <div className={fuistyles.skeletonRow}>
                                                    <SkeletonItem />
                                                    <SkeletonItem shape="circle" size={24} />
                                                </div>
                                            </Skeleton>
                                            <Skeleton >
                                                <div className={fuistyles.skeletonRow}>
                                                    <SkeletonItem />
                                                    <SkeletonItem shape="circle" size={24} />
                                                </div>
                                            </Skeleton>
                                            <Skeleton >
                                                <div className={fuistyles.skeletonRow}>
                                                    <SkeletonItem />
                                                    <SkeletonItem shape="circle" size={24} />
                                                </div>
                                            </Skeleton>
                                        </div>
                                    )}
                                </div>
                            </div>



                            <div style={{ width: '100%' }}>
                                {/* Collection Manager ToolBar */}
                                <div className={styles.cmToolBar}>
                                    {/* Create New Collection */}
                                    <div className={styles.newCollectionBtn}>
                                        <Popover withArrow open={dialogVisible}>
                                            <PopoverTrigger disableButtonEnhancement>
                                                <Button
                                                    appearance="subtle"
                                                    icon={<Add24Filled />
                                                    }
                                                    disabled={collections?.length == 15 ? true : false}
                                                    onClick={showDialog}
                                                >
                                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                                        <Text
                                                            as='span'
                                                            size={300}
                                                            weight='semibold'
                                                        >New Collection</Text>
                                                    </div>
                                                </Button>
                                            </PopoverTrigger>

                                            <PopoverSurface tabIndex={-1}>
                                                {/* Create new Collection */}
                                                <CreateNewIndex hideDialog={hideDialog} />
                                                {/* not authorized message */}
                                                {!isAuthorizedToCreateIndex ? <div>You are not authorized to create a new database.</div> : null}
                                            </PopoverSurface>
                                        </Popover>
                                    </div>



                                    {/* Rename collection */}
                                    {selectedCollection ?
                                        <div className={styles.renameBtn}>
                                            <RenameCollection selectedCollection={selectedCollection}
                                                setRefreshCollection={setRefreshCollection} />
                                        </div>
                                        : ''}

                                    <Divider vertical inset appearance='strong' />


                                    {/* Delete and Clear buttons */}
                                    {isAuthorizedToDeleteIndex ? (
                                        <DeleteCollection
                                            indexName={selectedCollection ? selectedCollection.collection_name : ''}
                                            fetchCollections={fetchCollectionNames}
                                            setSelectedCollection={setSelectedCollection}
                                            setSelectedPdf={setSelectedPdf}
                                        />
                                    ) : ('')} {/* Blank if not authorized to delete */}

                                    <Divider vertical inset appearance='strong' />

                                    {/*refresh button*/}
                                    <Menu positioning="below-end">
                                        <MenuTrigger disableButtonEnhancement>
                                            {(triggerProps: MenuButtonProps) => (
                                                <SplitButton
                                                    appearance='subtle'
                                                    menuButton={triggerProps}
                                                    primaryActionButton={{ onClick: () => setRefreshGrid(true) }}
                                                    icon={<ArrowSync20Regular />}
                                                >
                                                    Reload
                                                </SplitButton>
                                            )}
                                        </MenuTrigger>
                                        <MenuPopover>
                                            <MenuList>
                                                <MenuItem persistOnClick={true}>
                                                    <Checkbox
                                                        label="Auto-refresh"
                                                        defaultChecked={autoRefresh}
                                                        onChange={() => setAutoRefresh(!autoRefresh)}
                                                    />
                                                </MenuItem>
                                            </MenuList>
                                        </MenuPopover>
                                    </Menu>

                                    {/*Search collection*/}
                                    <Popover withArrow open={isSearchVisible}>
                                        <PopoverTrigger disableButtonEnhancement>
                                            <Button
                                                onClick={showSearch}
                                                appearance='subtle' icon={<SearchRegular />}>
                                                Search
                                            </Button>
                                        </PopoverTrigger>
                                        <PopoverSurface tabIndex={-1}>
                                            <IconButton
                                                iconProps={{ iconName: 'Cancel' }}
                                                ariaLabel="Close"
                                                onClick={closeSearch}
                                                style={{ position: 'absolute', top: 0, right: 0 }}
                                            />
                                            <Button onClick={clearFilter}>Clear</Button>
                                            <TextField
                                                placeholder='Search...'
                                                label="Search by name"
                                                value={searchFilter}
                                                onChange={searchFilterOnChange}
                                            />
                                        </PopoverSurface>
                                    </Popover>
                                </div>

                                {/* File upload button and dropzone */}
                                {openUpload ? (
                                    <div className={styles.fileUpload}>
                                        {isAuthorizedToUpload ? (
                                            <div className={styles.uploadContainer}>
                                                <UploadArea indexName={selectedCollection ? selectedCollection.collection_name : ''}
                                                    setRefreshGrid={setRefreshGrid}
                                                    setHasUpload={setHasUpload}
                                                />
                                                {!hasUpload ?
                                                    <Button onClick={openUploadChange} style={{ margin: '15px auto', display: 'block' }}>Close</Button>
                                                    : ''}
                                            </div>
                                        ) : ('')}
                                    </div>
                                ) : ('')}


                                {/* Main content */}
                                <div className={styles.fileExplorer}>
                                    {selectedCollection ? (
                                        <>
                                            <div className={styles.main}>
                                                {/* Name of Collection Selected */}
                                                <Text
                                                    as='span'
                                                    size={600}
                                                    weight='semibold'
                                                    style={{ marginBottom: '50px', color: '#00b0ba' }}
                                                ><ArchiveRegular style={{ marginRight: '8px' }} />
                                                    {/* Show the display_name instead of the id or name */}
                                                    {selectedCollection ? selectedCollection.display_name : "Select a collection"}</Text>
                                                <p>{selectedCollection.description}</p>
                                                <p><FolderOpenRegular /> {selectedCollection.category}</p>

                                                {/* Divider */}
                                                <Divider inset appearance='strong' />


                                                {/* size of collection & refresh button */}
                                                <div className={styles.collectionHeader}>
                                                    {/* Display count of files*/}
                                                    <Text
                                                        size={400}
                                                        weight='semibold'
                                                        style={{ color: 'var(--primary-color)' }}
                                                    >{numberOfFilesInSelectedCollection ? numberOfFilesInSelectedCollection : "0"} files
                                                    </Text>
                                                </div>
                                                {/* Upload Area */}
                                                <UploadArea indexName={selectedCollection ? selectedCollection.collection_name : ''}
                                                    setRefreshGrid={setRefreshGrid}
                                                    setHasUpload={setHasUpload}
                                                />




                                                {/* List Files */}
                                                <div className={styles.fileStatusContainer}>
                                                    <FileStatus
                                                        indexName={selectedCollection ? selectedCollection.collection_name : ''}
                                                        shouldRefresh={refreshGrid}
                                                        setSelectedPdf={setSelectedPdf}
                                                        setRefreshGrid={setRefreshGrid}
                                                        setNumberOfFilesInSelectedCollection={setNumberOfFilesInSelectedCollection}
                                                        autoRefresh={autoRefresh}
                                                        searchFilterText={searchFilter}
                                                        isBEadmin={isAuthorizedToViewQuickSettingsMenu}
                                                    />
                                                </div>


                                            </div>
                                        </>
                                    ) : (
                                        <div className={styles.selectACollection}>
                                            {/* Logo */}
                                            <Image
                                                src={BEAILogo}
                                                alt="BEAI Logo"
                                                height={102}
                                            />
                                            <Text
                                                as='span'
                                                size={600}
                                            >Document Collection Manager</Text>

                                            {/* Divider */}
                                            <div style={{ borderTop: '1px solid var(--primary-color)', width: '80%' }} />
                                            {/* Header Text */}

                                            <Text
                                                as="h1"
                                                size={300}
                                                weight="bold"
                                                align="center"
                                                style={{ color: 'var(--bold-text)' }}
                                            >{"Create or Select a Collection to start."}
                                            </Text>

                                            {/* First Time graphic */}
                                            <ArrowCircleLeft32Filled />
                                        </div>
                                    )}
                                </div>
                            </div>


                        </div>
                    </DialogContent>

                </DialogBody>
            </DialogSurface>
        </Dialog >


    )
}
