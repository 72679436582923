// This will display the user information

import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Button, Text } from "@fluentui/react-components";
import { Popover, PopoverSurface, PopoverTrigger } from "@fluentui/react-popover";
import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { getTokenLimitApi, getTokensSinceResetApi, getTotalTokensApi } from "../../api";
import styles from "./UserProfile.module.css";

const UserProfile = () => {
    const navigate = useNavigate();
    const { isAuthenticated, logout, user, getAccessTokenSilently } = useAuth0();
    const [ isCalloutVisible, setIsCalloutVisible ] = useState(false);
    const [ totalTokens, setTotalTokens ] = useState<number | null>(null);
    const [ tokensSinceReset, setTokensSinceReset ] = useState<number | null>(null);
    const [ tokenLimit, setTokenLimit ] = useState<number | null>(null);

    const now = new Date(); // Date calculations
    const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    const daysUntilReset = Math.ceil((nextMonth.getTime() - now.getTime()) / (1000 * 60 * 60 * 24));

    // Check auth
    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/login");
        }
    }, [ isAuthenticated, navigate ]);

    useEffect(() => {
        const fetchAllData = async () => {
            if (user && user.name) {
                try {
                    const token = await getAccessTokenSilently();

                    // Fetch total tokens
                    const totalTokens = await getTotalTokensApi(user.name, token);
                    setTotalTokens(totalTokens);

                    // Fetch tokens since reset
                    const tokensSinceReset = await getTokensSinceResetApi(user.name, token);
                    setTokensSinceReset(tokensSinceReset);

                    // Fetch token limit
                    const tokenLimit = await getTokenLimitApi(user.name, token);
                    setTokenLimit(tokenLimit);

                    // Testing
                    //console.log(`token info for ${user.name} total: ${totalTokens} used since reset: ${tokensSinceReset} limit: ${tokenLimit}`);
                } catch (error) {
                    console.error("Failed to fetch data:", error);
                }
            }
        };

        fetchAllData();
    }, [ user ]);

    // toggle popover
    const toggleIsCalloutVisible = async () => {
        setIsCalloutVisible(!isCalloutVisible);

        // Fetch data once the popover is opened
        if (!isCalloutVisible) {
            try {
                if (user && user.name) {
                    const access_token = await getAccessTokenSilently();
                    // Fetch total tokens
                    await getTotalTokensApi(user.name, access_token);

                    // Fetch tokens since reset
                    await getTokensSinceResetApi(user.name, access_token);

                    // Fetch token limit
                    await getTokenLimitApi(user.name, access_token);
                }
            } catch (error) {
                console.error("Failed to fetch data:", error);
            }
        }
    };

    // check screen width
    const isDesktop = useMediaQuery({ minWidth: 980 });
    // split email if screen is over 980px
    const displayEmail = !isDesktop ? user!.email!.split("@")[ 0 ] : user!.email!;

    return (
        <div className={styles.userInfoBtnContainer}>
            <Popover>
                <PopoverTrigger>
                    <Button
                        onClick={toggleIsCalloutVisible}
                        id="userInfoButton"
                        appearance="transparent"
                        className={styles.userInfoButton}
                    // color='colorBrandBackground'
                    >
                        <Avatar className={styles.userAvatar} name={user?.name} color="brand" />
                        <Text className={styles.userInfoEmail}> {displayEmail}</Text>
                    </Button>
                </PopoverTrigger>
                <PopoverSurface
                    style={{
                        borderRadius: "15px",
                        padding: "10px",
                        backgroundColor: "white",
                        border: "none",
                        boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)"
                    }}
                >
                    <div className={styles.popoverContaner}>
                        <div className={styles.popover}>
                            <div className={styles.topRow}>
                                <div className={styles.userInfo}>
                                    {tokenLimit !== null ? (
                                        <div className={styles.ticketsLeftContainer}>
                                            {/* Tokens left until reset */}
                                            <Text size={700} weight="bold" className={styles.tokensLeftNumber}>
                                                {/* tokenLimit - tokensUsed - default value if null is 0 */}
                                                {Math.round((tokenLimit - (tokensSinceReset || 0)) / 1000)}{" "}
                                                <span className={styles.tokensLeftLimit}>
                                                    /
                                                    {Math.round(tokenLimit / 1000)}
                                                </span>
                                            </Text>

                                            <p>Credits remaining</p>

                                            <progress value={Math.floor((tokenLimit - (tokensSinceReset || 0)) / 1000)} max={Math.floor(tokenLimit / 1000)}></progress>
                                        </div>
                                    ) : (
                                        <p className={styles.tokensLeftLimit}>Unlimited</p>
                                    )}
                                </div>

                                {/*  User Info */}
                                <div className={styles.userName}>
                                    <Text size={500} weight="bold" className={styles.userNameName}>
                                        {user?.nickname}
                                    </Text>

                                    <hr />

                                    <Text size={300} style={{ color: "gray" }}>
                                        {user?.name}
                                    </Text>

                                    <hr />
                                    
                                    <Text style={{ color: "gray" }}>Resets in {daysUntilReset} days</Text>
                                </div>
                            </div>

                            <div className={styles.bottomRow}>
                                {/* Logout */}
                                <button className={styles.logoutButton} onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                                    Log Out
                                </button>
                            </div>
                        </div>
                    </div>
                </PopoverSurface>
            </Popover>
        </div>
    );
};

export default UserProfile;
