// this component will take a url and submit it to the backend

import { useAuth0 } from '@auth0/auth0-react';
import { TextField } from '@fluentui/react';
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Text } from '@fluentui/react-components';
import { useEffect, useState } from 'react';
import { uploadFileApi } from '../../api';
import styles from './UploadUrl.module.css';

type UploadUrlProps = {
    index_name: string;
}

const UploadUrl = ({ index_name }: UploadUrlProps) => {
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [url, setUrl] = useState<string>(''); // url to upload
    const { getAccessTokenSilently, user } = useAuth0();
    const [isVisible, setIsVisible] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');


    const openDialog = () => setIsVisible(true);

    const closeDialog = () => {
        setUrl(''); // Clear the URL input field
        setSuccessMessage(''); // Clear any previous success message
        setErrorMessage(''); // Clear any previous error message
        setIsVisible(false);
    }


    // handle upload
    const handleUpload = async () => {
        setIsProcessing(true);
        try {
            const userTag = user?.name?.toString() || 'unknown'; // create a userTag with the user.name from auth0
            const token = await getAccessTokenSilently();

            // Create a FormData object
            const formData = new FormData();
            // Create a blob from the URL string
            const contentBlob = new Blob([url], { type: 'text/plain' });

            // Dynamically create filename based off url
            const urlToUseAsFilename = url
                .replace(/^https?:\/\//, '') // Remove http:// or https://
            // .replace(/[^a-zA-Z0-9]/g, '_') // Replace any character that is not alphanumeric
            //    .substring(0, 40); // Limit the length to 40 characters for simplicity

            // Set as name for file
            const filename = `webpage-${urlToUseAsFilename}.url`;

            // Append the blob to the FormData object, using a filename
            formData.append('files', contentBlob, filename);
            // Optionally, append other data like userTag if needed
            formData.append('userTag', userTag);

            // Call the uploadFileApi function
            const response = await uploadFileApi(formData, index_name, token);

            // Parse the response
            const data = JSON.parse(response.success);
            setSuccessMessage(`Webpage added to Document Collection`);
            setErrorMessage(''); // Clear any previous error message
            setUrl('');


        } catch (error) {
            console.error(error);
            setIsProcessing(false);
            if (error instanceof Error) {
                setErrorMessage(`Error: ${error.message}`);
            } else {
                // Handle cases where the error is not an Error instance
                setErrorMessage(`An unknown error occurred`);
            }
            setSuccessMessage(''); // Clear any previous success message
        } finally {
            setIsProcessing(false);
        }
    }
    // function to clear on collection name
    useEffect(() => {
        if (index_name) {
            setUrl(''); // Clear the URL input field
            setSuccessMessage(''); // Clear any previous success message
            setErrorMessage(''); // Clear any previous error message
        }
    }, [index_name]);



    return (
        <div>
            {/* Error / Success message */}
            <>
                {successMessage && (
                    <div style={{ color: 'green', marginBottom: '10px' }}>
                        {successMessage}
                    </div>
                )}
                {errorMessage && (
                    <div style={{ color: 'red', marginBottom: '10px' }}>
                        {errorMessage}
                    </div>
                )}
            </>



            <div className={styles.inputContainer}>
                <div className={styles.textField}>
                    <TextField
                        // label="Submit URL"
                        placeholder="Example: https://www.networkrail.co.uk/running-the-railway/railway-upgrade-plan/key-projects/crossrail/"
                        value={url}
                        onChange={(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                            if (newValue != null) {
                                setUrl(newValue);
                            }
                        }}
                    />
                </div>

                <div className={styles.button}>
                    <Button
                        appearance='primary'
                        onClick={handleUpload}
                        disabled={isProcessing}
                    >
                        {isProcessing ? "Validating..." : "Add Page"}
                    </Button>

                </div>
            </div>


        </div>
    )
}

export default UploadUrl