// Landing page - images are in public/images

import { Warning24Regular } from "@fluentui/react-icons";
import React from 'react';
import LoginButton from '../../components/LogInButton/LogInButton';
import styles from './landingPage.module.css';


const LandingPage: React.FC = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const authMessage = queryParameters.get("message");

    return (
        <div className={styles.container}>

            <section>
                <img src="/images/BEAILogoFinal.png" width="350px" />

                {authMessage ? (
                    <>
                        <div className={styles.messageDialog}>
                            <Warning24Regular />
                            <p>{authMessage}</p>
                        </div>
                    </>
                ) : ('')}

                <menu>
                    <div className={styles.linksContainer}>
                        <><LoginButton className={styles.loginButton} /></>
                    </div>
                </menu>
            </section>

            <footer>
                <div className={styles.info}>
                    <p>
                        <strong>Blue Edge</strong><br />
                        Runway East, York And Elder Works, 50 New England St, Brighton, BN1 4AW
                    </p>

                    <p>
                        <strong>Telephone</strong><br />
                        <a href="tel:01273328125">+44(0) 1273 328 125</a>
                    </p>

                    <p>
                        <strong>Email</strong><br />
                        <a href="mailto:info@blue-edge.co.uk">info@blue-edge.co.uk</a>
                    </p>
                </div>

                <p id="copyright">
                    Copyright ©
                    <script>
                        document.write(new Date().getFullYear());
                    </script>
                    Blue Edge
                </p>
            </footer>
        </div>
    )
}

export default LandingPage