// This will be the feedback component. It will have thumbs up and thumbs down buttons with a section to add a note.

import { Button, Textarea } from '@fluentui/react-components'
import { ThumbDislikeFilled, ThumbDislikeRegular, ThumbLikeFilled, ThumbLikeRegular } from '@fluentui/react-icons'
import React from 'react'
import styles from './Feedback.module.css'
import { feedbackApi } from '../../api'
import { useAuth0 } from '@auth0/auth0-react'


type FeedbackProps = {
    run_id: string
}

const Feedback = ({ run_id }: FeedbackProps) => {
    const { getAccessTokenSilently } = useAuth0();
    // states for like, dislike and note
    const [ like, setLike ] = React.useState(false); // thumb up
    const [ dislike, setDislike ] = React.useState(false); // thumb down
    const [ note, setNote ] = React.useState(''); // note
    const [ loading, setLoading ] = React.useState(false);
    const [ submitStatus, setSubmitStatus ] = React.useState('');

    // function to handle like button click
    const handleLike = () => {
        setLike(true);
        setDislike(false);
    }

    // function to handle dislike button click
    const handleDislike = () => {
        setLike(false);
        setDislike(true);
    }

    // function to handle note change
    const handleNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNote(event.target.value);
    }

    // function to handle submit button click
    const handleSubmit = async () => {
        setLoading(true);
        setSubmitStatus('');
        const score = like ? 1 : 0; // score is 1 if like is true, 0 otherwise
        const token = await getAccessTokenSilently(); // get token to pass to api

        try {
            const response = await feedbackApi(run_id, score, note, token);

            // Check if the response was successful
            if (response.status === 200) {
                console.log('Feedback submitted successfully');
                setSubmitStatus('Saved!'); // set status to saved
            } else {
                console.log('Failed to submit feedback');
                setSubmitStatus('Fail'); // set status to fail
            }
        } catch (error) {
            console.error('An error occurred while submitting feedback:', error);
            setSubmitStatus('Fail');
        } finally {
            setLoading(false);
        }
    }


    return (
        <div>
            {submitStatus != 'Saved!' ? (
                <>
                    <div className={styles.feedbackContainer}>
                        {/* Thumb Up */}
                        <Button
                            appearance="transparent"
                            icon={like ? <ThumbLikeFilled /> : <ThumbLikeRegular />}
                            style={{ color: "#00b0ba" }}
                            shape='circular'
                            onClick={handleLike}
                        />

                        {/* Thumb Down */}
                        <Button
                            appearance="transparent"
                            icon={dislike ? <ThumbDislikeFilled /> : <ThumbDislikeRegular />}
                            style={{ color: "#00b0ba", display: 'block' }}
                            shape='circular'
                            onClick={handleDislike}
                        />
                        <p>Rate your response</p>

                    </div>

                    <div className={styles.dislikeFeedback} style={{ height: dislike || like ? 'auto' : '0px' }} >
                        {/* Text Area to add comment */}
                        <Textarea
                            placeholder={dislike ? "Please tell us more. Why was this a bad response?" : "Please tell us more. Why was this a good response?"}
                            onChange={handleNoteChange}
                            appearance='outline'
                            disabled={loading}

                        />
                        {/* Submit Button */}
                        <Button
                            size='small'
                            appearance='primary'
                            onClick={handleSubmit}
                            disabled={loading}
                        >
                            {loading ? 'Sending...' : submitStatus || 'Submit feedback'}
                        </Button>
                    </div>
                </>
            ) : (
                <div className={styles.feedbackContainer}>
                    <p>Thank you for submitting your feedback.</p>
                </div>
            )}
        </div >
    )
}

export default Feedback