// This component will delete all the files in a collection. It clears the db entry
import { useAuth0 } from '@auth0/auth0-react';
import { DefaultButton, Dialog, DialogContent, DialogFooter, PrimaryButton, Spinner } from '@fluentui/react';
import { Button, Text, Popover, PopoverTrigger, PopoverSurface } from '@fluentui/react-components';
import { BinRecycleFullRegular } from '@fluentui/react-icons';
import React, { useState } from 'react';
import { CollectionName, deleteCollectionApi, deleteCollectionFileStatusApi, SelectedFile } from '../../api';
import styles from './DeleteCollection.module.css';


type Props = {
    indexName: string;
    fetchCollections: () => void;
    setSelectedCollection: (collection: CollectionName | undefined) => void;
    setSelectedPdf: (pdf: SelectedFile) => void;
}

export const DeleteCollection: React.FC<Props> = ({ indexName, fetchCollections, setSelectedCollection }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [dialogVisible, setDialogVisible] = React.useState(false); // Track if the dialog is visible
    const [deleteSuccessful, setDeleteSuccessful] = useState(false); // Track if the delete operation was successful
    const [message, setMessage] = useState(''); // Track the success/fail message
    const [isDeleting, setIsDeleting] = React.useState(false); // Track if the delete operation is in progress
    const [confirmInput, setConfirmInput] = useState(''); // state to track text input in confirmation popover


    // Delete the collection
    const handleDeleteCollection = async () => {
        console.log('Delete collection started', indexName);
        setIsDeleting(true); // Start the spinner
        try {
            const token = await getAccessTokenSilently();
            await deleteCollectionFileStatusApi(indexName, token); // delete all file status with the index name
            await deleteCollectionApi(indexName, token); // delete the collection itself (index)
            setMessage('Successfully deleted all files and their statuses in the collection.');
            setDeleteSuccessful(true);
        } catch (error) {
            console.error('Error emptying collection', error);
            setDeleteSuccessful(false);
            setMessage('Failed to delete all files and their statuses in the collection.');
        }
        setIsDeleting(false); // Stop the spinner
        setDialogVisible(false); // hide the dialog
        fetchCollections(); // refresh list of collections
        setSelectedCollection(undefined); // clear the selected collection from state
    }

    // Open the dialog
    const handleOpenDialog = () => {
        setDialogVisible(true);
        setMessage('');
        setDeleteSuccessful(false);
        setConfirmInput('');
    };

    // Close the dialog
    const handleCancelDelete = () => {
        setDialogVisible(false);
        setDeleteSuccessful(false);
        setConfirmInput('');
    };


    return (


        <Popover
            open={dialogVisible}
            trapFocus
            withArrow
        >
            <PopoverTrigger>
                <Button
                    appearance='subtle'
                    icon={<BinRecycleFullRegular />}
                    onClick={handleOpenDialog}
                    disabled={indexName == ''}
                >
                    Delete Collection
                </Button>
            </PopoverTrigger>
            <PopoverSurface>
                <div className={styles.deleteContainer}>
                    <h2 style={{ color: '#e62c30' }}><BinRecycleFullRegular /> Delete Collection</h2>
                    <p>Are you sure you want to delete the entire Document Collection?</p>
                    <p>Please Note: If you plan on recreating this collection with the same name, please allow 5 minutes for the old data to be removed before creating it again.</p>
                    <br></br>
                    <p>Please type <span className={styles.dialogConfirm}>Delete</span> to confirm</p>
                    <input
                        type="text"
                        onChange={(e) => setConfirmInput(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter' && confirmInput === 'Delete') {
                                handleDeleteCollection();
                            }
                        }}
                    />
                    <br></br><br></br>

                    {isDeleting ? (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Spinner label="Deleting Collection" />
                        </div>
                    ) : (
                        <div className={styles.btnContainer}>
                            {!deleteSuccessful &&
                                <PrimaryButton
                                    disabled={confirmInput !== 'Delete'}
                                    onClick={handleDeleteCollection}
                                    text="Delete Collection"
                                />
                            }
                            <DefaultButton disabled={isDeleting} onClick={handleCancelDelete} text="Cancel" />
                        </div>
                    )}
                    {/* Display the success/fail message - orange if fail */}
                    {message && (
                        <Text style={{ color: message.startsWith('Successfully') ? 'green' : 'orange' }}>
                            {message}
                        </Text>
                    )}
                </div>
            </PopoverSurface>

        </Popover>
    )
}
