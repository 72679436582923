import * as Icons from '@fluentui/react-icons';
import styles from "./PersonaIcon.module.css";

interface Props {
    iconName?: string;
    iconSize?: string;
    iconColor?: string;
}

export const PersonaIcon = ({
    iconName = 'BookExclamationMarkRegular',
    iconSize = 'auto',
    iconColor }: Props) => {

    //display dynamic icon
    const DynamicIcon = () => {
        if (iconName) {
            const IconComponent = (Icons as never)[ iconName ] as React.FC<React.HTMLAttributes<HTMLSpanElement>>;

            if (!IconComponent) { // Return a default one
                return <Icons.BookExclamationMarkRegular  className={styles.icon} />;
            }

            return <IconComponent className={`${styles.icon} ${styles[iconSize]}`} style={{ color: iconColor }} />;
        }
        // Return a default one if iconName is not defined
        return <Icons.BookExclamationMarkRegular className={styles.icon} />;
    };

    return <DynamicIcon />;
};

export default PersonaIcon;