// This component will display the terms of service for the app.
// The TOS is 30 pages, so with performance in mind we will load the text from a file.

// tosmd.md is in /public

import styles from './TermsOfService.module.css';


const TermsOfService = () => {

    return (
        <div className={styles.container}>
            <iframe src='https://blue-edge.co.uk/ai/AITnC.pdf' width={'100%'} height={'100%'} />
        </div>
    )
}

export default TermsOfService