// Collections selector - list of collections the user can select from

import { useAuth0 } from "@auth0/auth0-react";
import { Checkbox, CheckboxOnChangeData, Popover, PopoverProps, PopoverSurface, Skeleton, SkeletonItem, Text, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { ArchiveRegular, ErrorCircle24Regular } from '@fluentui/react-icons';
import React, { useEffect, useState } from 'react';
import { lightTheme } from '../../BlueEdgeTheme';
import { CollectionName, getCollectionsApi } from '../../api';
import styles from './CollectionSelector.module.css';


interface Props {
  selectedCollections: CollectionName[];
  setSelectedCollections: (indexes: CollectionName[]) => void;
  refreshCollection: boolean;
  setRefreshCollection: (refreshCollection: boolean) => void;
}

// Styles for the skeleton row
const useStyles = makeStyles({
  invertedWrapper: {
    backgroundColor: tokens.colorNeutralBackground1,
  },
  skeletonRow: {
    alignItems: "center",
    display: "grid",
    paddingBottom: "10px",
    position: "relative",
    ...shorthands.gap("10px"),
    gridTemplateColumns: "min-content 80%",
    height: '5vh' // matches the height of the personaListItem
  },
  text: {
    color: lightTheme.colorBrandForeground1
  },
});


const CollectionSelector: React.FC<Props> = ({ selectedCollections, setSelectedCollections, refreshCollection, setRefreshCollection }) => {
  const [collections, setCollections] = useState<CollectionName[] | undefined>(undefined);
  const uistyles = useStyles();
  const { getAccessTokenSilently } = useAuth0();
  const [openErrorIndicator, setOpenErrorIndicator] = useState(false); //error popover
  const [groupedCollections, setGroupedCollections] = useState<{ [key: string]: CollectionName[] }>({});

  // handle open error popover
  const handleOpenChange: PopoverProps["onOpenChange"] = (e, data) =>
    setOpenErrorIndicator(data.open || false);

  // function to get the list of collections
  const fetchData = async () => {
    try {
      const token = await getAccessTokenSilently();
      // localStorage.setItem('accessToken', token);
      const collectionsNameList = await getCollectionsApi(token);
      if (collectionsNameList) {
        setCollections(collectionsNameList);
      } else {
        setCollections(undefined);
      }
      setRefreshCollection(false);
    } catch (error) {
      console.error('Failed to retrieve collections', error);
      setCollections(undefined);
    }
  };

  // Fetch collections on component mount
  useEffect(() => {
    fetchData();
  }, []);

  //Refresh collection list
  useEffect(() => {
    if (refreshCollection) {
      fetchData();
    }
  }, [refreshCollection]);

  // function to handle checkbox changes - powerLevel<boolean>
  const onChange = (ev: React.FormEvent<HTMLElement>, data: CheckboxOnChangeData, collection: CollectionName) => {
    const isChecked = data.checked; // See if the item is checked
    let newSelectedCollections = [...selectedCollections]; // creates a new array with same data

    if (isChecked) {
      // if the box is checked, add the collection to the array
      newSelectedCollections.push(collection);
    } else {
      // if the box is unchecked, remove the collection from the array
      newSelectedCollections = newSelectedCollections.filter((selectedCollection) => selectedCollection.id !== collection.id);
    }

    setSelectedCollections(newSelectedCollections);
    localStorage.setItem('selectedCollections', JSON.stringify(newSelectedCollections));
  };

  // handle collection selection
  const onClickChange = (collection: CollectionName) => {
    let newSelectedCollections = [...selectedCollections]; // creates a new array with same data
    const isSelected = newSelectedCollections.some(newSelectedCollections => newSelectedCollections.id === collection.id)
    if (!isSelected) {
      newSelectedCollections.push(collection);
    } else {
      newSelectedCollections = newSelectedCollections.filter((selectedCollection) => selectedCollection.id !== collection.id);
    }
    setSelectedCollections(newSelectedCollections);
    localStorage.setItem('selectedCollections', JSON.stringify(newSelectedCollections));
  }

  // Sort collections by category
  useEffect(() => {
    if (collections) {
      const grouped = collections.reduce((acc, collection) => {
        if (!acc[collection.category]) {
          acc[collection.category] = [];
        }
        acc[collection.category].push(collection);
        return acc;
      }, {} as { [key: string]: CollectionName[] });
      setGroupedCollections(grouped);
    }
  }, [collections]);


  return (
    <div>
      {/* error popup - leaving this for future use */}
      <Popover appearance="brand" withArrow open={openErrorIndicator} onOpenChange={handleOpenChange}>
        <PopoverSurface tabIndex={-1}>
          <p><ErrorCircle24Regular /> You must select a higher power level to select more Document Collections</p>
        </PopoverSurface>
      </Popover>
      {/* List the collections */}
      {collections === null ? (
        <div style={{ textAlign: 'center' }}>
          <p>There was an error fetching the collections.</p>
        </div>
      ) : collections === undefined ? (
        // display 4 skeleton checkboxes if no collections are loaded yet
        <div style={{ textAlign: 'center' }}>
          <Skeleton >
            <div className={uistyles.skeletonRow}>
              <SkeletonItem shape="square" size={24} />
              <SkeletonItem />
            </div>
          </Skeleton>
          <Skeleton >
            <div className={uistyles.skeletonRow}>
              <SkeletonItem shape="square" size={24} />
              <SkeletonItem />
            </div>
          </Skeleton>
          <Skeleton >
            <div className={uistyles.skeletonRow}>
              <SkeletonItem shape="square" size={24} />
              <SkeletonItem />
            </div>
          </Skeleton>
          <Text
            as='p'
            size={200}
            className={uistyles.text}
          >Loading Collections...</Text>
        </div>
      ) : collections.length === 0 ? (
        // display a message if collections is an empty array
        <div style={{ textAlign: 'center' }}>
          <p>No collections found.</p>
        </div>
      ) : (
        // display the collections with category header
        <div className={styles.list}>
          {Object.keys(groupedCollections)
            .sort((a, b) => a.localeCompare(b))
            .map((category, index) => (
              <div key={index}>
                <h3 className={styles.gizmoComponentSubheading}>{category}</h3>
                <div>
                  {groupedCollections[category]
                    .sort((a, b) => a.display_name.localeCompare(b.display_name))
                    .map((collection, index) => {
                      const isSelected = selectedCollections.some(
                        (selectedCollection) => selectedCollection.id === collection.id
                      );
                      const collectionClass = isSelected ? styles.selectedCollection : '';

                      return (
                        <div
                          key={index}
                          onClick={() => onClickChange(collection)}
                          className={`${styles.collectionSelectBox} ${collectionClass}`}
                        >
                          <Checkbox
                            onChange={(ev, data) => onChange(ev, data, collection)}
                            checked={isSelected}
                            size="medium"
                          />
                          <div style={{ flexDirection: 'column' }}>
                            <p className={styles.collectionName}>
                              <ArchiveRegular /> {collection.display_name}
                            </p>
                            <p className={styles.collectionDescription}>{collection.description}</p>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  )
}

export default CollectionSelector