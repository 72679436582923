import { MessageBar, MessageBarType, Stack } from "@fluentui/react";
import { ArchiveRegular, Cloud20Regular, DocumentPdfRegular, DocumentRegular, DocumentTextRegular, PreviewLinkRegular } from "@fluentui/react-icons";
import DOMPurify from "dompurify";
import { saveAs } from 'file-saver';
import { useEffect, useMemo, useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import styles from "./Answer.module.css";

import { Document, HeadingLevel, Packer, Paragraph } from 'docx';
import { AskResponse, CollectionName } from "../../api";
import { getCollectionsApi, retrieveFileApi } from '../../api/api';
import { parseAnswerToHtml } from "./AnswerParser";

import { useAuth0 } from "@auth0/auth0-react";
import {
    Button
} from "@fluentui/react-components";
import { ArrowCircleDownRegular, CopyRegular } from '@fluentui/react-icons';
import Feedback from "../Feedback/Feedback";



interface Props {
    answer: AskResponse;
    isSelected?: boolean;
    onCitationClicked: (filePath: string, documentID: string, sourceName: string) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
    selectedIndexes: CollectionName[];
    settingsButtonVisible?: boolean;
    webResultsIncluded: boolean;
    exportFollowupQuestions: (followupQuestions: string[]) => void;
}

export const Answer = ({
    answer,
    isSelected,
    onCitationClicked,
    // onThoughtProcessClicked,
    // onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions,
    selectedIndexes,
    webResultsIncluded,
    exportFollowupQuestions
}: Props) => {
    // removed the clickable link from the citation buttons
    // const parsedAnswer = useMemo(() => parseAnswerToHtml(answer.answer, onCitationClicked, selectedIndexes[0]), [answer]);
    const parsedAnswer = useMemo(() => parseAnswerToHtml(answer.answer, selectedIndexes), [answer]);
    const [allCollectionNames, setAllCollectionNames] = useState<CollectionName[]>([]); // hold all names of collections

    const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);
    const [isCopied, setIsCopied] = useState(false);
    const { getAccessTokenSilently } = useAuth0();
    const [tokensUsed, setTokensUsed] = useState(Number);

    // Function to get all collection names
    async function getAllCollectionNames() {
        const accessToken = await getAccessTokenSilently();
        const allCollectionNames = await getCollectionsApi(accessToken);
        setAllCollectionNames(allCollectionNames ?? []);
        return allCollectionNames ?? [];
    }

    // Use Effect to get all collection names on page load
    useEffect(() => {
        getAllCollectionNames();
    }, []);


    // Function to copy to clipboard
    function copyToClipboard(divId: string): void {
        const divElement = document.getElementById(divId);
        if (divElement) {
            const html = divElement.innerHTML;
            const blob = new Blob([html], { type: 'text/html' });
            const clipboardItem = new ClipboardItem({ 'text/html': blob });

            try {
                navigator.clipboard.write([clipboardItem]);
            } catch (err) {
                console.error('Failed to copy HTML content: ', err);
            }
        } else {
            console.error(`Element with id ${divId} not found.`);
        }
    }

    // return word count
    function wordCount(text: string) {
        const mainAnswer = text.replace(/\[.*?\]/g, '').replace(/<<.*?>>/g, '').trim();
        const mainAnswerCounted = mainAnswer.split(/\s+/).length;
        return mainAnswerCounted
    };

    // Create a word document
    function createWordDoc(filename: string) {
        const mainAnswer = answer.answer.replace(/\[.*?\]/g, '').replace(/<<.*?>>/g, '').trim();
        console.log(mainAnswer);
        const answerSplitText = mainAnswer.split(/\r?\n/);
        // Create a new Document
        const buildParagraph = () => {
            const paragraphArray = [];
            for (let i = 0; i < answerSplitText.length; i++) {
                paragraphArray.push(new Paragraph({ text: answerSplitText[i] }));
            }
            return paragraphArray;
        };

        const doc = new Document({
            creator: "Blue Edge AI",
            title: "Blue Edge AI Answer",
            styles: {
                default: {
                    document: {
                        run: {
                            size: "11pt",
                            font: "Calibri",
                        },
                    },
                }
            },
            sections: [
                {

                    children: [
                        new Paragraph({ text: 'Blue Edge AI', heading: HeadingLevel.HEADING_1 }),
                        ...buildParagraph(), // paragraphs are not coming through
                    ],
                },
            ],
        });



        // Pack the Document
        Packer.toBlob(doc)
            .then((blob) => {
                // saveAs from FileSaver will download the file
                saveAs(blob, filename + ".docx");
            })
            .catch((error) => {
                console.error("Error generating the document:", error);
                // Handle the error appropriately here
            });
    }

    //create exported followup question array
    useEffect(() => {
        if (parsedAnswer.followupQuestions.length && showFollowupQuestions && onFollowupQuestionClicked) {
            exportFollowupQuestions(parsedAnswer.followupQuestions);
        }
    }, [parsedAnswer]);

    // handle click on citation
    async function handleCitationClick(
        index: string,
        sourceName: string,
        documentId: string
    ) {
        // check for values
        if (!index || !sourceName || !documentId) {
            console.log("Error: Citation values are missing when calling handleCitationClick in answer.tsx.");
            console.log("Index:", index);
            console.log("SourceName:", sourceName);
            console.log("DocumentId:", documentId);
            return { error: true, message: "An error occurred" };
        }
        const token = await getAccessTokenSilently();
        // Order: index_name, document_id, filename, accessToken
        try {
            const fileUrl = await retrieveFileApi(index, documentId, sourceName, token);
            onCitationClicked(fileUrl, documentId, sourceName);
        } catch (error) {
            console.error("Error retrieving file from API", error);
            alert("Error retrieving file from API");
        }
    };

    //extract the tokens use from 'thoughts' (This can probably be done differently but this is good for now.)
    useEffect(() => {
        if (answer?.thoughts) { // Ensure answer and thoughts exist
            const tokensMatch = answer.thoughts.match(/used (\d+) tokens/); // Match the number between "used" and "tokens"

            const tokens = tokensMatch ? parseInt(tokensMatch[1], 10) : 0; // Safely parse the matched number or default to 0

            setTokensUsed(tokens / 1000); // Set the divided value in state
        }
    }, [answer]);


    return (
        <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">

            {/* Message bar success/fail copy */}
            {isCopied && (
                <MessageBar
                    messageBarType={MessageBarType.success}
                    isMultiline={false}
                    onDismiss={() => setIsCopied(false)}
                    className={styles.messageBar}
                >
                    Text copied to clipboard!
                </MessageBar>
            )}

            <Stack.Item grow>
                {/* Alert that results contain content from the web */}
                {webResultsIncluded ?
                    <div className={styles.webResultWarning}>
                        <Cloud20Regular /> This answer includes sources from the internet, this may effect accuracy of results <Cloud20Regular />
                    </div> : null}

                {/* Markdown */}
                <div id="TheAnswer" className={styles.answerText}>
                    <ReactMarkdown children={sanitizedAnswerHtml} rehypePlugins={[rehypeRaw]} />
                </div>

            </Stack.Item>

            {/* Word Count */}
            <div className={styles.wordCount}><p><strong>Word Count:</strong> {wordCount(answer.answer)}</p></div>


            {/* display citations if any */}
            {!!parsedAnswer.citations.length && (

                <div className={styles.citationList}>
                    <h3 className={styles.gizmoComponentSubheading}>PDF Citations</h3>
                    <Stack.Item>

                        <Stack wrap tokens={{ childrenGap: 5 }}>
                            {parsedAnswer.citations.map((x, i) => {
                                // Split the string into parts
                                const parts = x.split('/');

                                // Search allCollectionNames for the matching dp.index and set that as the display name
                                const collection = allCollectionNames.find(collection => collection.collection_name === parts[0]);
                                const displayName = collection ? collection.display_name : parts[0];

                                // format is: index/filename/documentId - extract values
                                const index = displayName;
                                const sourceName = parts[1]; // filename
                                const documentId = parts[2];


                                return (
                                    <div key={i}
                                        className={styles.citation} title={`${sourceName} - ${index}`}
                                        onClick={() => handleCitationClick(parts[0], sourceName, documentId)}

                                    >
                                        <div className={styles.citationNumber}>{i + 1}</div>
                                        <p>{sourceName}</p>
                                        <p className={styles.sourceCollection}><ArchiveRegular /> {index}</p>
                                    </div>

                                );
                            })}
                        </Stack>
                    </Stack.Item>
                </div>
            )}
            {/* display sources if any */}
            {!!answer.data_points.length && (
                <>
                    <h3 className={styles.gizmoComponentSubheading}>All sources used</h3>
                    <p>Select a source to show more information</p>


                    <div className={styles.sources}>
                        <Stack.Item>
                            <Stack horizontal tokens={{ childrenGap: 5 }}>
                                {/* Map out the data points object */}
                                {answer.data_points.map((dp, index) => {
                                    const fileExtensionIndex = dp.sourceName.lastIndexOf('.');
                                    let sourceName = dp.sourceName;
                                    let fileExtension;
                                    if (fileExtensionIndex != -1) {
                                        sourceName = dp.sourceName.substring(0, fileExtensionIndex);
                                        fileExtension = dp.sourceName.split('.').pop();
                                    }

                                    // Search allCollectionNames for the matching dp.index and set that as the display name
                                    const collection = allCollectionNames.find(collection => collection.collection_name === dp.index);
                                    const displayName = collection ? collection.display_name : dp.index;


                                    return (
                                        <div key={index} className={styles.sourcesItem} title={`${dp.sourceName} - ${index}`}
                                            onClick={() => handleCitationClick(dp.index, dp.sourceName, dp.documentId)}>
                                            <div className={styles.sourcesIcon}>
                                                {fileExtension == "pdf" ? <DocumentPdfRegular style={{ color: "#FF0000" }} className={styles.icon} /> : fileExtension == "docx" || fileExtension == "doc" ? <DocumentTextRegular style={{ color: "#295ba9" }} className={styles.icon} /> : fileExtension == "url" ? <PreviewLinkRegular className={styles.icon} /> : <DocumentRegular className={styles.icon} />}
                                            </div>

                                            <p className={styles.sourceName}>{sourceName}</p>
                                            <p className={styles.sourceCollection}>                                            <ArchiveRegular style={{ display: "inline-block" }} /> {displayName}</p>

                                        </div>
                                    )

                                })}
                            </Stack>
                        </Stack.Item>
                    </div>

                </>
            )}
            {/* Follow up questions [[ NOW MOVED UP TO PARENT ]]*/}
            {/* {!!parsedAnswer.followupQuestions.length && showFollowupQuestions && onFollowupQuestionClicked && (
                <>
                    <h3 className={styles.gizmoComponentSubheading}>Suggested Follow-up questions</h3>
                    <Stack.Item>
                        <Stack horizontal wrap className={`${parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                            {parsedAnswer.followupQuestions.map((x, i) => {
                                return (
                                    <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                        {`${x}`}
                                    </a>
                                );
                            })}
                        </Stack>
                    </Stack.Item>
                </>
            )} */}

            <div className={styles.answerButtons}>
                {/* Buttons */}
                {/* Copy to clipboard */}
                <Button
                    appearance="secondary"
                    icon={<CopyRegular />}
                    style={{ color: "#00b0ba" }}
                    title="Copy Answer to Clipboard"
                    aria-label="Copy Answer to Clipboard"
                    onClick={() => copyToClipboard("TheAnswer")}
                    disabled={isCopied}
                >Copy Result to Clipboard</Button>
                {/* Download */}
                <Button
                    appearance="secondary"
                    icon={<ArrowCircleDownRegular />}
                    style={{ color: "#00b0ba" }}
                    title="Download as Word Document (Preview Feature)"
                    aria-label="Download as Word Document (Preview Feature)"
                    onClick={() => createWordDoc('BlueEdgeAI')}

                    disabled={isCopied}
                >Download as Word Document</Button>
                {/* disabled lightbulb */}
                {/*
                        <Button
                            appearance="transparent"
                            icon={<LightbulbRegular />}
                            style={{ color: "grey" }}
                            title="Reasoning Rivulet.  Click again to close."
                            aria-label="Show Reasoning Rivulet"
                            onClick={() => onThoughtProcessClicked()}
                            disabled={!answer.thoughts}
                        />
*/}

                {/* Supporting content  */}
                {/* <Button
                    appearance="transparent"
                    icon={<ReadingModeMobileRegular />}
                    style={{ color: "#00b0ba" }}
                    title="Show supporting content. Click again to close."
                    aria-label="Show supporting content"
                    onClick={() => onSupportingContentClicked()}
                // disabled={!answer.data_points.length}
                /> */}
            </div>


            {/* Feedback */}
            <Feedback
                run_id={answer.run_id}
            />


            {/* show amount of credits used */}

            {/* <Tooltip content={"Credits Used: " + tokensUsed} relationship="label">
                <Text className={styles.tokensUsed}><DataBarVerticalAscending16Filled /> {tokensUsed}</Text>
            </Tooltip> */}

        </Stack >
    );
};
