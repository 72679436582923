// This component is a popover to display the upload status - which is the details of when it was processed and if it succeeded or failed. It does require to pass in the index name and the document id.

import { useAuth0 } from '@auth0/auth0-react';
import { Button, Dialog, DialogTrigger, DialogSurface, DialogTitle, DialogContent, DialogActions, Spinner } from '@fluentui/react-components';
import { ArrowClockwiseDashesRegular, DocumentCheckmark20Regular, Info24Regular, SettingsCogMultiple20Filled, WarningFilled } from '@fluentui/react-icons';
import { useCallback, useEffect, useState } from 'react';
import { uploadStatusApi, UploadStatusApiResponse } from '../../api';
import styles from './UploadStatus.module.css';

type UploadStatusProps = {
    documentId: string;
    index: string;
    fileName: string;
    miniDisplay?: boolean;
};

const UploadStatus = ({
    documentId,
    index,
    fileName,
    miniDisplay,
}: UploadStatusProps) => {
    const { getAccessTokenSilently } = useAuth0();
    const [isOpen, setIsOpen] = useState(false);
    const [uploadStatus, setUploadStatus] = useState<UploadStatusApiResponse | null>(null);
    const [error, setError] = useState<string | null>(null); // If error when fetching file status
    const [warning, setWarning] = useState<boolean>(false);

    // Function  to fetch the upload status | Pass in documentId and index
    const fetchData = useCallback(async () => {
        try {
            const token = await getAccessTokenSilently();
            const data = await uploadStatusApi(index, documentId, token);
            setUploadStatus(data as UploadStatusApiResponse);
            if (miniDisplay && data) {
                const lastUpdate = new Date(data.last_update);
                const now = new Date();
                const thresholdMinutes = 5 * 60 * 1000; // 5 minutes ago
                if (now.getTime() - lastUpdate.getTime() > thresholdMinutes && data.completed == false) {
                    setWarning(true);
                }

            }

        } catch (error) {
            console.error("Failed to fetch upload status:", error);
            setError(`Failed to fetch upload status. Please try again. Document ID: ${documentId}, Index: ${index}, File Name: ${fileName}, Error: ${error}`);
        }
    }, [isOpen, documentId, index, getAccessTokenSilently]);




    return (
        <>
            {!miniDisplay ? (
                <Dialog open={isOpen} onOpenChange={(e, { open }) => setIsOpen(open)}>
                    <DialogTrigger>
                        <Button icon={<Info24Regular />} />
                    </DialogTrigger>
                    <DialogSurface>
                        <DialogTitle>Upload Status</DialogTitle>
                        <DialogContent>
                            {error ? (
                                // Error message
                                <div style={{ padding: '10px' }}>
                                    <strong style={{ color: 'red' }}>Error:</strong>
                                    <p>Failed to fetch upload status. Please try again.</p>
                                    <ul>
                                        <li>Document ID: {documentId}</li>
                                        <li>Index: {index}</li>
                                        <li>File Name: {fileName}</li>
                                    </ul>
                                </div>
                            ) : uploadStatus ? (
                                <div style={{ padding: '10px', color: uploadStatus.failed ? 'red' : 'black' }}>
                                    <p>{fileName}</p>
                                    <div style={{ color: uploadStatus.completed ? '#01a4ad' : 'red' }}>Completed: {uploadStatus.completed.toString()}</div>
                                    <div>Creation: {uploadStatus.creation}</div>
                                    <div>Last Update: {uploadStatus.last_update}</div>
                                    <div>Steps Completed: {uploadStatus.completed_steps.join(', ')}</div>
                                    <div>Document ID: {uploadStatus.document_id}</div>
                                    <div style={{ color: !uploadStatus.empty ? '#01a4ad' : 'red' }}>Empty: {uploadStatus.empty.toString()}</div>
                                    <div style={{ color: !uploadStatus.empty ? '#01a4ad' : 'red' }}>Failed: {uploadStatus.failed.toString()}</div>
                                    <div>Collection: {uploadStatus.index}</div>
                                    <div>Remaining Steps: {uploadStatus.remaining_steps.join(', ')}</div>
                                    <div>All Steps: {uploadStatus.steps.join(', ')}</div>
                                    <div>Tags:</div>
                                    <ul>
                                        {Object.entries(uploadStatus.tags).map(([key, value]) => (
                                            <li key={key}>{key}: {JSON.stringify(value)}</li>
                                        ))}
                                    </ul>
                                    <Button onClick={fetchData} title="Refresh">Refresh</Button> {/* Refresh button */}
                                </div>
                            ) : (
                                <div className={styles.loading}>Loading...</div>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setIsOpen(false)}>Close</Button>
                        </DialogActions>
                    </DialogSurface>
                </Dialog>
            ) : (
                <div className={styles.statusBtn}>
                    {uploadStatus ?
                        <>
                            {warning ? <Button appearance='subtle' title='Processing Failed - Contact Support' icon={<WarningFilled color='red' />} /> : !uploadStatus.completed ? <Button appearance='subtle' title='Processing...' icon={<SettingsCogMultiple20Filled color='orange' />} /> : <Button appearance='subtle' title='Ready to use' icon={<DocumentCheckmark20Regular color='green' />} />}

                        </> :
                        <Button onClick={fetchData} icon={<ArrowClockwiseDashesRegular />} appearance='subtle' />
                    }
                </div>
            )}
        </>
    );
};

export default UploadStatus;