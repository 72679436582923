// This component is used to rename a collection. It is a modal that pops up when the user clicks on the rename button in the collection view. It will require the selectedCollection to be passed in.


import { useAuth0 } from '@auth0/auth0-react';
import { TextField } from '@fluentui/react';
import { Button, Dropdown, Field, Option, Popover, PopoverSurface, PopoverTrigger, Spinner, Textarea, Tooltip } from '@fluentui/react-components';
import { CodeBlockRegular, Edit16Regular } from '@fluentui/react-icons';
import React, { useState } from 'react';
import { CollectionCategory, CollectionName, getAllCollectionCategoriesApi, renameCollectionApi, RenameCollectionResponse } from '../../api';
import styles from './RenameCollection.module.css';

type Props = {
    selectedCollection: CollectionName;
    setRefreshCollection: (refresh: boolean) => void;
}


const RenameCollection: React.FC<Props> = ({ selectedCollection, setRefreshCollection }) => {
    const { getAccessTokenSilently, user } = useAuth0();
    const [open, setOpen] = useState(false);
    const [newDisplayName, setNewDisplayName] = useState<string>(selectedCollection.display_name);
    const [indexDescription, setIndexDescription] = useState<string>(selectedCollection.description);
    const [isProcessing, setIsProcessing] = useState(false); // track if the operation is in progress
    const [categoryOptions, setCategoryOptions] = useState<CollectionCategory[]>([]); // all the options
    const [selectedCategoryName, setSelectedCategoryName] = useState<string>(); // id only

    // Fetch the category options
    const fetchCategoryOptions = async () => {
        // Fetch the categories from the backend
        const accessToken = await getAccessTokenSilently();
        const response = await getAllCollectionCategoriesApi(accessToken);
        setCategoryOptions(response);
    };

    // UseEffect to fetch the categories
    React.useEffect(() => {
        fetchCategoryOptions();
    }, []);

    // open the main modal
    const handleClickOpen = () => {
        setNewDisplayName(selectedCollection.display_name);
        setIndexDescription(selectedCollection.description);
        setRefreshCollection(false);
        setOpen(true);
    }

    // Close the modal
    const handleClose = () => {
        setOpen(false)
    };

    // handle rename collection
    const handleRenameCollection = async () => {
        setIsProcessing(true);
        try {
            const userTag = user?.name?.toString() || 'unknown'; // create a userTag with the user.name from auth0
            const token = await getAccessTokenSilently();

            // Create the form
            const formData = new FormData();
            formData.append('index', selectedCollection.collection_name);
            formData.append('displayName', newDisplayName);
            formData.append('description', indexDescription || selectedCollection.description);
            formData.append('user', userTag);
            formData.append('category', selectedCategoryName || selectedCollection.category);
            // call the renameCollectionApi function
            const response: RenameCollectionResponse = await renameCollectionApi(formData, token);
            // Check if the operation was successful by examining the 'success' property
            if (response.success) {
                setNewDisplayName('');
                setOpen(false);
                setRefreshCollection(true);
            } else {
                // If the operation was not successful, use the message from the response for the error
                throw new Error(`Failed to rename collection. Message: ${response.message}`);
            }
        } catch (error) {
            console.error("Error renaming collection:", error);
            window.alert(`Error renaming collection: ${error instanceof Error ? error.message : String(error)}`);
        } finally {
            setRefreshCollection(true);
            setIsProcessing(false);
        }
    };

    // handle category change
    const handleCategoryChange = (event: React.FormEvent<HTMLDivElement>, option?: CollectionCategory) => {
        if (option) {
            const selectedOption = categoryOptions.find(opt => opt.id === option.id);
            if (selectedOption) {
                setSelectedCategoryName(selectedOption.category_name);
            }
        }
    };



    // it is no more than 32 characters
    const isValidName = newDisplayName.length <= 32;





    return (
        <div className={styles.container}>
            <Popover open={open} onOpenChange={(e, data) => setOpen(data.open)}>
                <PopoverTrigger>
                    <div className={styles.triggerContainer}>
                        <Button appearance='subtle' icon={<Edit16Regular />} aria-label="Edit" onClick={handleClickOpen}>Edit Collection</Button>
                    </div>
                </PopoverTrigger>
                <PopoverSurface>
                    <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        <h3>Edit Collection Settings</h3>
                        <Field label="Collection Name" orientation='horizontal' validationState={isValidName ? "success" : "error"} validationMessage={isValidName ? "32 characters max" : "Required: 32 characters max"}
                        >
                            <TextField
                                value={newDisplayName}
                                onChange={(event, newValue?: string) => setNewDisplayName(newValue || '')}
                                onKeyDown={(e) => {
                                    // If the user presses Enter and the name is valid, create the index
                                    if (e.key === 'Enter' && isValidName) {
                                        handleRenameCollection();
                                    }
                                }}
                            />
                        </Field>
                        <br></br>
                        {/* Index description */}
                        <Field label="Collection Description" orientation='horizontal' hint="A short description to help users select relevant collections">
                            <Textarea
                                placeholder="Collection Description"
                                defaultValue={selectedCollection.description}
                                onChange={e => setIndexDescription(e.target.value)} />
                        </Field>
                        <br></br>
                        <Field label="Collection Category" orientation='horizontal'>
                            <Dropdown
                                placeholder='Select a category'
                                defaultValue={selectedCollection.category}
                            >
                                {categoryOptions.map((option) => (
                                    <Option key={option.id} onClick={(e) => handleCategoryChange(e, option)}>{option.category_name}</Option>
                                ))}
                            </Dropdown>
                        </Field>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                            <Button appearance="primary" onClick={handleRenameCollection} disabled={!isValidName || isProcessing}>
                                {isProcessing ? <Spinner size="small" /> : 'Confirm'}
                            </Button>
                            <Button appearance="outline" onClick={handleClose}>Cancel</Button>
                        </div>
                    </div>
                    <Tooltip content={"Backend Name:" + selectedCollection.collection_name + " | Current Display Name: " + selectedCollection.display_name + " | Current Category: " + selectedCollection.category} relationship='label'>
                        <CodeBlockRegular />
                    </Tooltip>
                </PopoverSurface>
            </Popover>
        </div>
    );
};

export default RenameCollection;