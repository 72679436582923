import { Image } from '@fluentui/react-components';
import styles from './PageFooter.module.css';
import UserProfile from '../UserProfile/UserProfile';
import footerLogo from "../../assets/footer_logo.png";
import { NavLink } from 'react-router-dom';
import { PersonaIcon } from '../PersonaIcon';



export const PageFooter = () => {

  const currentYear = new Date().getFullYear();

  return (

    <div className={styles.pageFooter}>
      <div className={styles.footerContainer}>
        <div className={styles.footerLayout}>
          <div className={styles.logoFooterSection}>
            <Image src={footerLogo} className={styles.footerLogo} />
          </div>
          <div className={styles.footerSection}>
            <p>Blue Edge specalise in creative bid solutions, offering strategic bid support and design to elevate your submission above the competition.</p>
            <p><a href='https://blue-edge.co.uk'>Click here to find out more</a></p>
          </div>
          <div className={styles.footerSection}>
            <p className={styles.footerHeader}>Blue Edge</p>
            <p>Freedom Works - Barts House, Black Lion Street, Brighton, BN1 1JE</p>
            <p>Copyright © {currentYear} Blue Edge</p>
          </div>
          <div className={styles.footerSection}>
            <p className={styles.footerHeader}>Support</p>
            <p><a href='mailto:support@blue-edge.ai'>support@blue-edge.ai</a></p>
          </div>
        </div>
      </div>
    </div >
  );
}