// this will be the manager for the collection categories. It will allow the user to add, edit, and delete categories.

// This will be self contained. It will fetch a list of categories which can be edited.

// Use dialog to display the form for adding/editing categories.
import { useAuth0 } from "@auth0/auth0-react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
    Input,
    Label,
    Link,
    Spinner,
    Text,
    Toast,
    ToastBody,
    ToastTitle,
    Toaster,
    useId,
    useToastController
} from "@fluentui/react-components";
import { useEffect, useState } from "react";
import { CollectionCategory, createCollectionCategoryApi, deleteCollectionCategoryApi, getAllCollectionCategoriesApi } from "../../api";

import { ArrowCounterclockwiseRegular } from "@fluentui/react-icons";
import styles from './CollectionCategoryManager.module.css';

// Define a type alias for the allowed values
type MessageIntent = "success" | "error" | "warning" | "info";


const CollectionCategoryManager: React.FC = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [isOpen, setIsOpen] = useState<boolean>(false); // this dialog
    const [categories, setCategories] = useState<CollectionCategory[]>([]); // list of all
    const [selectedCategoryId, setSelectedCategoryId] = useState<string>(""); // only id
    const [errors, setErrors] = useState({ name: '', description: '' });
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isDeleteConfirmationPopoverOpen, setIsDeleteConfirmationPopoverOpen] = useState(false);

    const [newCategoryName, setNewCategoryName] = useState<string>(""); // hold new category name
    const [newCategoryDesc, setNewCategoryDesc] = useState<string>(""); // hold new category description
    const [newCategoryColor, setNewCategoryColor] = useState<string>(""); // hold new category color

    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);

    // Define a notify to display the Toast messages
    const notify = (
        title: string = 'Success!', // default
        body: string = 'The operation was successful.',
        message_intent: MessageIntent = "success"
    ) =>
        dispatchToast(
            // Use the Toast component to display a message
            <Toast
                as='div'
                appearance='inverted' // dark mode
                key={undefined}
            >
                <ToastTitle action={<Link>Undo</Link>}>{title}</ToastTitle>
                <ToastBody><Text>{body}</Text></ToastBody>
            </Toast>,
            { intent: message_intent }
        );




    // 1. First define the function to get list of categories
    const fetchCategories = async () => {
        try {
            const accessToken = await getAccessTokenSilently();
            const response = await getAllCollectionCategoriesApi(accessToken);
            console.log('fetchCategories response:', response);
            setCategories(response);
            const length = response.length;

            // Display success toast
            notify(
                `${length} Categories Fetched`, // Title
                'Categories fetched successfully.', // Body
                'success' // Message intent
            );
        } catch (error) {
            console.error('Error fetching categories:', error);

            // Display error toast
            notify(
                'Fetch Error', // Title
                'There was an error fetching the categories.', // Body
                'error' // Message intent
            );
        }
    };

    // 1a. UseEffect to trigger that function on component load
    useEffect(() => {
        fetchCategories();
    }, []); // <- Note the empty dependency array


    // handle open dialog
    const handleOpen = () => {
        setIsOpen(true);
    }

    // handle close dialog - clear the form
    const handleClose = () => {
        setNewCategoryName("");
        setNewCategoryDesc("");
        setNewCategoryColor("");
        setIsOpen(false);
    }

    // Add category
    const handleAddCategory = async () => {
        setIsSaving(true);
        try {
            if (validateForm()) {
                const token = await getAccessTokenSilently();
                await createCollectionCategoryApi(newCategoryName, newCategoryDesc, newCategoryColor, token);

                setNewCategoryName("");
                setNewCategoryDesc("");
                setNewCategoryColor("");
                fetchCategories();

                // Display success toast
                notify(
                    'Category Added', // Title
                    'The new category was added successfully.', // Body
                    'success' // Message intent
                );
            }
        } catch (error) {
            console.error('Error adding category:', error);

            // Display error toast
            notify(
                'Add Error', // Title
                'There was an error adding the new category.', // Body
                'error' // Message intent
            );
        } finally {
            setIsSaving(false);
        }
    };

    // Edit category
    // const handleEditCategory = (updatedCategory: string) => {
    //     console.log('Edit category', updatedCategory);
    // };

    // Delete category
    const handleDeleteCategory = async (categoryId: string) => {
        console.log('Delete category', categoryId);
        try {
            const accessToken = await getAccessTokenSilently();
            const response = await deleteCollectionCategoryApi(categoryId, accessToken);
            console.log('deleteCollectionCategoryApi response:', response);
            notify('Success!', 'The category was successfully deleted.', 'success');
        } catch (error) {
            console.error('Failed to delete category:', error);
            notify('Error!', 'Failed to delete the category.', 'error');
        } finally {
            fetchCategories();
        }
    };

    // Select category
    const handleSelectItem = (category: string) => {
        setSelectedCategoryId(category);
    };

    // Validate inputs in form
    const validateForm = () => {
        let valid = true;
        const errors = { name: '', description: '' };

        if (newCategoryName.trim() === '') {
            errors.name = 'Name is required';
            notify('Validation Error', 'Name is required', 'error');
            valid = false;
        }

        if (newCategoryDesc.trim() === '') {
            errors.description = 'Description is required';
            notify('Validation Error', 'Description is required', 'error');
            valid = false;
        }

        setErrors(errors);
        return valid;
    };

    // Confirm Delete
    const confirmDelete = (categoryId: string) => {
        setSelectedCategoryId(categoryId); // add the id to state
        setIsDeleteConfirmationPopoverOpen(true);
    };

    // on confirm the delete
    const onConfirmDelete = () => {
        if (selectedCategoryId) {
            handleDeleteCategory(selectedCategoryId);
        }
        setIsDeleteConfirmationPopoverOpen(false);
    };

    // on cancel the delete
    const onCancelDelete = () => {
        setIsDeleteConfirmationPopoverOpen(false);
    }

    return (
        <div>
            {/* Toaster */}
            <Toaster
                toasterId={toasterId} // toasterId allows multiple to be sent
                position='bottom-end' // position of the toaster on the screen
            />

            {/* Delete confirmation Dialog */}
            <Dialog
                open={isDeleteConfirmationPopoverOpen}
                modalType="alert"
            >
                <DialogSurface tabIndex={-1} >
                    <p>Confirmation</p>
                    <p>Are you sure you want to delete this category?</p>

                    {/* Yes */}
                    <Button
                        appearance="primary"
                        onClick={onConfirmDelete}
                    >
                        Yes
                    </Button>
                    {/* Cancel */}
                    <Button
                        onClick={onCancelDelete}
                    >
                        Cancel
                    </Button>
                </DialogSurface>
            </Dialog>

            {/* Main Collection Category Manager Dialog */}
            <Dialog
                open={isOpen}
                modalType="modal"
            >
                <DialogTrigger disableButtonEnhancement>
                    <Button
                        appearance="secondary"
                        onClick={handleOpen}
                    >
                        Category Manager
                    </Button>
                </DialogTrigger>

                <DialogSurface>
                    <DialogTitle>Collection Category Manager</DialogTitle>
                    <DialogBody>
                        <DialogContent>
                            <Text
                                className={styles.description}
                                size={500}
                                align="center"
                            >Here we can Add new, Edit and Delete categories.</Text>

                            <div>
                                <div className={styles.form}>
                                    {/* Name */}
                                    <Label className={styles.label}>
                                        <span>New Category Name: <span className={styles.required}>Required</span></span>
                                        <div>
                                            <Input
                                                placeholder="Name"
                                                value={newCategoryName}
                                                onChange={(e) => setNewCategoryName(e.target.value)}
                                                type="text"
                                                className={errors.name ? styles.inputError : ''}
                                            />
                                            {errors.name && <span className={styles.error}>Name is required</span>}
                                        </div>
                                    </Label>

                                    {/* Description */}
                                    <Label className={styles.label}>
                                        <span>New Category Description: <span className={styles.required}>Required</span></span>
                                        <div>
                                            <Input
                                                placeholder="Description"
                                                value={newCategoryDesc}
                                                onChange={(e) => setNewCategoryDesc(e.target.value)}
                                                type="text"
                                                className={errors.description ? styles.inputError : ''}
                                            />
                                            {errors.description && <span className={styles.error}>Description is required</span>}
                                        </div>
                                    </Label>

                                    {/* Color */}
                                    <Label className={styles.label}>
                                        <span>New Category Color: <span className={styles.optional}>Optional</span></span>
                                        <div>
                                            <Input
                                                placeholder="ex. blue"
                                                value={newCategoryColor}
                                                onChange={(e) => setNewCategoryColor(e.target.value)}
                                                type="text"
                                            />
                                        </div>
                                    </Label>
                                </div>

                                {/* Save */}
                                <Button
                                    appearance="primary"
                                    onClick={handleAddCategory}
                                    disabled={isSaving}
                                >
                                    {isSaving ? <Spinner size="small" /> : 'Add Category'}
                                </Button>


                                <hr />
                                <div>
                                    {/* Refresh button */}
                                    <Label>
                                        <Button
                                            appearance="transparent"
                                            onClick={fetchCategories}
                                        >
                                            <ArrowCounterclockwiseRegular />
                                        </Button></Label>
                                    <Text size={400} align="center">Current Categories</Text>

                                    {/* List out the current categories */}
                                    <div className={styles.listContainer}>
                                        {categories.map(category => (
                                            <div
                                                key={category.id}
                                                className={`${styles.listItem} ${selectedCategoryId === category.id ? styles.selectedItem : ''}`}
                                                onClick={() => handleSelectItem(category.id)}
                                            >
                                                <span className={styles.listName}>
                                                    {category.category_name}
                                                </span>
                                                <div className={styles.categoryActions}>
                                                    <Button onClick={(e) => {
                                                        e.stopPropagation(); // Prevent the div's onClick from firing
                                                        confirmDelete(category.id);
                                                    }}>Delete</Button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </DialogContent>
                    </DialogBody>


                    <DialogActions>
                        <Button
                            onClick={handleClose}
                            appearance="secondary"
                        >
                            Close
                        </Button>
                    </DialogActions>
                </DialogSurface>
            </Dialog>
        </div>
    )
}

export default CollectionCategoryManager