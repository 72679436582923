// This will hold all the action buttons on the Indexer page

import { useAuth0 } from '@auth0/auth0-react';
import { Button, makeStyles, shorthands, Text, tokens } from '@fluentui/react-components';
import React from 'react';
import { applyIndexerChangesApi, checkForChangesApi, deleteAllIndexerFileStatusApi, deleteCollectionApi, ExternalDataCollection, seedCollectionApi } from '../../api';

import {
    useId,
    Link,
    Toaster,
    useToastController,
    Toast,
    ToastTitle,
    ToastBody,
} from "@fluentui/react-components";

// Define a type alias for the allowed values
type MessageIntent = "success" | "error" | "warning" | "info";

type IndexerActionProps = {
    selectedCollection: ExternalDataCollection;
    fetchFiles: (collectionId: string) => void;
    get_external_collections: () => void;
    setSelectedCollection: (collection: ExternalDataCollection | null) => void;
}

const useStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        ...shorthands.padding(tokens.spacingHorizontalM),
    },
    button: {
        ...shorthands.margin(tokens.spacingHorizontalM),
    },
    deleteBtn: {
        ...shorthands.margin(tokens.spacingHorizontalS),
        backgroundColor: 'white', // background color
        color: 'red', // text color
        ...shorthands.border('1px', 'solid', 'red'),
    },
});

const IndexerActions = ({
    selectedCollection,
    fetchFiles,
    get_external_collections,
    setSelectedCollection
}: IndexerActionProps) => {
    const styles = useStyles();
    const { getAccessTokenSilently } = useAuth0();
    const [ isDeletingAllIndexItems, setIsDeletingAllIndexItems ] = React.useState(false);
    const [ isSeeding, setIsSeeding ] = React.useState(false);
    const [ isDeletingIndexer, setIsDeletingIndexer ] = React.useState(false);
    const [ isCheckingForChanges, setIsCheckingForChanges ] = React.useState(false);
    const [ isApplyingChanges, setIsApplyingChanges ] = React.useState(false);

    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);

    // Function to display the Toast
    const notify = (
        title: string = 'Success!', // default
        body: string = 'The operation was successful.',
        message_intent: MessageIntent = "success"
    ) =>
        dispatchToast(
            // Use the Toast component to display a message
            <Toast
                as='div'
                appearance='inverted'
            >
                <ToastTitle action={<Link>Undo</Link>}>{title}</ToastTitle>
                <ToastBody><Text>{body}</Text></ToastBody>
                {/* <ToastFooter>
                    <Link>OK</Link>
                    <Link>Cancel</Link>
                </ToastFooter> */}
            </Toast>,
            { intent: message_intent }
        );

    // Delete all items
    const handleDeleteAllIndexItems = async () => {
        console.log("Deleting all items for collection:", selectedCollection.id);
        setIsDeletingAllIndexItems(true);
        try {
            const accessToken = await getAccessTokenSilently();
            const response = await deleteAllIndexerFileStatusApi(selectedCollection.id, accessToken);
            if (response.ok) {
                notify(
                    'Items Deleted', // Title
                    'All items deleted successfully.', // Body
                    'success' // Message intent
                );
            } else {
                throw new Error("Failed to delete items."); // will be caught below
            }
        } catch (error) {
            // Check if the error is an instance of Error
            if (error instanceof Error) {
                console.error("Error deleting items:", error);
                notify(
                    'Error Deleting Items', // Title
                    error.message, // Body
                    'error' // Message intent
                );
            } else {
                console.error("Unknown error:", error);
                notify(
                    'Unknown Error', // Title
                    'An unknown error occurred.', // Body
                    'error' // Message intent
                );
            }
        } finally {
            setIsDeletingAllIndexItems(false);
            fetchFiles(selectedCollection.id); // refresh the files list
        }
    };


    // Delete the Indexer
    const handleDeleteIndexer = async () => {
        console.log("Deleting indexer for collection:", selectedCollection.id);
        setIsDeletingIndexer(true);
        try {
            const accessToken = await getAccessTokenSilently();
            const db_response = await deleteCollectionApi(selectedCollection.id, accessToken);
            await deleteCollectionApi(selectedCollection.id, accessToken);
            get_external_collections();
            if (db_response.ok) {
                // alert("Collection with Indexer attached deleted successfully.");
                notify(
                    'Indexer Deleted', // Title
                    'Indexer deleted successfully.', // Body
                    'success' // Message intent
                )
            } else {
                alert("Failed to delete indexer.");
            }
        } catch (error) {
            console.error("Error deleting indexer:", error);
            notify(
                'Error Deleting Indexer', // Title
                'An error occurred while deleting indexer.', // Body
                'error' // Message intent
            )
        } finally {
            setIsDeletingIndexer(false);
            setSelectedCollection(null); // clear the selected collection
        }
    }

    // Handle seed
    const handleSeed = async () => {
        console.log("Start Seeding collection:", selectedCollection.id);
        setIsSeeding(true);
        try {
            // Create a FormData object and add the site_id, collection_id, and folder_id from the selected collection
            const site_info = new FormData();
            site_info.append('site_id', selectedCollection.site_id);
            site_info.append('folder_id', selectedCollection.folder_id);
            site_info.append('drive_id', selectedCollection.drive_id);
            site_info.append('collection_name', selectedCollection.collection_name);

            // Call the API function to send the FormData
            const accessToken = await getAccessTokenSilently();
            const response = await seedCollectionApi(site_info, accessToken);
            console.log("Response from handleSeed", response);

        } catch (error) {
            console.error("Error seeding collection:", error);
        } finally {
            setIsSeeding(false);
            fetchFiles(selectedCollection.id); // refresh the files list
        }
    };

    // Handle Check for Changes
    const handleCheckForChanges = async () => {
        console.log("Checking for changes in collection:", selectedCollection.id);
        setIsCheckingForChanges(true);
        try {
            const folder_id = selectedCollection.folder_id;
            const drive_id = selectedCollection.drive_id;
            const accessToken = await getAccessTokenSilently();

            // Call the API function to check for changes
            const response = await checkForChangesApi(folder_id, drive_id, accessToken);

            // Format the response into a readable string
            const formattedResponse = Object.entries(response)
                .map(([ key, value ]) => `${key}: ${value}`)
                .join('\n');

            notify(
                'Changes Checked', // Title
                formattedResponse, // Body
                'success' // Message intent
            );
            // Testing
            console.log("Response from handleCheckForChanges", response);
        } catch (error) {
            console.error("Error checking for changes:", error);
            notify(
                'Error Checking for Changes', // Title
                'An error occurred while checking for changes.', // Body
                'error' // Message intent
            )
        } finally {
            setIsCheckingForChanges(false);
            fetchFiles(selectedCollection.id); // refresh the files list
        }
    };

    // Handle Apply Changes
    const handleApplyChanges = async () => {
        setIsApplyingChanges(true);
        console.log("Applying changes for collection:", selectedCollection.id);
        notify(
            'Starting to apply changes', // Title
            'Starting processing of changes...', // Body
            'info' // Message intent
        )

        try {
            // Call the API function to apply changes
            const token = await getAccessTokenSilently();
            const response = await applyIndexerChangesApi(selectedCollection.id, token);
            
            // Check if the response status is 'success'
            if (response.status === 'success') {
                // Show a success notification with the number of updated and deleted items
                notify(
                    'Changes Applied', // Title
                    `Updated: ${response.updated}, Deleted: ${response.deleted}`, // Body
                    'success' // Message intent
                )
            }
            console.log("Response from handleApplyChanges", response);
        } catch (error) {
            console.error("Error applying changes:", error);
            notify(
                'Error Applying Changes', // Title
                'An error occurred while applying changes.', // Body
                'error' // Message intent
            )
        } finally {
            setIsApplyingChanges(false);
            fetchFiles(selectedCollection.id); // refresh the files list
        }
    }

    return (
        <div className={styles.container}>
            <Toaster
                toasterId={toasterId}
                position='bottom-end'
            />


            {/* Clear index / start over */}
            <div>
                <Button
                    className={styles.button}
                    title='Delete everything and start over.'
                    onClick={handleDeleteAllIndexItems}
                >
                    {isDeletingAllIndexItems ? 'Deleting...' : 'Clear Index'}
                </Button>
            </div>

            {/* Seed */}
            <div>
                <Button
                    className={styles.button}
                    title='Find all files'
                    onClick={handleSeed}
                >
                    {isSeeding ? 'Seeding...' : 'Seed'}
                </Button>
            </div>

            {/* Check for changes */}
            <div>
                <Button
                    className={styles.button}
                    title='Check for updates/changes'
                    onClick={handleCheckForChanges}
                >
                    {isCheckingForChanges ? 'Checking...' : 'Check for Changes'}
                </Button>
            </div>

            {/* Apply Changes */}
            <div>
                <Button
                    className={styles.button}
                    title='Apply the pending changes'
                    onClick={handleApplyChanges}
                >
                    {isApplyingChanges ? 'Applying... ' :'Apply Changes'}
                </Button>
            </div>

            {/* Delete the indexer */}
            <div>
                <Button
                    className={styles.deleteBtn}
                    title='Delete the indexer'
                    appearance='outline'
                    onClick={handleDeleteIndexer}
                >
                    {isDeletingIndexer ? 'Deleting...' : 'Delete Collection / Indexer'}
                </Button>
            </div>

        </div>
    )
}

export default IndexerActions