import { useAuth0 } from "@auth0/auth0-react";
import {
    Text
} from "@fluentui/react-components";
import { jwtDecode } from 'jwt-decode';
import * as React from "react";
import { useEffect, useState } from "react";
import { CustomJwtPayload, getMaintenanceModeLogsApi } from "../../api";
import BEAILogo from "../../assets/BEAILogoFinal.png";
import styles from "./MaintenanceMode.module.css";

export const MaintenanceMode = () => {

    const { logout, getAccessTokenSilently } = useAuth0();
    const [isAuthorizedMaintenanceMode, setIsAuthorizedMaintenanceMode] = useState(false)
    const [isMaintenanceModeActive, setIsMaintenanceModeActive] = React.useState<boolean>(false); // support access status [true/false]
    const [timeLeft, setTimeLeft] = useState<number>(10);

    // Function to Fetch the maintenance mode status from the server
    const fetchMaintenanceMode = async () => {
        const token = await getAccessTokenSilently();
        const maintenanceModeArray = await getMaintenanceModeLogsApi(token); // fetch the data
        if (maintenanceModeArray && maintenanceModeArray.length > 0) {
            const record = maintenanceModeArray[0]; // get just the first record if multiple exist
            setIsMaintenanceModeActive(record.action === true); // any value other than 'true' from the db is considered false
        }

    };

    // Get permissions
    useEffect(() => {
        const checkRole = async () => {
            try {
                const token = await getAccessTokenSilently();
                const decodedToken = jwtDecode<CustomJwtPayload>(token);
                const permissions = decodedToken.permissions;

                // see maintenance mode bypass
                if (permissions.includes('view:CompanyProfile')) {
                    setIsAuthorizedMaintenanceMode(true);
                };
            } catch (e) {
                // Handle errors - e.g. token is invalid, expired, etc.
                console.error(e);
                alert("There was an issue verifying your credentials. Please log in again.")
            }
        }
        checkRole();
        fetchMaintenanceMode();

    }, [])


    useEffect(() => {
        if (isMaintenanceModeActive && !isAuthorizedMaintenanceMode) {
            const intervalId = setInterval(() => {
                setTimeLeft(prevCount => {
                    if (prevCount > 0) {
                        return prevCount - 1;
                    } else {
                        console.log("LOGOUT");
                        logout({ logoutParams: { returnTo: window.location.origin } });
                        clearInterval(intervalId); // Ensure to clear the interval once timeLeft reaches 0
                        return 0;
                    }
                });
            }, 1000);

            return () => clearInterval(intervalId); // Cleanup interval on component unmount
        };
    }, [isMaintenanceModeActive, isAuthorizedMaintenanceMode]);



    return (
        <>
            {isMaintenanceModeActive ?
                <>
                    {isAuthorizedMaintenanceMode ?
                        (
                            <div className={styles.MaintenanceModeBar}>
                                <Text>MAINTENANCE MODE ACTIVE</Text>
                            </div>
                        )
                        :
                        (
                            <div className={styles.MaintenanceModeContainer}>
                                <img src={BEAILogo} />
                                <h1>Under Maintenance</h1>
                                <h2>Sorry! Blue Edge AI is currently offline for maintenance</h2>
                                <p>Please check back again soon</p>
                                <p><a href="mailto:support@blue-edge.ai">support@blue-edge.ai</a></p>

                                <p>You will be logged out in <strong>{timeLeft} seconds</strong></p>
                            </div>
                        )
                    }
                </>
                : ''}
        </>
    );
};

export default MaintenanceMode;

