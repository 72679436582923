import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { getGoogleAnalyticsApi } from "../../api";


export const Analytics = () => {
    const [googleAnalyticsID, setGoogleAnalyticsID] = useState('G-V3ZG119S6N');
    const googleTagManagerURL = "https://www.googletagmanager.com/gtag/js?id=" + googleAnalyticsID
    const {  getAccessTokenSilently } = useAuth0();

    // Function to Fetch the google analytics settings from the server
    const fetchGoogleAnalyticsSettings = async () => {
        const token = await getAccessTokenSilently();
        const google_analytics_id = await getGoogleAnalyticsApi(token); // fetch the data
        if (google_analytics_id && google_analytics_id.length > 0) {
            const record = google_analytics_id[0]; // get just the last record if multiple exist
            setGoogleAnalyticsID(record.googleAnalyticsID); // any value other than 'true' from the db is considered false
        }
    };

    useEffect(() => {
        fetchGoogleAnalyticsSettings();
    }, []);

    return (
        <HelmetProvider>
            <Helmet>
                <script async src={googleTagManagerURL}></script>
                <script>{"window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '" + googleAnalyticsID + "');"}</script>
            </Helmet>
        </HelmetProvider>
    );
};
