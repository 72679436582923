// This component will be used to import a .be file and upload it to cosmos db.
// This component will be used to import a .be file and upload it to cosmos db.

import { useAuth0 } from '@auth0/auth0-react';
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Spinner, Text } from '@fluentui/react-components';
import React, { useRef, useState } from 'react';
import { importTemplateApi } from '../../api';

type ImportTemplateProps = {
    listTemplates: () => void;  // triggers the refresh in parent component
};

const ImportTemplate:React.FC<ImportTemplateProps> = ({listTemplates}) => {
    const { getAccessTokenSilently } = useAuth0(); // get the Auth0 hook
    const [ isImportingFile, setIsImportingFile ] = useState(false); // track if a file is being imported
    const [ isDialogOpen, setIsDialogOpen ] = useState(false); // track if the dialog is open
    const [ selectedFiles, setSelectedFiles ] = useState<FileList | null>(null); // track selected files
    const fileInputRef = useRef(null);

    // Handle a template being selected to import
    const handleFileImport = async () => {
        if (selectedFiles && selectedFiles.length > 0) {
            setIsImportingFile(true);
            const templates = [];
            try {
                for (let i = 0; i < selectedFiles.length; i++) {
                    const file = selectedFiles[i];
                    const content = await file.text();
                    const template = JSON.parse(content);
                    templates.push(template);
                }
                const token = await getAccessTokenSilently();
                const response = await importTemplateApi(templates, token);
                const result = await response.json();

                // Handle the response
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                result.forEach((res: any) => {
                    if (res.status === "created") {
                        console.log(`Template ${res.item.id} created successfully.`);
                    } else if (res.status === "updated") {
                        console.log(`Template ${res.item.id} updated successfully.`);
                    } else {
                        console.error(`Failed to process template: ${res.error}`);
                    }
                });
    
                // show a success message to the user
                alert("Templates processed successfully.");
                setSelectedFiles(null);
            } catch (err) {
                console.error('Error while importing templates:', err);
                // Error message to the user
                if (err instanceof Error) {
                    alert(`Error while importing templates: ${err.message}`);
                } else {
                    alert('An unknown error occurred while importing templates.');
                }
            } finally {
                setIsImportingFile(false);
                setIsDialogOpen(false); // Close the dialog after import
                listTemplates(); // triggers the refresh in parent component
            }
        }
    };

    // Handle file selection
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedFiles(event.target.files);
    };

    // Handle close - clear input
    const handleClose = () => {
        setIsDialogOpen(false);
        setSelectedFiles(null);
        listTemplates(); // triggers the refresh in parent component
    }

    return (
        <div>
            <Dialog
                modalType='modal'
                open={isDialogOpen}
            >
                {/* Trigger */}
                <DialogTrigger disableButtonEnhancement>
                    <Button onClick={() => setIsDialogOpen(true)} appearance="primary">
                        <Text as='span' size={300} weight='semibold'>Import Template from file</Text>
                    </Button>
                </DialogTrigger>

                <DialogSurface>
                    <DialogBody>
                        {/* Title */}
                        <DialogTitle>Upload template from file</DialogTitle>
                        <DialogContent>
                            {/* Content */}
                            <Text>
                                Please Select a Blue Edge Template file (.bet) file to upload. Note: The ID must be unique and if a record with the same ID exists already it will error.
                            </Text>
                            {/* File input */}
                            <input
                                type="file"
                                accept='.bet'
                                ref={fileInputRef}
                                style={{ display: 'block' }}
                                onChange={handleFileChange}
                                multiple={true}
                            />

                            <div style={{ maxHeight: '200px', overflowY: 'auto', marginTop: '10px' }}>
                                {selectedFiles && selectedFiles.length > 0 && (
                                    <ul>
                                        {Array.from(selectedFiles).map((file, index) => (
                                            <li key={index}>{file.name}</li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </DialogContent>

                        {/* Action Buttons */}
                        <DialogActions>
                            {isImportingFile ? (
                                <Spinner />
                            ) : (
                                // Upload button
                                <Button onClick={handleFileImport} appearance="primary">
                                    <Text as='span' size={300} weight='semibold'>Upload</Text>
                                </Button>
                            )}
                            {/* Cancel button */}
                            <Button onClick={handleClose} appearance="secondary">
                                <Text as='span' size={300} weight='semibold'>Cancel</Text>
                            </Button>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </div>
    );
};

export default ImportTemplate;