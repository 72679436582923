/* eslint-disable @typescript-eslint/no-unused-vars */
import { useAuth0 } from "@auth0/auth0-react";
import { Checkbox, DefaultButton, Dropdown, IDropdownOption, Panel, SpinButton } from "@fluentui/react";
import type { TooltipProps } from "@fluentui/react-components";
import {
    Button,
    Dialog, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Divider, Image, Link,
    Spinner,
    Text, Tooltip
} from '@fluentui/react-components';
import { Archive20Regular, ArrowEnterRegular, BookQuestionMark24Filled, CheckboxCheckedRegular, CheckboxUncheckedRegular, CloudArchive20Regular, Dismiss24Regular, Edit24Regular, ErrorCircle24Regular, Fire24Filled, Person20Filled, TriangleDown16Filled, EditRegular, ChevronRightRegular, ChevronLeftRegular, SaveRegular, FormNewRegular, AddCircleFilled, AddCircle24Filled, AddCircle20Filled, ArrowReplyRegular } from "@fluentui/react-icons";
import { jwtDecode } from 'jwt-decode';
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid'; // to generate a new thread_id for each chat session
import { Approaches, AskResponse, chatApi, ChatRequest, ChatTurn, CollectionName, CustomJwtPayload, Endpoints, getCollectionsApi, getPromptTemplatesApi, getTokenLimitApi, getTokensSinceResetApi, GptVersion, PersonaTemplate, RetrievalMode, Project, getProjectRecordsApi, getTemplateByIdApi } from "../../api";
import BELogo from "../../assets/BE_Logo.png";
import BEAILogo from "../../assets/BEAILogoFinal.png";
import ClientLogoTransparent from "../../assets/LogoTransparent.png";
import { AnalysisPanel, AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { ClearChatButton } from "../../components/ClearChatButton";
import CollectionSelector from "../../components/CollectionSelector/CollectionSelector";
import { MaintenanceMode } from "../../components/MaintenanceMode";
import { ManagementToolbar } from "../../components/ManagmentToolbar";
import { PersonaIcon } from "../../components/PersonaIcon";
import { PersonaSelector } from "../../components/PersonaSelector/PersonaSelector";
import { QuestionImprover } from "../../components/QuestionImprover/QuestionImprover";
import { QuestionInput } from "../../components/QuestionInput";
import { SettingsButton } from "../../components/SettingsButton";
import { UserChatMessage } from "../../components/UserChatMessage";
import UserProfile from "../../components/UserProfile/UserProfile";
import styles from "./Ai.module.css";
// tutorial system
import "@sjmc11/tourguidejs/src/scss/tour.scss";
import { TourGuideClient } from "@sjmc11/tourguidejs/src/Tour";
import { CollectionManagerPopup } from "../../components/CollectionManagerPopup/CollectionManagerPopup";
import SearchOptions from '../../components/SearchOptions/SearchOptions';
import { ReportIssueButton } from "../../components/ReportIssueButton";
import { Analytics } from "../../components/Analytics";
import GizmoComponentBlank from "../../components/GizmoComponentBlank/GizmoComponentBlank"
import GizmoDocumentCollection from "../../components/GizmoDocumentCollection/GizmoDocumentCollection";
import GizmoComponentPrompt from "../../components/GizmoComponentPrompt/GizmoComponentPrompt"
import GizmoQuick from "../../components/GizmoQuick/GizmoQuick";
import MainTool from "../../components/MainTool/MainTool";
import { PageHeader } from "../../components/PageHeader/PageHeader";



const Ai = (props: Partial<TooltipProps>) => {
    const { isAuthenticated, user, isLoading, getAccessTokenSilently } = useAuth0();
    const [isAuthorizedToViewQuickSettingsMenu, setIsAuthorizedToViewQuickSettingsMenu] = useState(false);
    const [isAuthorizedToViewDocumentCollectionsManager, setIsAuthorizedToViewDocumentCollectionsManager] = useState<boolean>(false);
    const navigate = useNavigate();
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [promptTemplate, setPromptTemplate] = useState<string>("");
    const [retrieveCount, setRetrieveCount] = useState<number>(10); // number of results to return from document collections
    const [retrieveWebCount, setRetrieveWebCount] = useState<number>(3); // number of web results to return
    const [retrievalMode, setRetrievalMode] = useState<RetrievalMode>(RetrievalMode.Hybrid);
    const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(true);
    const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState<boolean>(true);
    const [temperature, setTemperature] = useState<number>(0.6);
    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const [isChatLoading, setIsChatLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();
    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);
    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<[user: string, response: AskResponse, web: boolean, run_id: string][]>([]);
    const [inputLength, setInputLength] = useState<number>(0); // question input character limit
    const [personasList, setPersonasList] = useState<PersonaTemplate[]>([])
    const [selectedPersona, setSelectedPersona] = useState<PersonaTemplate>(personasList[0]);
    const [settingsButtonVisible, setSettingsButtonVisible] = useState<boolean>(false);
    const [NoRoleError, setNoRoleError] = useState<boolean>(false);
    const [tokensUsed, setTokensUsed] = useState<number | null>(null); // track tokens used
    const [tokenLimitReached, setTokenLimitReached] = useState<boolean>(false); // track token limit
    const [open, setOpen] = React.useState(false); // open the persona menu
    const [includeWebResults, setIncludeWebResults] = useState<boolean>(false); // set to false by default
    const [threadId, setThreadId] = useState(uuidv4());; // track the uuid thread_id for each chat session
    const [includeCompanyMemory, setIncludeCompanyMemory] = useState<boolean>(false); // track company memory
    const [includeUserMemory, setIncludeUserMemory] = useState<boolean>(false); // track user memory
    const [images, setImages] = useState<string[]>([]); // state for array of images up to 5
    const [followUpQuestionPrompt, setFollowUpQuestionPrompt] = useState<string>(""); // hold follow up question prompt

    // states for search type
    const [vectorSearch, setVectorSearch] = useState(false);
    const [semanticSearch, setSemanticSearch] = useState(false);
    const [hybridSearch, setHybridSearch] = useState(true); // default to hybrid

    // State for selected collections
    const [selectedCollections, setSelectedCollections] = useState<CollectionName[]>([]);
    // state for Search only mode
    const [searchOnlyMode, setSearchOnlyMode] = useState(false);
    const [hideImageUploader, setHideImageUploader] = useState(true); // hide image uploader by default
    const [disableWeb, setDisableWeb] = useState(false); // disable web results
    const [disableDocumentCollections, setDisableDocumentCollections] = useState(false); // disable document collections
    const [showCitationPanel, setShowCitationPanel] = useState(true); //citation panel show/hide

    // State to trigger a refresh in the collection selector - true=refresh ex. setRefreshCollection(true)
    const [refreshCollection, setRefreshCollection] = useState<boolean>(false);

    //Project variables
    const [quickGizmo, setQuickGizmo] = useState('');
    const [gizmoComponents, setGizmoComponents] = useState<ReactElement[]>([]);
    const [projectSetup, setProjectSetup] = useState(false);
    const [projectName, setProjectName] = useState('') // name of the project
    const [projectDescription, setProjectDescription] = useState('') // project description
    const [projectGizmo, setProjectGizmo] = useState<PersonaTemplate>() // selected gizmo for project
    const [projects, setProjects] = useState<Project[]>([]);
    const [loadingProjects, setLoadingProjects] = useState<boolean>(true);
    const [selectedProject, setSelectedProject] = useState<Project>();
    const [mainTool, setMainTool] = useState<PersonaTemplate>(); // hold the tool object

    //focus change close all components
    const [focusedComponent, setFocusedComponent] = useState<number | null>(0);
    const [gizmoComponentIndex, setGizmoComponentIndex] = useState(0);


    // redirect to login if not authenticated
    useEffect(() => {
        if (isAuthenticated) {
            // If Authenticated Replace the current entry in the history stack.
            window.history.replaceState(null, '', '/#/ai');
        } else {
            console.log("Trigger redirect to login page at [ai.tsx]");
            // navigate("/login")
        }
    }, [isAuthenticated]);

    // Load selected Endpoint from local storage - fallback to "langchain"
    // const [selectedEndpoint, setSelectedEndpoint] = useState<string>(() => {
    //     const storedEndpoint = localStorage.getItem('selectedEndpoint');
    //     return storedEndpoint ? storedEndpoint : "langchain"; // default to langchain
    // });

    // load selected gpt version from local storage - fallback to chat (gpt3) if blank
    // const [gptVersion, setGptVersion] = useState<string>(() => {
    //     const storedVersion = localStorage.getItem('gptVersion');
    //     return storedVersion ? storedVersion : "gpt35-turbo"; // chat is name of 3.5 model in portal
    // });

    // Get collections
    const fetchCollections = async () => {
        const access_token = await getAccessTokenSilently();
        const allCollections = await getCollectionsApi(access_token); // Assume this returns an array of objects with collection_name
        const selectedCollections = localStorage.getItem('selectedCollections'); // get from storage
        try {
            const parsedCollections = selectedCollections ? JSON.parse(selectedCollections) : [];
            // Filter out collections that no longer exist
            const validCollections = parsedCollections.filter((collection: { collection_name: string; }) =>
                allCollections ? allCollections.some((allCollection) => allCollection.collection_name === collection.collection_name) : false
            );
            setSelectedCollections(validCollections);
        } catch (error) {
            console.error("Failed to parse selectedCollections as JSON: ", error);
        }
    };

    // 1. we are passing in the tool id only so we first need to fetch the whole record from the db
    const getToolRecord = async (id: string) => {
        const accessToken = await getAccessTokenSilently();
        try {
            const response = await getTemplateByIdApi(id, accessToken);
            setMainTool(response); // hold the whole tool object
        } catch (error) {
            console.error("Error fetching template:", error);
        }
    };


    // fetch tokens used
    const fetchTokensUsed = async () => {
        if (user && user.name) {
            const accessToken = await getAccessTokenSilently(); // get access token
            const tokens = await getTokensSinceResetApi(user.name, accessToken);
            setTokensUsed(tokens);
        }
    };

    // Trigger refresh of collections when refreshCollection is true
    useEffect(() => {
        if (refreshCollection) {
            fetchCollections();
        }
    }, [refreshCollection])

    // Get permissions
    useEffect(() => {
        const checkRole = async () => {
            try {
                const token = await getAccessTokenSilently();
                const decodedToken = jwtDecode<CustomJwtPayload>(token);
                const permissions = decodedToken.permissions;
                // Document Collections Manager
                if (permissions.includes('view:Dashboard')) {
                    setIsAuthorizedToViewDocumentCollectionsManager(true);
                }
                // Quick Settings Menu
                if (permissions.includes('view:QuickSettingsMenu')) {
                    setIsAuthorizedToViewQuickSettingsMenu(true);
                }
                //if no role assigned
                if (!permissions.includes('read:Files')) {
                    setNoRoleError(true);
                }
            } catch (e) {
                // Handle errors - e.g. token is invalid, expired, etc.
                console.error(e);
                alert("There was an issue verifying your credentials. Please log in again.")
                navigate("/login");
            }
        }
        checkRole();
    }, [])

    // Get Personas
    const loadPersonas = async () => {
        try {
            const token = await getAccessTokenSilently();
            if (token) {
                // if project was selected
                if (selectedProject) {
                    const personas = await getPromptTemplatesApi(token);
                    setPersonasList(personas);
                    // Try to get the selected persona from local storage
                    const storedPersona = selectedProject?.project_tool_id;
                    // If the stored persona exists in the list, select it
                    // Otherwise, select the first persona
                    const personaToSelect = personas.find(persona => persona.id === storedPersona) || personas[0];
                    setSelectedPersona(personaToSelect);
                }

            }
        } catch (error) {
            console.error('Failed to retrieve access token silently', error);
            setPersonasList([]) // blank to avoid render error
        }
    };


    // 1. load the project record
    const fetchProjectRecords = async (): Promise<Project[]> => {
        const access_token = await getAccessTokenSilently();
        // 2. Look for both - Get the stored project id from local storage
        const storedProject = localStorage.getItem('project'); // get from storage

        // Look at the local Storage for both project and quick and process and delete it
        if (storedProject) {
            // 2a. If stored project id is found, load the project record
            console.log("Loading Stored Project: ", storedProject);
            try {
                // Call the function to get all project records
                const allProjects = await getProjectRecordsApi(access_token);

                // Check if allProjects is null or undefined
                if (!allProjects || !Array.isArray(allProjects)) {
                    console.error('No project records found.');
                    return [];
                }

                // Transform the fetched data to match the Project model
                const collectedProjects: Project[] = allProjects.map((project) => ({
                    id: project.id,
                    project_name: project.project_name,
                    project_description: project.project_description,
                    owner: project.owner,
                    project_tool_name: project.project_tool_name,
                    project_tool_id: project.project_tool_id,
                    project_collections: project.project_collections,
                    last_updated: project.last_updated,
                    prompts: project.prompts,
                    results: project.results
                }));
                //find the stored project id from the list and set it as selected
                const projectToSelect = allProjects.find(project => project.id === storedProject);
                setSelectedProject(projectToSelect);
                setFocusedComponent(gizmoComponents.length);
                setGizmoComponentIndex(gizmoComponents.length);
                // Log or return the transformed project records
                return collectedProjects;
            } catch (error) {
                // Handle any errors that occur during the fetch
                console.error('Error fetching project records:', error);
                throw error;
                return [];
            }
        } else {
            console.log("No stored project or quick gizmo found");
            return []
        };
    }

    //set selectedTool
    useEffect(() => {
        const fetchData = async () => {
            if (!selectedProject) {
                return;
            }
            try {
                await getToolRecord(selectedProject?.project_tool_id);  // Await for getToolRecord
            } catch (error) {
                console.error("An error occurred while setting selected tool record:", error);
            }
        };
        fetchData();
    }, [selectedProject]);  // Add projectInfo as a dependency if it may change

    // UseEffect to load all projects
    useEffect(() => {
        // Define an async function to fetch and set the data
        const loadProjects = async () => {
            try {
                const fetchedProjects = await fetchProjectRecords();
                setProjects(fetchedProjects); // Set the fetched projects
            } catch (error) {
                setError('Failed to fetch projects');
            } finally {
                await loadPersonas();
                setLoadingProjects(false);
            }
        };

        loadProjects(); // Call the async function
    }, [loadingProjects]); // Todo:, check if this is correct dependency

    // useEffect for smooth scrolling
    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [isChatLoading]);

    // UseEffect to listen for key combination to enable the settings button
    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            // Check for the key combination Ctrl + Shift + z
            if (e.ctrlKey && e.shiftKey && e.key.toLowerCase() === 'z') {
                handleToggleSettings();
            }
        };

        // Attach the event listener
        document.addEventListener('keydown', handleKeyDown);

        // Remove the event listener when the component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        };
    }, [settingsButtonVisible]);

    // fetch tokens used since reset
    useEffect(() => {
        fetchTokensUsed();
    }, [user]);

    // Check if token limit reached
    useEffect(() => {
        const checkTokenLimit = async () => {
            if (user && user.name) {
                const token = await getAccessTokenSilently();
                const tokenLimit = await getTokenLimitApi(user.name, token);
                if (tokenLimit !== null && tokensUsed !== null && tokensUsed >= tokenLimit) {
                    setTokenLimitReached(true);
                } else {
                    setTokenLimitReached(false);
                }
            }
        };
        if (tokensUsed !== null) {
            checkTokenLimit();
        }
    }, [user, tokensUsed]);

    // Clear chat
    const clearChat = () => {
        lastQuestionRef.current = "";
        error && setError(undefined);
        setActiveCitation(undefined);
        setActiveAnalysisPanelTab(undefined);
        setAnswers([]);
        setThreadId(uuidv4()); // Generate a new threadId
        // clear user input
    };


    // Handle toggle visibility of settings button
    const handleToggleSettings = () => {
        setSettingsButtonVisible(prevVisible => !prevVisible);
    };

    // Handle Persona Selection Change - (dropdown selector event)
    const onPersonaChange = (event: React.FormEvent<HTMLDivElement>, item: PersonaTemplate) => {
        setSelectedPersona(item);
        setPromptTemplate(item.text);

        // Save persona text to localStorage
        localStorage.setItem('savedItemText', item.text);
        // Check if item.temperature is defined and is a string
        if (item.temperature) {
            // Convert item.temperature to a number before calling setTemperature
            setTemperature(Number(item.temperature));
        }
        // set boolean search types
        // setVectorSearch(item.vector_search || false);
        // setSemanticSearch(item.semantic_search || false);
        // setHybridSearch(item.hybrid_search || false);
    }

    // Load savedItemText from local storage
    // useEffect(() => {
    //     const savedItemText = localStorage.getItem('savedItemText');
    //     if (savedItemText) {
    //         setPromptTemplate(savedItemText);
    //     }
    // }, []);

    // Handle prompt text change
    // const onPromptTemplateChange = (_ev?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    //     setPromptTemplate(newValue || "");
    // };



    const onRetrieveCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setRetrieveCount(parseInt(newValue || "10"));
    };

    const onRetrieveWebCountChange = (_ev?: React.SyntheticEvent<HTMLElement, Event>, newValue?: string) => {
        setRetrieveCount(parseInt(newValue || "5"));
    };

    // const onRetrievalModeChange = (_ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<RetrievalMode> | undefined, index?: number | undefined) => {
    //     setRetrievalMode(option?.data || RetrievalMode.Hybrid);
    // };

    // handle web results change
    const onWebResultsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setIncludeWebResults(!!checked);
    };

    // handle search only mode change
    const onSearchOnlyModeChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setSearchOnlyMode(!!checked);
    };

    // handle company memory change
    const onCompanyMemoryChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setIncludeCompanyMemory(!!checked);
        console.log("Company Memory: ", checked);
    };

    // handle user memory change
    const onUserMemoryChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setIncludeUserMemory(!!checked);
        console.log("User Memory: ", checked);
    };

    // // Endpoint
    // const onEndPointChange = (_ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<string> | undefined) => {
    //     setSelectedEndpoint(option?.data || "chat");
    //     // Save to local storage
    //     localStorage.setItem('selectedEndpoint', option?.data || "chat");
    //     // trim selected indexes to only the first one
    //     setSelectedCollections(prevCollections => prevCollections.slice(0, 1));
    // };

    // // Gpt Version change
    // const onGptVersionChange = (_ev: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<string> | undefined) => {
    //     setGptVersion(option?.data || "gpt4");
    //     // Save to local storage
    //     localStorage.setItem('gptVersion', option?.data || "gpt4");
    // }

    // const onUseSemanticRankerChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
    //     setUseSemanticRanker(!!checked);
    // };

    // const onUseSemanticCaptionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
    //     setUseSemanticCaptions(!!checked);
    // };

    const onUseSuggestFollowupQuestionsChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setUseSuggestFollowupQuestions(!!checked);
    };

    const onShowCitation = (citation: string, index: number) => {

        if (activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
            setShowCitationPanel(false);
        } else {
            setActiveCitation(citation);
            setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
            setShowCitationPanel(true); // if not expanded, this will expand the panel to display the citation
        }
        setSelectedAnswer(index);
    };

    // Toggle citation panel
    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            // setActiveAnalysisPanelTab(tab);
            setActiveAnalysisPanelTab(tab);
        }
        setSelectedAnswer(index);

    };



    //change of component focus 
    const handleFocusChange = (componentName: number) => {
        setFocusedComponent(prev => (prev === componentName ? null : componentName));
        setGizmoComponentIndex(componentName);
    }

    //set list of (gizmo) components to be rendered for the PROJECT section
    useEffect(() => {
        if (!selectedProject) {
            return;
        }

        const newGizmoComponents = [
            <GizmoDocumentCollection
                key="gizmo-document-collection"
                focusedComponent={focusedComponent === 0}
                setFocusedComponent={handleFocusChange}
                componentName={0}
                exportSelectedCollections={setSelectedCollections}
                selectedProject={selectedProject}
                setRefreshCollection={setRefreshCollection}
                refreshCollection={refreshCollection}
            />,
            <GizmoComponentPrompt
                key="gizmo-component-prompt"
                focusedComponent={focusedComponent === 1}
                setFocusedComponent={handleFocusChange}
                componentName={1}
                importSelectedCollections={selectedCollections}
                importSelectedPersona={selectedPersona}
                project={selectedProject}
            />
        ];
        setGizmoComponents(newGizmoComponents);
    }, [selectedProject, focusedComponent, selectedCollections, selectedPersona, handleFocusChange]);


    // Function to handle the next focus change - this makes a list to act as the index
    const handleNextFocus = (index: number) => {
        setGizmoComponentIndex(index);
        if (gizmoComponentIndex >= 0 && gizmoComponentIndex < gizmoComponents.length - 1) {
            setGizmoComponentIndex(gizmoComponentIndex + 1);
            handleFocusChange(gizmoComponentIndex + 1);
        }
    };

    // Auth - should be last before return to cover all
    if (isLoading) {
        return <div className='loading'><Spinner size='large' label='Loading' labelPosition="below" /></div>;
    }

    return (
        <div className={styles.container}>
            <PageHeader selectedTool={mainTool} />
            <Analytics />
            <MaintenanceMode />
            <div className={styles.newQueryButton} onClick={() => navigate('/hello')}>
                <AddCircle20Filled />
                <p>Start a new query</p>
            </div>
            {/*error: no role message*/}
            <Dialog open={NoRoleError} modalType="alert">
                <DialogSurface mountNode={undefined}>
                    <DialogBody>
                        <DialogTitle>Account not yet verified</DialogTitle>
                        <DialogContent>
                            You account provisioning has now started and is normally completed within 20 minutes but can take up to 24 hours.
                            You will receive an email notification once your account has been fully setup and you can start using the app. Contact <a href="emailto:support@blue-edge.ai">support@blue-edge.ai</a> if you are still receiving this message 24 hours after sign up.
                        </DialogContent>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
            {/* conditionally render either the QuickTools component (new), or project settings */}
            {selectedProject ?
                // Quick Tool component
                <div className={styles.mainTool}>
                    <Button icon={<ArrowReplyRegular />} appearance="secondary" onClick={() => navigate('/hello')} style={{ marginBottom: '20px' }}>Cancel & Start Again</Button>


                    <MainTool // This only needs the id of the tool passed in
                        projectInfo={selectedProject} />

                </div>
                :
                <div className={styles.mainTool}>
                    <Button icon={<ArrowReplyRegular />} appearance="secondary" onClick={() => navigate('/hello')} style={{ marginBottom: '20px' }}>Cancel & Start Again</Button>

                    <Spinner label={"LOADING TOOL"} />
                </div>
                // Project section continues
                // <div>
                //     <div className={styles.sectionFull}>
                //         <div className="ms-Grid">
                //             <div className="ms-Grid-row">
                //                 <div className="ms-Grid-col ms-lg4">
                //                     <p>Projects section</p>
                //                     <NavLink to="/hello">
                //                         <Button icon={<SaveRegular />}>Save and Close</Button>
                //                     </NavLink>
                //                     <h2 className={styles.gizmoComponentName} >Tender Details</h2>

                //                 </div>

                //                 <div className="ms-Grid-col ms-lg8">
                //                     {!loadingProjects ?
                //                         <div>
                //                             <p className={styles.projectName}>{selectedProject?.project_name}</p>
                //                             <p className={styles.projectDescription}>{selectedProject?.project_description}</p>
                //                             <div className={styles.projectGizmo}>
                //                                 <h3 className={styles.summaryHeading}>Tender Gizmo:</h3>
                //                                 <div className={styles.gizmoIconContainer}>
                //                                     <PersonaIcon iconName={selectedPersona?.iconName} />
                //                                 </div>
                //                                 <div>
                //                                     <h4 className={styles.gizmoName}>{selectedPersona?.name}</h4>
                //                                     <div className={styles.gizmoDescription} dangerouslySetInnerHTML={{ __html: selectedPersona?.long_description ?? '' }} />
                //                                 </div>
                //                             </div>
                //                         </div> : <Spinner />
                //                     }
                //                 </div>
                //             </div>
                //         </div>
                //     </div>



                //     {/* here is where we map out the rest of the components for the project that were set by the useEffect */}
                //     {/* doc collections and ask anything are in the list */}
                //     {!loadingProjects ?
                //         // Next button
                //         <div>
                //             {gizmoComponents.map((gizmo, index) => (
                //                 <div className={styles.sectionFull} key={index}>
                //                     {gizmo}
                //                     {gizmoComponentIndex === index && index !== gizmoComponents.length - 1 ? (
                //                         <Button
                //                             className={styles.confirmGizmoBtn}
                //                             appearance="primary"
                //                             onClick={() => handleNextFocus(index)}
                //                             icon={<ChevronRightRegular />}
                //                             iconPosition="after"
                //                         >
                //                             Next
                //                         </Button>
                //                     ) : null}
                //                 </div>
                //             ))}
                //         </div>
                //         : ' '}
                // </div>
            }

        </div >
    );
};

export default Ai;
