// This page will have a form where you can add line items as a description for a company.
// The items can be acronyms, definitions, projects the company has worked on, affiliations, and other information.

import { useAuth0 } from '@auth0/auth0-react';
import type { InputProps } from "@fluentui/react-components";
import { Button, Image, Input, Label, Spinner, Text, Toast, Toaster, ToastTitle, useId, useToastController } from '@fluentui/react-components';
import { ArrowClockwiseDashesRegular, Chat24Regular } from '@fluentui/react-icons';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UserProfile from '../../components/UserProfile/UserProfile';
import styles from './companyProfile.module.css';
import { ManagementToolbar } from '../../components/ManagmentToolbar';
import { getCompanyProfileApi, updateCompanyProfileApi } from '../../api';
import ClientLogo from '../../assets/LogoTransparent.png';
import { MaintenanceMode } from "../../components/MaintenanceMode";


// Define the type for the company profile and the fields that will be in the form
type CompanyProfileType = {
    company_name: string,
    acronyms: string,
    description: string,
    projects: string,
    affiliations: string,
    competition: string,
    industry: string,
    key_products_services: string,
    clients: string,
    company_culture_keywords: string,
    important_dates: string,
    locations: string,
    primary_language: string,
    vision_mission_statement: string,
    [key: string]: string // index signature
}

// Function to get total character count of all form fields
const getTotalCharacterCount = (formData: CompanyProfileType) => {
    let totalCharacterCount = 0;
    for (const key in formData) {
        if (formData[key]) {
            totalCharacterCount += formData[key].length;
        }
    }
    return totalCharacterCount;
};


const CompanyProfile: React.FC = (props: InputProps) => {
    const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
    const navigate = useNavigate();
    const [isUpdating, setIsUpdating] = React.useState(false); // Track loading
    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);



    // state to track form data - Initialize with empty strings - this will make the components controlled by default
    const [formData, setFormData] = React.useState<CompanyProfileType>({
        company_name: '',
        acronyms: '',
        description: '',
        projects: '',
        affiliations: '',
        competition: '',
        industry: '',
        key_products_services: '',
        clients: '',
        company_culture_keywords: '',
        important_dates: '',
        locations: '',
        primary_language: '',
        vision_mission_statement: ''
    });


    // Check auth
    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/login"); // Navigate the user to login if they are not authenticated.
        }
    }, [isAuthenticated, navigate]);

    // Function to get company profile
    const getCompanyProfile = async () => {
        // Get the access token
        const token = await getAccessTokenSilently();
        // Call the getCompanyProfileApi function
        try {
            const profiles = await getCompanyProfileApi(token); // returns array
            if (profiles.length > 0) {
                const companyProfile = profiles[0] // get just the first company profile(there will be only 1)
                setFormData(companyProfile); // set the form data to the company profile
            } else {
                setFormData({
                    company_name: '',
                    acronyms: '',
                    description: '',
                    projects: '',
                    affiliations: '',
                    competition: '',
                    industry: '',
                    key_products_services: '',
                    clients: '',
                    company_culture_keywords: '',
                    important_dates: '',
                    locations: '',
                    primary_language: '',
                    vision_mission_statement: ''
                });
            }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            let errorMessage = 'Updating company profile failed:';
            // If the error response has a message property, use it
            if (error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            }
            console.error(`Getting company profile failed: ${errorMessage}`);
        }
    };

    // useEffect to getCompanyProfile on page load
    useEffect(() => {
        getCompanyProfile();
    }, []);

    // Submit form
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsUpdating(true); // Set loading to true

        // Get the access token
        const token = await getAccessTokenSilently();

        // Convert formData to FormData
        const formDataObj = new FormData();
        for (const key in formData) {
            formDataObj.append(key, formData[key]);
        }

        // Call the updateCompanyProfileApi function
        try {
            const data = await updateCompanyProfileApi(formDataObj, token);
            // extract the message
            const successMessage = data.success
            // Dispatch success toast
            dispatchToast(
                <Toast>
                    <ToastTitle>{successMessage}</ToastTitle>
                </Toast>,
                { intent: 'success' }
            );
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
            let errorMessage = 'Updating company profile failed';

            // If the error response has a message property, use it
            if (error.response && error.response.data && error.response.data.message) {
                errorMessage = error.response.data.message;
            }
            // Dispatch error toast
            dispatchToast(
                <Toast>
                    <ToastTitle>{errorMessage}</ToastTitle>
                </Toast>,
                { intent: 'error' }
            );
        } finally {
            setIsUpdating(false); // Set loading to false
        }
    };

    // Function to handle input changes
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData(prevState => ({ ...prevState, [name]: value }));
    };

    const totalCharacterCount = getTotalCharacterCount(formData); // Get total character count of all form fields

    // While auth is loading show indicator
    if (isLoading) {
        return <div><Spinner /></div>;
    }


    return (
        <div className='ms-Grid' dir='ltr'>
            <MaintenanceMode />
            <div className='ms-Grid-row'>
                {/* Side menu - 2/12ths of grid*/}
                <div className='ms-Grid-col ms-lg2'>
                    <div className='ms-Grid' dir='ltr' style={{ height: '100vh' }}>
                        <div className={styles.sideMenu}>
                            <div className={styles.sideMenuTop}>
                                <div className={styles.logoContainer}>
                                    {/* Client Logo */}
                                    <Image className={styles.logo} src={ClientLogo} alt="Logo" />
                                </div>

                                {/* User info - wrap in isAuthenticated to prevent render leakage*/}
                                <div className={styles.userDropdown}>
                                    {isAuthenticated ? (
                                        <UserProfile />
                                    ) : null}
                                </div>
                                <ManagementToolbar />
                                {/* Back to chat button */}
                                <div className={styles.backToChatBtn}>
                                    <Button
                                        appearance="secondary"
                                        onClick={() => window.location.href = "/#/chat"}
                                        icon={<Chat24Regular />}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                            <Text
                                                as='span'
                                                size={300}
                                                weight='semibold'
                                            >Back to AI chat</Text>
                                        </div>
                                    </Button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {/* Main content - ms-lg5 = 5/12ths of grid */}
                <div className='ms-Grid-col ms-lg5' style={{ height: '99vh' }}>
                    <div className={styles.mainContent}>
                        <h1>Company Profile </h1>

                        <p>Information entered here will be passed to the AI with each chat interaction. Let's try to keep the total characters under 1000 total for all fields.</p>

                        <div className={styles.refreshContainer}>
                            {/* Button to refresh */}
                            <Button
                                onClick={getCompanyProfile}
                                icon={<ArrowClockwiseDashesRegular />}
                                appearance='transparent'
                                title='Refresh'
                            />
                        </div>


                        {/* Toaster - can be anywhere in the return of component- pass position for location*/}
                        <Toaster
                            toasterId={toasterId}
                            mountNode={undefined}
                            position={"top-end"} // top right
                        />
                        <br />
                        <hr />

                        {/* Form */}
                        <form
                            className={styles.form}
                            onSubmit={handleSubmit}
                        >
                            {/* Company Name */}
                            <Label
                                htmlFor='companyName'
                                size={props.size}
                            >
                                Company Name
                            </Label>
                            <Input type="text"
                                name='companyName'
                                value={formData.company_name}
                                onChange={handleInputChange}
                                placeholder='Company Name'
                                aria-label='Company Name'
                            />

                            {/* acronyms */}
                            <Label>Acronyms - Seperate multiple acronyms with commas</Label>
                            <Input type="text"
                                name='acronyms'
                                value={formData.acronyms}
                                onChange={handleInputChange}
                                placeholder='BE = Blue Edge.'
                                aria-label='Acronyms'
                            />

                            {/* Description */}
                            <Label>Description</Label>
                            <Input type="text"
                                name='description'
                                value={formData.description}
                                onChange={handleInputChange}
                                placeholder='Company Description'
                                aria-label='Description'
                            />

                            {/* Projects */}
                            <Label>Projects</Label>
                            <Input type="text"
                                name='projects'
                                value={formData.projects}
                                onChange={handleInputChange}
                                placeholder='Projects the company has worked on.'
                                aria-label='Projects'
                            />

                            {/* Affiliations */}
                            <Label>Affiliations</Label>
                            <Input type="text"
                                name='affiliations'
                                value={formData.affiliations}
                                onChange={handleInputChange}
                                placeholder='Affiliations with other companies or organizations.'
                                aria-label='Affiliations'
                            />

                            {/* Competition */}
                            <Label>Competition</Label>
                            <Input type="text"
                                name='competition'
                                value={formData.competition}
                                onChange={handleInputChange}
                                placeholder='Competitors of the company.'
                                aria-label='Competition'
                            />

                            {/* Industry */}
                            <Label>Industry</Label>
                            <Input type="text"
                                name='industry'
                                value={formData.industry}
                                onChange={handleInputChange}
                                placeholder='Industry the company is in.'
                                aria-label='Industry'
                            />

                            {/* Key Products or Services */}
                            <Label>Key Products or Services</Label>
                            <Input type="text"
                                name='key_products_services'
                                value={formData.key_products_services}
                                onChange={handleInputChange}
                                placeholder='Key Products or Services the company offers.'
                                aria-label='Key Products or Services'
                            />

                            {/* Clients */}
                            <Label>Clients</Label>
                            <Input type="text"
                                name='clients'
                                value={formData.clients}
                                onChange={handleInputChange}
                                placeholder='Clients the company has worked with.'
                                aria-label='Clients'
                            />

                            {/* Company Culture Keywords */}
                            <Label>Company Culture Keywords</Label>
                            <Input type="text"
                                name='company_culture_keywords'
                                value={formData.company_culture_keywords}
                                onChange={handleInputChange}
                                placeholder='Keywords that describe the company culture.'
                                aria-label='Company Culture Keywords'
                            />

                            {/* Important Dates */}
                            <Label>Important Dates</Label>
                            <Input type="text"
                                name='important_dates'
                                value={formData.important_dates}
                                onChange={handleInputChange}
                                placeholder='Important dates in the company history.'
                                aria-label='Important Dates'
                            />

                            {/* Locations */}
                            <Label>Locations</Label>
                            <Input type="text"
                                name='locations'
                                value={formData.locations}
                                onChange={handleInputChange}
                                placeholder='Locations of the company.'
                                aria-label='Locations'
                            />

                            {/* Primary Language */}
                            <Label>Primary Language</Label>
                            <Input type="text"
                                name='primary_language'
                                value={formData.primary_language}
                                onChange={handleInputChange}
                                placeholder='Primary language of the company.'
                                aria-label='Primary Language'
                            />

                            {/* Vision and Mission Statement */}
                            <Label>Vision and Mission Statement</Label>
                            <Input type="text"
                                name='vision_mission_statement'
                                value={formData.vision_mission_statement}
                                onChange={handleInputChange}
                                placeholder='Vision and Mission Statement of the company.'
                                aria-label='Vision and Mission Statement'
                            />
                        </form>
                    </div>
                </div>








                {/* Right side - ms-lg5 = 5/12ths of grid */}
                <div className='ms-Grid-col ms-lg5' style={{ height: '99vh' }}>
                    <div className={styles.rightSide}>
                        <Text
                            as='h2'
                            align='center'
                            size={500}
                        >Save your changes</Text>
                        {/* Submit */}
                        <Button
                            type='submit'
                            onClick={handleSubmit}
                            disabled={isUpdating}
                            appearance='outline'
                        >
                            {isUpdating ? (
                                <>
                                    <Spinner
                                        size={"tiny"}
                                        label="..saving"
                                    />
                                </>
                            ) : 'Submit'}
                        </Button>
                        <hr />

                        <Text
                            as='span'
                        >Character Total for all fields:</Text>
                        {/* count */}
                        <Text
                            as='span'
                            style={{ color: 'var(--primary-color)' }}
                        >{totalCharacterCount}</Text>
                    </div>
                </div>
            </div>

        </div >
    );
}

export default CompanyProfile