
import { SupportingContentItem } from "../../api";
import styles from "./SupportingContent.module.css";



interface Props {
    supportingContent: SupportingContentItem[];
    selectedSource: string | undefined;
}



export const SupportingContent = ({ supportingContent, selectedSource }: Props) => {
    // Calculate total number of partitions

    return (
        <ul className={styles.supportingContentNavList}>

            {/* Map out the supporting content */}
            {supportingContent.filter((source => source.documentId == selectedSource)).map((item, i) => {
                return (
                    < div key={i} className={styles.supportingContentItem}>

                        {item.partitions.map((partition, index) => (
                            <div key={index}
                                className={styles.supportingContentPartition}
                            >
                                <h3 className={styles.gizmoComponentSubheading}>Source Text {index + 1}</h3>
                                <p>{partition.text}</p>
                                <div className={styles.supportingContentInfo}>
                                    <p>Relevance: {partition.relevance}</p>
                                    <p>Partition Number: {partition.partitionNumber}</p>
                                    <p>Last Update: {partition.lastUpdate}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    // <li
                    //     key={i}
                    //     className={styles.supportingContentItem}>
                    //     <p className={styles.supportingContentItemText}>Type: {item.sourceContentType}</p>
                    //     <p className={styles.supportingContentItemText}>Collection: {item.index}</p>
                    //     <p className={styles.supportingContentItemText}>Sections Sent: {item.partitions.length}</p>
                    //     <p className={styles.supportingContentItemText}>Link: {item.link}</p>
                    //     <p className={styles.supportingContentItemText}>
                    //         Download URL:
                    //         <a href={`https://km-service-762zazlx44o66.proudwater-2d088d8a.uksouth.azurecontainerapps.io${item.sourceUrl}`} target="_blank" rel="noopener noreferrer">
                    //             {item.sourceUrl}
                    //         </a>
                    //     </p> */}

                    //     {item.partitions.map((partition, index) => (
                    //         <div key={index}
                    //             className={styles.supportingContentPartition}
                    //         >
                    //             <h3>Section {index + 1}:</h3>
                    //             <p>{partition.text}</p>
                    //             <p>Relevance: {partition.relevance}</p>
                    //             <p>Partition Number: {partition.partitionNumber}</p>
                    //             <p>Last Update: {partition.lastUpdate}</p>
                    //         </div>
                    //     ))}
                    // </li>

                );
            })}
        </ul>
    );
};
