// This page will have a form where you can add line items as a description for a company.
// The items can be acronyms, definitions, projects the company has worked on, affiliations, and other information.

import { useAuth0 } from '@auth0/auth0-react';
import { Button, Image, Input, Text } from '@fluentui/react-components';
import { Chat24Regular } from '@fluentui/react-icons';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getMaintenanceModeLogsApi, setMaintenanceModeApi, getGoogleAnalyticsApi, setGoogleAnalyticsApi, setClientLogoUrlApi, getClientLogoUrlApi } from '../../api';
import ClientLogo from '../../assets/LogoTransparent.png';
import { ManagementToolbar } from '../../components/ManagmentToolbar';
import SystemStatus from '../../components/SystemStatus/SystemStatus';
import UserProfile from '../../components/UserProfile/UserProfile';
import styles from './adminPage.module.css';
import { TextField } from '@fluentui/react';



const CompanyProfile: React.FC = () => {
    const { isAuthenticated, getAccessTokenSilently, user } = useAuth0();
    const [isMaintenanceModeActive, setIsMaintenanceModeActive] = React.useState<boolean>(false); // support access status [true/false]
    const [googleAnalyticsID, setGoogleAnalyticsID] = useState('');
    const [clientLogoURL, setClientLogoURL] = useState<string>('');
    const [timestamp, setTimestamp] = React.useState<string>(''); // timestamp of the most recent support access status
    const [lastUserThatCreatedRecord, setLastUserThatCreatedRecord] = React.useState<string>(''); // username of the most recent support access status
    const navigate = useNavigate();

    // Redirect to login page if not authenticated
    useEffect(() => {
        if (isAuthenticated) {
            // If Authenticated Replace the current entry in the history stack.
            window.history.replaceState(null, '', '/#/admin');
        } else {
            console.log("Trigger redirect to login page at [admin.tsx]");
            navigate("/login")
        }
    }, [isAuthenticated]);

    // Function to Fetch the maintenance mode status from the server
    const fetchMaintenanceMode = async () => {
        const token = await getAccessTokenSilently();
        const maintenanceModeArray = await getMaintenanceModeLogsApi(token); // fetch the data
        if (maintenanceModeArray && maintenanceModeArray.length > 0) {
            const record = maintenanceModeArray[0]; // get just the first record if multiple exist
            setIsMaintenanceModeActive(record.action === true); // any value other than 'true' from the db is considered false
            const date = new Date(record.timestamp);
            setTimestamp(date.toLocaleString());
            setLastUserThatCreatedRecord(record.username);
        }
    };

    // Call the function on page load
    React.useEffect(() => {
        fetchMaintenanceMode();
        fetchGoogleAnalyticsSettings();
        fetchClientLogoSettings();
    }, []);

    // Function to activate maintenance mode
    const activateMaintenanceMode = async (username: string) => {
        try {
            const token = await getAccessTokenSilently();
            const response = await setMaintenanceModeApi(username, true, token);
            console.log('Maintenance Mode enabled by user:', username, response);
        } catch (error) {
            console.error('Error activating maintenance mode:', error);
        }
        finally {
            fetchMaintenanceMode();
        }
    };

    // Function to deactivate maintenance mode
    const deactivateMaintenanceMode = async (username: string) => {
        try {
            const token = await getAccessTokenSilently();
            const response = await setMaintenanceModeApi(username, false, token);
            console.log('Maintenance Mode disabled by user:', username, response);
        } catch (error) {
            console.error('Error deactivating maintenance mode:', error);
        }
        finally {
            fetchMaintenanceMode();
        }
    };

    //google analytics settings
    const googleAnalyticsOnChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setGoogleAnalyticsID(newValue || '');
    }
    //set the google analytics settings
    const saveGoogleAnalyticsSettings = async () => {
        try {
            //  const token = await getAccessTokenSilently();
            const response = await setGoogleAnalyticsApi(googleAnalyticsID);
            console.log('google analytics set by user:', googleAnalyticsID, response);
        } catch (error) {
            console.error('Error saving google analytics:', error);
        }
        finally {
            fetchGoogleAnalyticsSettings();
        }
    }

    // Function to Fetch the google analytics settings from the server
    const fetchGoogleAnalyticsSettings = async () => {
        const token = await getAccessTokenSilently();
        const google_analytics_id = await getGoogleAnalyticsApi(token); // fetch the data
        if (google_analytics_id && google_analytics_id.length > 0) {
            const record = google_analytics_id[0]; // get just the last record if multiple exist
            setGoogleAnalyticsID(record.googleAnalyticsID); // any value other than 'true' from the db is considered false
        }
    };


    //client logo settings
    const clientLogoOnChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setClientLogoURL(newValue || '');
    }
    //set the client logo settings
    const saveClientLogoSettings = async () => {
        try {
            //  const token = await getAccessTokenSilently();
            const response = await setClientLogoUrlApi(clientLogoURL);
            console.log('client logo set by user:', clientLogoURL, response);
        } catch (error) {
            console.error('Error saving client logo:', error);
        }
        finally {
            fetchClientLogoSettings();
        }
    }

    // Function to Fetch the client logo url from the server
    const fetchClientLogoSettings = async () => {
        const token = await getAccessTokenSilently();
        const client_logo = await getClientLogoUrlApi(token); // fetch the data
        if (client_logo && client_logo.length > 0) {
            const record = client_logo[0]; // get just the last record if multiple exist
            setClientLogoURL(record.clientLogoID); // any value other than 'true' from the db is considered false
            console.log(record.clientLogoID)

        }
    };



    return (
        <div className='ms-Grid' dir='ltr'>
            <div className='ms-Grid-row'>
                {/* Side menu - 2/12ths of grid*/}
                <div className='ms-Grid-col ms-lg2'>
                    <div className='ms-Grid' dir='ltr' style={{ height: '100vh' }}>
                        <div className={styles.sideMenu}>
                            <div className={styles.sideMenuTop}>
                                <div className={styles.logoContainer}>
                                    {/* Client Logo */}
                                    <Image className={styles.logo} src={ClientLogo} alt="Logo" />
                                </div>

                                {/* User info - wrap in isAuthenticated to prevent render leakage*/}
                                <div className={styles.userDropdown}>
                                    {isAuthenticated ? (
                                        <UserProfile />
                                    ) : null}
                                </div>
                                <ManagementToolbar />
                                {/* Back to chat button */}
                                <div className={styles.backToChatBtn}>
                                    <Button
                                        appearance="secondary"
                                        onClick={() => window.location.href = "/#/hello"}
                                        icon={<Chat24Regular />}
                                    >
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                                            <Text
                                                as='span'
                                                size={300}
                                                weight='semibold'
                                            >Back to AI chat</Text>
                                        </div>
                                    </Button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                {/* Main content - ms-lg5 = 5/12ths of grid */}
                <div className='ms-Grid-col ms-lg5' style={{ height: '99vh' }}>
                    <div className={styles.mainContent}>
                        <h1>Admin Page </h1>
                        <SystemStatus />
                        <div className={styles.maintenanceMode}>
                            <h4>Maintenance Mode</h4>

                            {isMaintenanceModeActive ? (
                                <p>{lastUserThatCreatedRecord}<br></br>{timestamp}</p>
                            ) : (
                                <p>Not active</p>
                            )}
                            {/* Button wrapper */}
                            <div className={styles.buttonWrapper}>
                                {/* Enable button */}
                                <Button appearance='secondary' onClick={() => activateMaintenanceMode(user?.email || '')} disabled={isMaintenanceModeActive}>Enable Maintenance Mode</Button>
                                {/* Disable button */}
                                <Button appearance='secondary' onClick={() => deactivateMaintenanceMode(user?.email || '')} disabled={!isMaintenanceModeActive}>Disable Maintenance Mode</Button>
                            </div>
                        </div>
                        <div className={styles.maintenanceMode}>
                            <h4>Logo URL</h4>
                            <p>insert logo url below for this server:</p>
                            <TextField value={clientLogoURL} onChange={clientLogoOnChange} />
                            <Button style={{ width: '100px', marginTop: '5px' }} appearance='primary' onClick={saveClientLogoSettings}>Save logo</Button>
                        </div>
                        <div className={styles.maintenanceMode}>
                            <h4>Analytics settings</h4>
                            <p>Input the analytics tracking ID below:</p>
                            <TextField value={googleAnalyticsID} onChange={googleAnalyticsOnChange} />
                            <Button style={{ width: '200px', marginTop: '5px' }} appearance='primary' onClick={saveGoogleAnalyticsSettings}>Save Analytics Settings</Button>

                        </div>
                    </div>
                </div>


                {/* Right side - ms-lg5 = 5/12ths of grid */}
                <div className='ms-Grid-col ms-lg5' style={{ height: '99vh' }}>
                    <div className={styles.rightSide}>

                    </div>
                </div>
            </div>

        </div >
    );
}

export default CompanyProfile