// This component is responsible for displaying the status of the files that have been uploaded
import { useAuth0 } from '@auth0/auth0-react';
import { Button, createTableColumn, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridRow, DataGridProps, Popover, PopoverSurface, PopoverTrigger, Spinner, TableCellLayout, TableColumnDefinition, Text, useId } from '@fluentui/react-components';
import { ArrowDownloadFilled, BezierCurveSquare20Regular, Delete24Regular, DocumentBulletListMultiple24Regular, EmojiSadSlight24Regular, ImageCircle24Regular, LinkSquare24Regular, DocumentPdfRegular } from '@fluentui/react-icons';
import React, { useEffect, useState } from 'react';
import { DataGridItem, deleteSingleFileApi, FileStatusItem, getFileStatusByIndexApi, retrieveFileApi, SelectedFile } from '../../api';
import styles from './FileStatus.module.css';
import DOMPurify from 'dompurify';
import UploadStatus from '../UploadStatus/UploadStatus';

interface Props {
    indexName: string;
    shouldRefresh: boolean; // boolean to trigger a refresh of the file status list = setRefreshGrid
    setSelectedPdf: (pdf: SelectedFile) => void;
    setRefreshGrid: React.Dispatch<React.SetStateAction<boolean>>;
    setNumberOfFilesInSelectedCollection: React.Dispatch<React.SetStateAction<number>>;
    autoRefresh: boolean;
    searchFilterText: string;
    isBEadmin: boolean;
}

const FileStatus = ({
    indexName,
    shouldRefresh,
    setSelectedPdf,
    setRefreshGrid,
    setNumberOfFilesInSelectedCollection,
    autoRefresh,
    searchFilterText,
    isBEadmin,
}: Props) => {
    const { getAccessTokenSilently } = useAuth0();
    const [fileStatuses, setFileStatuses] = useState<FileStatusItem[]>([]);
    const [loading, setLoading] = useState(true);
    const [isDeleting, setIsDeleting] = useState(false); // state to track if file is being deleted
    const [searchFilter, setSearchFilter] = useState<string>('');
    const [filter, setFilter] = useState<FileStatusItem[]>([]);
    // const [isClearingFilter, setIsClearingFilter] = useState(false);
    const [isPdfDownloading, setIsPdfDownloading] = useState(false); // show loading while pdf is being downloaded
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 25;


    // Define columns for the DataGrid
    const columns: TableColumnDefinition<DataGridItem>[] = [
        createTableColumn<DataGridItem>({
            columnId: "number",
            renderHeaderCell: () => {
                return "#";
            },
            renderCell: (item) => {
                return (
                    <div className={styles.numberCell}>
                        {item.number}
                    </div>
                );
            },
        }),
        createTableColumn<DataGridItem>({

            columnId: "file",
            // add compare method to enable the sorting of the file column
            compare: (a, b) => {
                return a.file.label.localeCompare(b.file.label);
            },
            renderHeaderCell: () => {
                return (
                    <div>
                        <Text>
                            File
                        </Text>
                    </div>
                );
            },
            renderCell: (item) => {
                return (
                    <div className={styles.fileCell} >
                        <TableCellLayout media={item.file.icon}>
                            <Text as='span' title={item.documentId}>
                                {item.file.label}
                            </Text>
                        </TableCellLayout>
                    </div>
                );
            },
        }),
        createTableColumn<DataGridItem>({
            columnId: "status",
            // sort
            compare: (a, b) => {
                return a.status.localeCompare(b.status);
            },
            renderHeaderCell: () => {
                return "Status";
            },
            renderCell: (item) => {
                return (
                    <div className={styles.cell}>
                        <UploadStatus
                            documentId={item.documentId || ''}
                            index={indexName || ''}
                            fileName={item.file.id || ''}
                            miniDisplay={true}
                        />
                    </div>
                )
            },
        }),
        createTableColumn<DataGridItem>({
            columnId: "actions",
            renderHeaderCell: () => {
                return "Actions";
            },
            renderCell: (item) => {
                return (
                    // Confirmation popover
                    <>
                        <Button disabled={isPdfDownloading || item.file.id.endsWith('.url')} onClick={() => downloadOriginal(indexName, item.documentId, item.file.id)} icon={<ArrowDownloadFilled />}></Button>
                        -
                        <Popover
                            trapFocus>
                            <PopoverTrigger
                                disableButtonEnhancement>
                                <Button
                                    as='button'
                                    icon={<Delete24Regular />}></Button>
                            </PopoverTrigger>

                            <PopoverSurface aria-labelledby={id}>
                                <div className={styles.popover}>
                                    <Text
                                        as='span'
                                        size={400}
                                        style={{ color: 'red' }}
                                    >
                                        Delete File?
                                    </Text>
                                    <br />

                                    <Text>
                                        Are you sure you want to delete this file?
                                    </Text>

                                    <div>
                                        {isDeleting ? (
                                            <Spinner
                                                label="Deleting..."
                                                size='extra-small'
                                            />
                                        ) : (
                                            <>
                                                <Button
                                                    appearance='primary'
                                                    onClick={() => {
                                                        handleDelete(
                                                            // Order: indexName: string, fileName: string, document_id: string
                                                            indexName,
                                                            item.file.label,
                                                            item.documentId
                                                        );
                                                    }}
                                                >
                                                    Confirm
                                                </Button>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </PopoverSurface>
                        </Popover>
                        {isBEadmin ?
                            <UploadStatus
                                documentId={item.documentId || ''}
                                index={indexName || ''}
                                fileName={item.file.id || ''}
                            />
                            : ''}
                    </>

                )
            }
        })
    ];

    const columnSizingOptions = {
        number: {
            minWidth: 50,
            defaultWidth: 50,
        },
        file: {
            minWidth: 420,
            defaultWidth: 420,

        },
        status: {
            defaultWidth: 180,
            minWidth: 120,
            idealWidth: 180,
        },
        actions: {
            defaultWidth: 60,
            minWidth: 60,
            idealWidth: 60,
        },
    };

    const defaultSortState = React.useMemo<
        Parameters<NonNullable<DataGridProps["onSortChange"]>>[1]
    >(() => ({ sortColumn: "number", sortDirection: "ascending" }), []);


    // Convert list of file status items retrieved from db to list of DataGrid items
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const fileStatusList: FileStatusItem[] = fileStatuses.map((fileStatus: any) => ({
        file: {
            label: fileStatus.file_name,
            icon: fileStatus.file_name.endsWith('.pdf') ? <DocumentPdfRegular /> :
                fileStatus.file_name.endsWith('.url') ? <LinkSquare24Regular /> :
                    fileStatus.file_name.endsWith('.docx') ? <DocumentBulletListMultiple24Regular /> :
                        /\.(jpg|jpeg|png|gif)$/i.test(fileStatus.file_name) ? <ImageCircle24Regular /> :
                            <BezierCurveSquare20Regular />,
            id: fileStatus.file_name,
        },
        documentId: fileStatus.document_id,
        index_name: fileStatus.index_name,
        status: fileStatus.status,
        lastUpdated: {
            label: fileStatus.time,
            timestamp: new Date(fileStatus._ts * 1000) // _ts is a Unix timestamp, so we multiply by 1000 to convert it to JavaScript Date
        },
    }));

    // Fetch file statuses
    const fetchFileStatuses = async (hardRefresh?: boolean) => {
        hardRefresh ? setLoading(true) : '';
        const token = await getAccessTokenSilently();
        const data = await getFileStatusByIndexApi(indexName, token);
        setFileStatuses(data);
        setNumberOfFilesInSelectedCollection(data.length);
        hardRefresh ? setLoading(false) : '';
        hardRefresh ? clearFilter() : '';
        setRefreshGrid(false); // setRefreshGrid to false once the file statuses have been fetched to be triggerd again
    };

    // Fetch file statuses on mount and when indexName or autoRefresh changes
    useEffect(() => {

        fetchFileStatuses(true);
        let intervalId: NodeJS.Timeout;
        if (autoRefresh) {
            intervalId = setInterval(() => {
                fetchFileStatuses();
            }, 10000);
        }

        // Cleanup function to clear the interval when autoRefresh is false or when the component unmounts
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [indexName, autoRefresh]);

    // Refresh file statuses when shouldRefresh is true- triggered by UploadArea component
    useEffect(() => {
        if (shouldRefresh) {
            fetchFileStatuses(true);

            // being set to false in the fetchFileStatuses function
        }
    }, [shouldRefresh]);

    // Handle delete file status
    // indexName: string, fileName: string, document_id: string
    const handleDelete = async (indexName: string, fileName: string, document_id: string) => {
        // Check if document_id is defined
        if (!document_id) {
            console.log("Error: document_id is undefined");
            alert("Error: Unable to delete file, document_id is undefined. Please try again.");
            return;
        }
        // Check if indexName is defined
        if (!indexName) {
            console.log("Error: indexName is undefined");
            alert("Error: Unable to delete file, indexName is undefined. Please try again.");
            return;
        }
        // Check if fileName is defined
        if (!fileName) {
            console.log("Error: fileName is undefined");
            alert("Error: Unable to delete file, fileName is undefined. Please try again.");
            return;
        }


        console.log(`Deleting file ${fileName} from index ${indexName}...`);
        setIsDeleting(true);
        const token = await getAccessTokenSilently();

        // Delete single file - will need to pass in index and document_id
        const fileResponse = await deleteSingleFileApi(indexName, document_id, fileName, token);
        console.log("Delete file response at handleDelete: ", fileResponse);
        alert("File Deletion started. Please allow a bit of time for it to be completely purged.");
        setIsDeleting(false);
        fetchFileStatuses(true); // refresh file status list
        // set selected pdf to empty
        setSelectedPdf({
            id: '',
            indexName: '',
            document_id: ''
        });// to trigger the clear of the state
    };

    // const addIcon: IIconProps = { iconName: "Add" };
    const id = useId();

    //filter the file list with the search box
    const searchFilterOnChange = (newValue: string) => {
        setSearchFilter(newValue || '');
        const filteredItems = fileStatusList.filter(fileStatusList => fileStatusList.file.label.toLowerCase().includes((newValue || '').toLowerCase()));
        setFilter(filteredItems);
    }

    const clearFilter = () => {
        setSearchFilter('');
        const filteredItems = fileStatusList.filter(fileStatusList => fileStatusList.file.label.toLowerCase().includes(('').toLowerCase()));
        setFilter(filteredItems);
    }

    //listen for search filter changes
    useEffect(() => {
        if (searchFilterText != '') {
            searchFilterOnChange(searchFilterText);
        }
    }
    );

    // Function to handle the download original file - will need index document id and file name
    // filename, index_name, documentId
    const downloadOriginal = async (index_name: string, documentId: string, filename: string,) => {
        setIsPdfDownloading(true);
        try {
            const token = await getAccessTokenSilently(); // get access token

            // index_name, document_id, filename, token
            const fileResponse = await retrieveFileApi(index_name, documentId, filename, token); // get the path to the file
            console.log('File response:', fileResponse)

            // sanatize the before creating the link to avoid XSS issues for the URL
            const sanitizedUrl = DOMPurify.sanitize(new URL(fileResponse).toString());

            //create the download link
            const link = document.createElement('a');
            link.href = sanitizedUrl;
            link.setAttribute(
                'download',
                filename,
            );
            // Append to html link element page
            document.body.appendChild(link);
            // Start download
            link.click();
        } catch (error) {
            console.error('Error downloading file:', error);
        } finally {
            setIsPdfDownloading(false);
        }
    };


    //Pagination for the datagrid
    const getCurrentPageItems = () => {
        const filteredItems = searchFilter === ''
            ? fileStatusList.filter((processing) => !processing.status.startsWith('Processing'))
            : filter;
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return filteredItems.slice(startIndex, endIndex).map((item, index) => ({
            ...item,
            number: startIndex + index + 1
        }));
    };

    const totalPages = Math.ceil((searchFilter === '' ? fileStatusList.filter((processing) => !processing.status.startsWith('Processing')) : filter).length / itemsPerPage);

    const handlePreviousPage = () => {
        setCurrentPage((prev) => Math.max(prev - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    };

    const handlePageInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const pageNumber = parseInt(event.target.value, 10);
        if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };




    return (
        <div className={styles.statusContainer}>
            {/* List file status */}
            <div>
                {/* Display if loading */}
                {loading ? (
                    <Spinner size="large" labelPosition='below' label='Opening Collection' className={styles.loadingSpinner}></Spinner>
                ) : (

                    <div className={styles.listContainer}>
                        {/* Display if no files in the collection */}
                        {fileStatuses.length === 0 ? (
                            <>
                                <Text
                                    as='span'
                                    size={400}
                                    style={{ color: 'var(--primary-color)' }}
                                >
                                    No files here yet. Upload some to get started!
                                </Text>
                                <EmojiSadSlight24Regular style={{ color: 'var(--primary-color)', margin: '20px' }} />
                            </>
                        ) : (
                            <>

                                {/*full list*/}
                                <div className={styles.dataGrid}>
                                    {/* List files in a DataGrid - https://react.fluentui.dev/?path=/docs/components-datagrid--default */}
                                    <DataGrid
                                        items={getCurrentPageItems()}
                                        columns={columns}
                                        sortable
                                        size='small'
                                        defaultSortState={defaultSortState}
                                        // subtleSelection={true}
                                        // selectionAppearance='brand'
                                        getRowId={(item) => item.file.label}
                                        focusMode="cell"
                                        columnSizingOptions={columnSizingOptions}
                                        resizableColumns
                                    // onSelectionChange={(event, data) => {
                                    //     if (data) {
                                    //         // Create an array from the event data object so we can access the values
                                    //         const selectedItemsArray = Array.from(data.selectedItems);
                                    //         if (selectedItemsArray.length > 0) {
                                    //             // We need to add the index name to the filename before setting it as the selected pdf
                                    //             const fileId = selectedItemsArray[0];

                                    //             const selectedItem = fileStatusList.find(fileStatusList => fileStatusList.file.label === fileId);
                                    //             if (selectedItem) {
                                    //                 // Here we set the values from the file that is selected
                                    //                 setSelectedPdf({
                                    //                     id: selectedItem.file.label,
                                    //                     indexName: selectedItem.index_name,
                                    //                     document_id: selectedItem.documentId
                                    //                 });
                                    //             }
                                    //         }
                                    //     }
                                    // }}
                                    >
                                        {/* List Header */}
                                        <DataGridHeader>
                                            <DataGridRow
                                            // Checkbox to select all rows - changed to single select

                                            >
                                                {/* Render headers */}
                                                {({ renderHeaderCell }) => (
                                                    <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>
                                                )}
                                            </DataGridRow>
                                        </DataGridHeader>
                                        {/* Table */}
                                        <DataGridBody<DataGridItem>>
                                            {({ item, rowId }) => (
                                                <DataGridRow<DataGridItem>
                                                    key={rowId}
                                                // selectionCell={{
                                                //     checkboxIndicator: { "aria-label": "Select row" },
                                                // }}
                                                >
                                                    {({ renderCell }) => (
                                                        <DataGridCell>{renderCell(item)}</DataGridCell>
                                                    )}
                                                </DataGridRow>
                                            )}
                                        </DataGridBody>
                                    </DataGrid>
                                    <div className={styles.pagination}>
                                        <Button onClick={handlePreviousPage} disabled={currentPage === 1}>Previous</Button>
                                        <div className={styles.paginationNumbers}>
                                            <input
                                                value={currentPage}
                                                onChange={handlePageInputChange}
                                                className={styles.paginationInput}
                                            />
                                            <span>of {totalPages}</span>
                                        </div>
                                        <Button onClick={handleNextPage} disabled={currentPage === totalPages}>Next</Button>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default FileStatus;