// This MainTool.tsx

// We should only need to pass in the id of the quick tool this component handles all.

import { useAuth0 } from '@auth0/auth0-react'
import { Stack } from '@fluentui/react'
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel, Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, makeStyles, Spinner, Text } from '@fluentui/react-components'
import { AddCircle20Filled, AppsListDetailRegular, ArchiveRegular, BoxCheckmarkFilled, ChatFilled, ChatRegular, DismissRegular, EditRegular, ErrorCircle24Regular, TextBulletListSquareRegular } from '@fluentui/react-icons'
import { useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'; // to generate a new thread_id for each chat session
import { AskResponse, chatApi, ChatRequest, ChatTurn, CollectionName, getCollectionsApi, getTemplateByIdApi, PersonaTemplate, Project, updateProjectFieldApi } from '../../api'
import { AnalysisPanel, AnalysisPanelTabs } from '../AnalysisPanel'
import { Answer, AnswerError, AnswerLoading } from '../Answer'
import { CollectionManagerPopup } from '../CollectionManagerPopup'
import CollectionSelector from '../CollectionSelector/CollectionSelector'
import { QuestionInput } from '../QuestionInput'
import { UserChatMessage } from '../UserChatMessage'
import styles from './MainTool.module.css'
import { useNavigate } from 'react-router-dom'

type MainToolProps = {
    projectInfo: Project
}

const tokenLimitReached = false // TODO add the token logic

const useStyles = makeStyles({
    dialogSurface: {
        width: '75vw',  // Adjust to your desired width
        maxWidth: '75vw',
        height: '75vh', // Adjust to your desired height
        maxHeight: '75vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
});


const MainTool = ({
    projectInfo // pass in id of tool to load
}: MainToolProps) => {
    const navigate = useNavigate();
    const fuistyles = useStyles();
    const { user, getAccessTokenSilently } = useAuth0();
    const [mainTool, setMainTool] = useState<PersonaTemplate>(); // hold the tool object
    const [answers, setAnswers] = useState<[user: string, response: AskResponse, web: boolean, run_id: string][]>([]); // hold all answers
    const [followupQuestions, setFollowupQuestions] = useState<string[]>([]); // follow up questions from answer
    // const [ selectedAnswer, setSelectedAnswer ] = useState<number>(0); // selected answer
    const [isChatLoading, setIsChatLoading] = useState<boolean>(false); // chat specific loading
    const lastQuestionRef = useRef<string>("");
    const [error, setError] = useState<unknown>();
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const [images] = useState<string[]>([]); // state for array of images up to 5
    const [selectedCollections, setSelectedCollections] = useState<CollectionName[]>([])
    const [threadId] = useState(uuidv4());; // generate the uuid thread_id for each chat session
    const [isLoading, setIsLoading] = useState<boolean>(false); // loading the records
    const [openItems, setOpenItems] = useState<number[]>(answers.length > 0 ? [0] : []); // track which accordion items are open - 

    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined); // state for active analysis panel tab - uses enum
    const [activeCitation, setActiveCitation] = useState<string[]>(); // which tab in the citation panel is active
    const [showCitationPanel, setShowCitationPanel] = useState(false); //citation panel show/hide
    const [selectedAnswer, setSelectedAnswer] = useState<number>(0); // selected answer


    // State to trigger a refresh in the collection selector - true=refresh ex. setRefreshCollection(true)
    const [refreshCollection, setRefreshCollection] = useState<boolean>(false);

    // states to toggle the display of the sections
    const [hideAskAnythingSection, setHideAskAnythingSection] = useState<boolean>(false);
    const [hideDocumentCollectionSelector, setHideDocumentCollectionSelector] = useState<boolean>(false);
    const [disableDocumentCollections, setDisableDocumentCollections] = useState<boolean>(false);


    // chat specific settings
    const [ includeCompanyMemory, setIncludeCompanyMemory ] = useState<boolean>(false);
    const [ includeUserMemory, setIncludeUserMemory ] = useState<boolean>(false);
    const [ promptTemplate, setPromptTemplate ] = useState<string>(''); // prompt template text
    const [ temperature, setTemperature ] = useState<string>('0.5'); // creativity of the AI
    const [ includeWebResults, setIncludeWebResults ] = useState<boolean>(true);  // Tavily web search
    const [ followUpPrompt, setFollowUpPrompt ] = useState<string>(''); // follow up prompt
    const [ retrieveCount, setRetrieveCount ] = useState<number>(10); // fallback to 10
    const [ retrieveWebCount, setRetrieveWebCount ] = useState<number>(3); // fallback to 1
    const [ inputCharacterLimit, setInputCharacterLimit ] = useState<number>(16000); // character limit for input
    const [ disableFollowUpQuestions, setDisableFollowUpQuestions ] = useState<boolean>(false); // disable follow up questions
    const [ originalPrompt, setOriginalPrompt ] = useState<string>();
    const [ useGpt4oMini, setUseGpt4oMini] = useState<boolean>(false); // use GPT-4o-mini model

    //focus of the sections
    const [focusDocumentCollection, setFocusDocumentCollection] = useState(true);
    const [focusChatInput, setFocusChatInput] = useState(false);

    //needed to stick the summary to the side
    const [answersHeight, setAnswersHeight] = useState<number>(0);
    const answersRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const updateAnswersHeight = () => {
            if (answersRef.current) {
                setAnswersHeight(answersRef.current.offsetHeight);
                // console.log(answersRef.current.offsetHeight)
            }
        };
        // Set the height on page load
        updateAnswersHeight();

        // Set the height on window resize
        window.addEventListener('resize', updateAnswersHeight);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', updateAnswersHeight);
        };
    });

    // 1. we are passing in the tool id only so we first need to fetch the whole record from the db
    const getToolRecord = async (id: string) => {
        setIsLoading(true);
        const accessToken = await getAccessTokenSilently();
        try {
            const response = await getTemplateByIdApi(id, accessToken);
            setMainTool(response); // hold the whole tool object
        } catch (error) {
            console.error("Error fetching template:", error);
        } finally {
            setIsLoading(false);
        }
    };

    // Get collections
    const fetchCollections = async () => {
        const access_token = await getAccessTokenSilently();
        const allCollections = await getCollectionsApi(access_token); // Assume this returns an array of objects with collection_name
        const savedCollections = projectInfo.project_collections; // get from record
        try {
            const parsedCollections = selectedCollections ? JSON.parse(savedCollections) : [];
            // Filter out collections that no longer exist
            const validCollections = parsedCollections.filter((collection: { collection_name: string; }) =>
                allCollections ? allCollections.some((allCollection) => allCollection.collection_name === collection.collection_name) : false
            );
            setSelectedCollections(validCollections);
        } catch (error) {
            console.error("Failed to parse selectedCollections as JSON: ", error);
        }
    };

    //update record with document collections
    useEffect(() => {
        const updateCollectionsAsync = async () => {
            const accessToken = await getAccessTokenSilently();
            await updateProjectFieldApi(projectInfo.id, "project_collections", JSON.stringify(selectedCollections), accessToken);
        };
        updateCollectionsAsync();
    }, [ selectedCollections ]);


    //load project settings
    useEffect(() => {
        const fetchData = async () => {
            try {
                await getToolRecord(projectInfo.project_tool_id);  // Await for getToolRecord
                if (projectInfo.project_collections != '') {
                    await fetchCollections();  // Await for fetchCollections
                }
                setOriginalPrompt(projectInfo.project_name);
                // if (projectInfo.project_collections) {
                //     setFocusDocumentCollection(false);
                // }
                // if (projectInfo.prompts) {
                //     setFocusChatInput(false);

                // } else {
                //     setFocusChatInput(true)
                // }
            } catch (error) {
                console.error("An error occurred while fetching data:", error);
            } finally {
                if (projectInfo.prompts != '') {
                    const parsedPrompts = JSON.parse(projectInfo.prompts);
                    setAnswers(parsedPrompts);
                }
            }
        };
        fetchData();  // Call the function
    }, []);  // Add projectInfo as a dependency if it may change


    // 2. Now that we have the record we need to set the states when mainTool changes.
    useEffect(() => {
        // these toggle the sections
        setHideAskAnythingSection(mainTool?.disable_ask_anything === 'true'); // section toggle
        setHideDocumentCollectionSelector(mainTool?.disable_document_collections === 'true'); // section toggle
        setFocusChatInput(mainTool?.disable_document_collections === 'true')//if there is no document collection needed, focus the chat
        setDisableDocumentCollections(mainTool?.disable_document_collections === 'true'); // for the call to the ai

        // chat specific settings
        setIncludeCompanyMemory(mainTool?.include_company_memory === 'true');
        setIncludeUserMemory(mainTool?.include_user_memory === 'true');
        setPromptTemplate(mainTool?.text ?? '');
        setFollowUpPrompt(mainTool?.follow_up_prompt ?? '');
        setRetrieveCount(Number(mainTool?.top_k ?? 1));
        setRetrieveWebCount(Number(mainTool?.web_top_k ?? 1));
        setTemperature(mainTool?.temperature ?? '0.5');
        setIncludeWebResults(mainTool?.include_web_results === 'true');
        setInputCharacterLimit(mainTool?.input_character_limit ?? 16000);
        setDisableFollowUpQuestions(mainTool?.disable_follow_up_questions === 'true');
        setUseGpt4oMini(mainTool?.use_gpt4o_mini === 'true');
    }, [ mainTool ]); // we add mainTool to this dependency array to trigger this any time mainTool changes.


    // function to make api request
    const makeApiRequest = async (question: string, original: boolean) => {
        lastQuestionRef.current = question; // save question as ref
        // clear fields
        if (error) setError(undefined);
        setIsChatLoading(true);
        setActiveAnalysisPanelTab(undefined); // close the panel if open
        //  setOpenItems([]); // close the question accordion

        if (original) {
            setOriginalPrompt(question);
            setFocusDocumentCollection(false);
            setFocusChatInput(false);
            const accessToken = await getAccessTokenSilently();
            updateProjectFieldApi(projectInfo.id, "project_name", question, accessToken);

            // Clear the answers state
            setAnswers([]);

            // If you need to ensure the answers state is cleared before proceeding
            // we can handle the state clearing effect like this:
            setAnswers(prevAnswers => {
                //    console.log('cleared?: ', prevAnswers); // This will log the empty array
                return prevAnswers;
            });
        }

        try {
            // build history
            const history: ChatTurn[] = answers.map(a => ({
                user: [{ type: 'text', text: a[0] }],
                bot: a[1].answer
            }));

            // build image messages
            const imageMessages = images.map(url => ({
                type: 'image_url' as const, image_url: { url }
            }));

            // build request with messages
            const request: ChatRequest = {
                history: [
                    ...history,
                    {
                        user: [
                            { type: 'text', text: question },
                            ...imageMessages
                        ],
                        bot: undefined
                    }
                ],
                selectedIndexes: disableDocumentCollections ? [] : selectedCollections.map(collection => collection.collection_name),
                user: String(user?.email ?? "unknown"), // send user email if available
                threadId: String(threadId), // a uuid thread_id for each chat session
                companyMemory: Boolean(includeCompanyMemory), // boolean for company memory
                userMemory: Boolean(includeUserMemory), // boolean for user memory
                search_only_mode: Boolean(false), // Boolean(searchOnlyMode), // boolean for search only mode
                // Overrides are passed to the approach
                overrides: {
                    promptTemplate: String(promptTemplate.length === 0 ? undefined : promptTemplate),
                    followUpQuestionPrompt: String(followUpPrompt.length === 0 ? undefined : followUpPrompt),
                    top: Number(retrieveCount),
                    retrieveWebCount: Number(retrieveWebCount), // number of web results to return
                    disableFollowupQuestions: Boolean(disableFollowUpQuestions),
                    temperature: Number(temperature), // convert string to number
                    webResults: Boolean(includeWebResults),
                    useGpt4oMini: Boolean(useGpt4oMini)
                }
            };
            const token = await getAccessTokenSilently(); // get token to pass to api
            const result = await chatApi(request, token); // call api and pass token

            // extract the run_id from the response
            const run_id = result.run_id;
            // Add response to array of answers
            setAnswers(prevAnswers => [...prevAnswers, [question, result, includeWebResults, run_id]]);
        } catch (e) {
            setError(e);
        } finally {
            setIsChatLoading(false);
            scrollToAnswer();
        }
    };



    // set the last accordion answer item to be opened & update record
    useEffect(() => {
        const updatePromptsAsync = async () => {
            const accessToken = await getAccessTokenSilently();
            await updateProjectFieldApi(projectInfo.id, 'prompts', JSON.stringify(answers), accessToken);
    
            if (answers.length > 0) {
                setOpenItems([answers.length - 1]); // first in list
            }
        };
    
        updatePromptsAsync();
    }, [answers]); // <- answers dependency will trigger when answers changes

    // handle when a user clicks the other accordions
    const handleAccordionClick = (index: number) => {
        setOpenItems(prevOpenItems => {
            if (prevOpenItems.includes(index)) {
                return prevOpenItems.filter(item => item !== index);
            } else {
                return [...prevOpenItems, index];
            }
        });
    };

    //scroll to the last generated answer
    const lastHeaderRef = useRef<HTMLDivElement | null>(null);

    const scrollToAnswer = () => {
        if (lastHeaderRef.current) {
            //  console.log("SCROLL");
            lastHeaderRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    const scrollToID = (elementId: string) => {
        if (elementId === "top") {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
        if (elementId === "bottom") {
            window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
        } else {
            const element = document.getElementById(elementId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    };

    // handle showing/hiding the citation panel
    const onShowCitation = (citation: string, index: number, documentID: string, sourceName: string) => {

        if (activeCitation?.[0] === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
            setShowCitationPanel(false);
        } else {
            setActiveCitation([citation, documentID, sourceName]);
            setShowCitationPanel(true); // if not expanded, this will expand the panel to display the citation
            console.log(activeCitation);
        }
        setSelectedAnswer(index);
        if (!showCitationPanel) {
            showHideCitationPanel();
        }
    };

    // Toggle citation panel tab
    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            // setActiveAnalysisPanelTab(tab);
            setActiveAnalysisPanelTab(tab);
        }
        setSelectedAnswer(index);
        if (!showCitationPanel) {
            showHideCitationPanel();
        }
    };

    //open and close the citation panel
    const showHideCitationPanel = () => {
        if (showCitationPanel) {
            setShowCitationPanel(false);
        } else {
            setShowCitationPanel(true);
        }
    }

    //button to edit document collections
    const handleChangeDocumentCollections = () => {
        setFocusDocumentCollection(true);
        setFocusChatInput(false);
        scrollToID('top')

    }

    //button to edit original query
    const handleChangeOriginalQuery = () => {
        setFocusDocumentCollection(false);
        setFocusChatInput(true);
        scrollToID('OriginalQuery')

    }

    //button to confirm selected collections
    const handleConfirmDocumentCollections = () => {
        setFocusDocumentCollection(false);
        if (!originalPrompt) {
            setFocusChatInput(true);
        }
    }

    //button to confirm selected collections & re run question
    const handleConfirmDocumentCollectionsAndReRun = () => {
        makeApiRequest(lastQuestionRef.current, false)
        setFocusDocumentCollection(false);
        setFocusChatInput(false);
        scrollToID('loadingAnswer');
    }

    if (isLoading) {
        return <div><Spinner /></div>;
    }

    return (
        <div>
            <div className={styles.sectionFull}>
                {/* Document collection section */}
                {!hideDocumentCollectionSelector ?
                    <div className="ms-Grid" id='DocumentCollections'>
                        {focusDocumentCollection ?
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-lg4" >
                                    <Text className={styles.sectionNumber} style={{ color: mainTool?.color }}>1</Text>
                                    <h2 className={styles.gizmoComponentName} style={{ color: mainTool?.color }}><ArchiveRegular /> Document Collections</h2>
                                    {/* Render the document collections description or a default */}
                                    <div className={styles.gizmoComponentDescription}>
                                        {mainTool?.document_collections_description ? (
                                            <div dangerouslySetInnerHTML={{ __html: mainTool.document_collections_description }} />
                                        ) : (
                                            <>
                                                <p>
                                                    Document Collections are "folders" of documents that Blue Edge AI will reference to generate your results.
                                                </p>
                                                <br />
                                                <p>
                                                    To get the best output, you should select the most relevant Document Collections for the AI to reference, too many and you will dilute your results.
                                                </p>
                                            </>
                                        )}
                                    </div>

                                    <br />
                                    <br />

                                    <CollectionManagerPopup
                                        setRefreshCollection={setRefreshCollection}
                                        refreshCollection={refreshCollection}
                                    />
                                </div>

                                <div className="ms-Grid-col ms-lg8">
                                    <div className={styles.collectionSelectorContainer}>
                                        <CollectionSelector
                                            selectedCollections={selectedCollections}
                                            setSelectedCollections={setSelectedCollections}
                                            refreshCollection={refreshCollection}
                                            setRefreshCollection={setRefreshCollection}
                                        />
                                    </div>

                                    <div className={styles.confirmButtonsContainer}>

                                        {lastQuestionRef.current ? <Button icon={<BoxCheckmarkFilled />} appearance='primary' disabled={selectedCollections.length == 0} onClick={() => handleConfirmDocumentCollectionsAndReRun()} className={styles.confirmButton}>Confirm Selection & Re-ask last query</Button> : ''}

                                        <Button icon={<BoxCheckmarkFilled />} appearance={lastQuestionRef.current ? 'secondary' : 'primary'} disabled={selectedCollections.length == 0} onClick={() => handleConfirmDocumentCollections()} className={styles.confirmButton}>Confirm Selection</Button>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-lg4" >
                                    <Text className={styles.sectionNumber} style={{ color: mainTool?.color }}>1</Text>

                                    <h2 className={styles.gizmoComponentName} style={{ color: mainTool?.color }}><ArchiveRegular /> Document Collections</h2>

                                </div>
                                <div className="ms-Grid-col ms-lg8">
                                    {/* List selected Collections */}
                                    <div className={styles.summaryContainer}>
                                        <h3 className={styles.summaryHeading}>Selected Collections:</h3>
                                        {/* List selected */}
                                        <ol>
                                            {selectedCollections!.map((collection, index) => (
                                                <li key={index}>{collection.display_name}</li>
                                            ))}
                                        </ol>
                                    </div>
                                    <Button appearance='subtle' className={styles.changeButton} onClick={() => handleChangeDocumentCollections()} icon={<EditRegular />}>Change Document Collections</Button>
                                </div>
                            </div>
                        }
                        <div className={styles.spacer} />
                        <hr />
                        <div className={styles.spacer} />
                    </div>
                    : ''
                }

                {/* We can list out the sub components below with simple toggles to display each one*/}
                <div className="ms-Grid" id='OriginalQuery' style={{ scrollMarginTop: "200px" }}>
                    {focusChatInput ?
                        <>
                            {/* In Less than X words section */}
                            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {!hideInXWordsSection ? (
                                    <InLessThan
                                        setInLessThanXWords={setInLessThanXWords}
                                        setPrefix={setPrefix}
                                        inLessThanXWords={inLessThanXWords}
                                    />
                                ) : ''}
                            </div> */}

                            {/* Example next component */}
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                {/* {!hideNextComponent ? ( // toggle 
                            <PlaceholderComponent
                            // pass in only what is absolutely needed for this component
                            prop1={value1}
                            prop2={value2}
                            />
                            ) : ''} */}
                            </div>

                            <div className="ms-Grid-row" >
                                {/* left column */}

                                {!hideAskAnythingSection ? (

                                    <div className="ms-Grid-col ms-lg4">
                                        <Text className={styles.sectionNumber} style={{ color: mainTool?.color }}>{hideDocumentCollectionSelector ? '1' : '2'}</Text>

                                        <h2 className={styles.gizmoComponentName} style={{ color: mainTool?.color }}><ChatRegular /> Your Query</h2>
                                        <div className={styles.gizmoComponentDescription}>
                                            {mainTool?.ask_anything_description ? (
                                                <div dangerouslySetInnerHTML={{ __html: mainTool.ask_anything_description }} />
                                            ) : (
                                                <>
                                                    <p>Now it's time to ask the AI what you'd like to do. The best prompts for the AI have as much context as possible.</p>
                                                    <br />
                                                    <p>Provide detailed information and be specific to get the most accurate and helpful responses.</p>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                ) : ''}

                                {/* Right side */}
                                {!hideAskAnythingSection ? (
                                    <div className="ms-Grid-col ms-lg8">
                                        <div className={styles.chatInputContainer}>
                                            {tokenLimitReached ?
                                                <div className={styles.tokenLimitReached}>
                                                    <ErrorCircle24Regular />

                                                    <p>You have used your credit allowance.</p>

                                                    <br />

                                                    <p>Please contact your Super Admin to resolve this issue.</p>
                                                </div> : (
                                                    <>
                                                        {originalPrompt ?
                                                            <Button onClick={() => setFocusChatInput(false)}>Cancel Change of Query</Button>
                                                            : ''}
                                                        {/*text input*/}
                                                        <div className={styles.chatInput}>
                                                            <QuestionInput
                                                                clearOnSend
                                                                placeholder={"Ask here..."}
                                                                disabled={isLoading}
                                                                onSend={question => makeApiRequest(question, true)}
                                                                setInputLength={() => { }} // sets the length of text input
                                                                maxInputLength={inputCharacterLimit}
                                                                originalPrompt={originalPrompt} // optional pass in default prompt
                                                            />
                                                        </div>
                                                    </>
                                                )}
                                        </div>

                                    </div>
                                ) : ''}
                            </div>
                        </>
                        :
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-lg4" >
                                <Text className={styles.sectionNumber} style={{ color: mainTool?.color }}>{hideDocumentCollectionSelector ? '1' : '2'}</Text>

                                <h2 className={styles.gizmoComponentName} style={{ color: mainTool?.color }}><ChatRegular /> Your Query</h2>

                            </div>
                            <div className="ms-Grid-col ms-lg8">
                                <div >
                                    {selectedCollections.length > 0 || hideDocumentCollectionSelector ? (
                                        <>
                                            <div className={styles.summaryContainer}>
                                                <h3 className={styles.summaryHeading}>Original Query:</h3>
                                                <p className={styles.originalPrompt}>{originalPrompt}</p>
                                            </div>
                                            <Button appearance='subtle' className={styles.changeButton} onClick={() => handleChangeOriginalQuery()} icon={<EditRegular />}>Change Original Query (this will remove existing results)</Button>

                                        </>
                                    ) : (
                                        <p>You must select relevant Document Collections first.</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    }
                </div>

                <div className={styles.spacer} />
                <hr />
                <div className={styles.spacer} />

                {/* Results area*/}
                <div className="ms-Grid">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-lg4" style={{ height: answersHeight + 'px' }}>
                            <Text className={styles.sectionNumber} style={{ color: mainTool?.color }}>{hideDocumentCollectionSelector ? '2' : '3'}</Text>

                            <h2 className={styles.gizmoComponentName} style={{ color: mainTool?.color }}><AppsListDetailRegular /> Results</h2>
                            <p className={styles.gizmoComponentDescription}>Your results will appear here and you can ask follow up questions below.</p>

                            {/* Summary bar on the side */}
                            {answers.length != 0 ?
                                <div className={styles.resultsSummaryContainer}>
                                    <h2><TextBulletListSquareRegular /> Query Summary</h2>
                                    <p>Generated results: {answers.length}</p>
                                    <h3 className={styles.gizmoComponentSubheading}>Selected Collections</h3>
                                    {/* List selected */}
                                    <ol>
                                        {selectedCollections!.map((collection, index) => (
                                            <li key={index}>{collection.display_name}</li>
                                        ))}
                                    </ol>
                                    <Button appearance='subtle' className={styles.changeButton} onClick={() => handleChangeDocumentCollections()} icon={<EditRegular />}>Change Document Collections</Button>
                                    <h3 className={styles.gizmoComponentSubheading}>Original Query</h3>
                                    <p className={styles.originalPrompt}>{originalPrompt}</p>
                                    <Button appearance='subtle' className={styles.changeButton} onClick={() => handleChangeOriginalQuery()} icon={<EditRegular />}>Change Original Query (this will remove existing results)</Button>
                                    <div className={styles.spacer} />
                                    <Button appearance='primary' icon={<AddCircle20Filled />} onClick={() => navigate('/hello')}>
                                        Start a new query
                                    </Button>
                                </div>
                                : ''
                            }
                        </div>


                        <div className="ms-Grid-col ms-lg8" ref={answersRef}>
                            {answers.length != 0 || isChatLoading ?
                                <div className={styles.chatPanel}>
                                    <div className={styles.chatMessageStream}>
                                        <Accordion
                                            openItems={openItems}
                                            defaultOpenItems={openItems} // always expand the first item if answers are present
                                            collapsible={false}
                                        >
                                            {answers.slice().map((answer, index) => (
                                                <AccordionItem key={index} value={index}>
                                                    <AccordionHeader
                                                        as='div'
                                                        ref={index === answers.length - 1 ? lastHeaderRef : null} // Assign ref to the last header
                                                        expandIcon={openItems.includes(index) ? <ChatFilled style={{ color: 'var(--primary-color)' }} /> : <ChatRegular />}
                                                        onClick={() => handleAccordionClick(index)} className={styles.answerHeader}
                                                        style={openItems.includes(index) ? { backgroundColor: '#e5f2f4' } : {}}
                                                    >
                                                        <span className={styles.answerHeaderText}>{answer[0]}</span>
                                                    </AccordionHeader>
                                                    <AccordionPanel>
                                                        <div className={styles.chatMessageGpt}>
                                                            {/* Answer */}
                                                            <Answer
                                                                key={index}
                                                                answer={answer[1]}
                                                                isSelected={true} // boolean
                                                                onCitationClicked={(filePath, documentID, sourceName) => onShowCitation(filePath, index, documentID, sourceName)}
                                                                onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                                onSupportingContentClicked={() => { }}
                                                                onFollowupQuestionClicked={q => makeApiRequest(q, false)}
                                                                selectedIndexes={selectedCollections} // array of strings of id's of collections
                                                                settingsButtonVisible={true} // boolean
                                                                webResultsIncluded={false} // boolean
                                                                showFollowupQuestions={true} // boolean
                                                                exportFollowupQuestions={setFollowupQuestions}
                                                            />
                                                        </div>
                                                    </AccordionPanel>
                                                </AccordionItem>
                                            ))}
                                        </Accordion>
                                        <div id='loadingAnswer' style={{ scrollMarginTop: "700px" }} />
                                        {/* answer loading indicator */}
                                        {isChatLoading && (
                                            <>
                                                <div className={styles.answerHeaderLoading} >
                                                    <span className={styles.answerHeaderText}>{lastQuestionRef.current}</span>

                                                </div>

                                                <div className={styles.chatMessageGptMinWidth}>
                                                    <AnswerLoading />
                                                </div>
                                            </>
                                        )}
                                        {error ? (
                                            // Error Message
                                            <>
                                                <UserChatMessage message={lastQuestionRef.current} />
                                                <div className={styles.chatMessageGptMinWidth}>
                                                    <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current, false)} />
                                                </div>
                                            </>
                                        ) : null}
                                        <div ref={chatMessageStreamEnd} />
                                        {/* Follow up questions */}
                                        {answers.length > 0 && (
                                            <>
                                                <h2 className={styles.gizmoComponentSubheading}>Follow-up Questions</h2>
                                                {/* Follow up questions */}
                                                {followupQuestions && !isChatLoading ? (
                                                    <>
                                                        <h3 className={styles.subHeader}>Suggested Follow-up questions:</h3>
                                                        <Stack.Item>
                                                            <Stack horizontal className={styles.followupQuestionsList} wrap tokens={{ childrenGap: 6 }}>
                                                                {followupQuestions.map((x, i) => {
                                                                    return (
                                                                        <a key={i} className={styles.followupQuestion} title={x} onClick={() => makeApiRequest(x, false)}>
                                                                            {`${x}`}
                                                                        </a>
                                                                    );
                                                                })}
                                                            </Stack>
                                                        </Stack.Item>
                                                    </>
                                                ) : ''}
                                                <QuestionInput
                                                    clearOnSend
                                                    placeholder={"Ask here..."}
                                                    disabled={isChatLoading}
                                                    onSend={question => makeApiRequest(question, false)}
                                                    setInputLength={() => { }} // pass setter for state to track number of characters
                                                    maxInputLength={16000}
                                                    originalPrompt='' // optional pass in default prompt
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>
                                :
                                "No results to show yet"
                            }
                        </div>
                    </div>
                </div>



                {/* <NavLink to="/hello">
                    <Button icon={<SaveRegular />} appearance='primary'>Save and Close</Button>
                </NavLink> */}
            </div>




            <div >
                <Dialog
                    open={showCitationPanel}
                    inertTrapFocus={true}
                >
                    <DialogSurface className={fuistyles.dialogSurface}>
                        <DialogBody>
                            <DialogTitle>
                                Source Analyser
                                <DialogActions>
                                    <DialogTrigger disableButtonEnhancement>
                                        <Button className={styles.sourceCloseBtn} appearance="primary" icon={<DismissRegular />} onClick={showHideCitationPanel}></Button>
                                    </DialogTrigger>
                                </DialogActions>
                            </DialogTitle>
                            <DialogContent>
                                {answers.length > 0 ? (
                                    <AnalysisPanel
                                        className={styles.chatAnalysisPanel}
                                        activeCitation={activeCitation}
                                        onActiveTabChanged={x => onToggleTab(x, selectedAnswer)}
                                        answer={answers[ selectedAnswer ][ 1 ]}
                                        settingsButtonVisible={true}
                                    />
                                ) : (<p>Citations will be displayed here.</p>)}
                            </DialogContent>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>
            </div>
        </div >
    )
}

export default MainTool