// This component will have the upload button to select files, a react drop zone, then an area to view progress of each file.
import { useAuth0 } from '@auth0/auth0-react';
import { List } from '@fluentui/react';
import { Divider, Spinner, Text, Button, Dialog, DialogSurface, DialogTitle, DialogContent, DialogActions, ProgressBar, Field, Toaster, useToastController, Toast, ToastTitle, ToastBody, useId, ToastIntent } from '@fluentui/react-components';
import { CloudArrowUp24Regular, CloudCheckmark24Regular, InfoRegular } from '@fluentui/react-icons';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadFileApi } from '../../api';
import { IUploadResponse } from '../../api/models';
import styles from './UploadArea.module.css';
import UploadUrl from '../UploadUrl/UploadUrl';

type Props = {
    indexName: string;
    setRefreshGrid: React.Dispatch<React.SetStateAction<boolean>>; // true = trigger refresh in file status list
    setHasUpload: React.Dispatch<React.SetStateAction<boolean>>;
}


const UploadArea = ({ indexName, setRefreshGrid, setHasUpload }: Props) => {
    const { getAccessTokenSilently } = useAuth0();
    const [open, setOpen] = React.useState(false); // file upload progress dialog
    const [uploadComplete, isUploadComplete] = React.useState(false); // show the close button once all files are done
    const [files, setFiles] = React.useState<File[]>([]);// state to store the list of files to be uploaded
    const [uploadProgress, setUploadProgress] = useState<Map<string, number>>(new Map());// state to store the progress of each file in a map    
    const [uploadedDocsNumber, setUploadedDocsNumber] = useState(0)//uploaded docs number - count
    const [dropErrorMessage, setDropErrorMessage] = useState<string[]>([]);// error message for file drop
    const [uploadResponses, setUploadResponses] = useState<Map<string, { message: string, documentId: string, index: number }>>(new Map());
    const [displayUploadInfo, setDisplayUploadInfo] = useState(false);
    const [showUploadList, setShowUploadList] = useState(false);
    const [isRejectedDialogOpen, setIsRejectedDialogOpen] = useState(false); // rejected files dialog


    const toasterId = useId("toaster");
    const { dispatchToast } = useToastController(toasterId);

    // Define a notify to display the Toast messages
    const notify = (
        title: string = 'Success!', // default
        body: string = 'The operation was successful.',
        message_intent: ToastIntent = "success"
    ) =>
        dispatchToast(
            // Use the Toast component to display a message
            <Toast
                as='div'
                appearance='inverted' // dark mode
                key={undefined}
            >
                <ToastTitle>{title}</ToastTitle>
                <ToastBody><Text>{body}</Text></ToastBody>
            </Toast>,
            { intent: message_intent }
        );



    // Open the dialog if there are error messages
    useEffect(() => {
        if (dropErrorMessage && dropErrorMessage.length > 0) {
            setIsRejectedDialogOpen(true);
            console.log('Open dialog')
        }
    }, [dropErrorMessage]); // This effect depends on dropErrorMessage

    const toggleDialog = () => {
        setIsRejectedDialogOpen(false);
        setDropErrorMessage([]); // Clear error messages when closing the dialog
    };

    // show close button when upload is complete
    function activateUploadCloseBtn() {
        isUploadComplete(true);
        setHasUpload(true);
    }

    // clear the files and upload progress when the component is unmounted
    useEffect(() => {
        // This function will be called when the component is unmounted
        return () => {
            setFiles([]);
            setUploadProgress(new Map());
            setUploadResponses(new Map());
        };
    }, []);



    // function to handle the file upload
    const onDrop = async (acceptedFiles: File[]) => {
        const token = await getAccessTokenSilently() // get the access token
        let counter = 1; // counter for number of uploaded files
        isUploadComplete(false) // start the loading spinner
        setShowUploadList(true)
        setHasUpload(true);
        setOpen(true); // open the dialog
        setFiles([...acceptedFiles]);// add the files to the list
        setUploadedDocsNumber(counter);//reset upload progress number
        const initialUploadProgress = new Map();// initialize the upload progress for each file
        acceptedFiles.forEach(file => initialUploadProgress.set(file.name, 0)); // start progress at 0
        setUploadProgress(initialUploadProgress); // set the initial upload progress (0)


        // loop through the files and upload them - need to be individual for percentages to work
        const uploadPromises = acceptedFiles.map(async (file) => {
            try {
                const formData = new FormData();
                formData.append('files', file);
                const request: FormData = formData;
                const response: IUploadResponse = await uploadFileApi(request, indexName, token, (progressEvent) => {
                    if (progressEvent.total) {
                        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        // Adjust the percent to 90 if it reaches 100 before getting the documentId
                        percentCompleted = percentCompleted >= 100 ? 90 : percentCompleted;
                        setUploadProgress(prev => new Map(prev).set(file.name, percentCompleted));
                    }
                });

                // Assuming the response contains the documentId at this point
                const responseObj = JSON.parse(response.success);
                const responseDetails = {
                    message: responseObj.message,
                    documentId: responseObj.documentId,
                    index: responseObj.index
                };

                // Now that we have the documentId, set the progress to 100%
                // file deepcode ignore CollectionUpdatedButNeverQueried: <please specify a reason of ignoring this>
                setUploadProgress(prev => new Map(prev).set(file.name, 100));
                setUploadedDocsNumber(counter++);
                setUploadResponses(prev => new Map(prev.set(file.name, responseDetails)));
            } catch (error) {
                console.error(`Error uploading file ${file.name}: `, error);
                //  setOpen(false); 
                //  alert(`Error uploading file, Please try again.  ${file.name}: ${error instanceof Error ? error.message : 'Unknown error'}`);
            }
        });

        Promise.all(uploadPromises)
            .then(() => setRefreshGrid(true))
            .then(() => notify(
                `${counter - 1} File(s) Uploaded Successfully`, // Title
                'Your Files are now processing. Please allow a few minutes before the documents will be available to use.', // Body
                'success' // Message intent
            ))
            .catch(error => console.error('Error uploading files: ', error));

        //This line waits for all the promises in the uploadPromises array to resolve. Promise.all takes an array of promises and returns a new promise that only resolves when all the promises in the array have resolved.
        Promise.all(uploadPromises)
            // once all files have resolved:
            .then(() => setRefreshGrid(true)) // refresh the grid to show the updated list of files
            .then(() => activateUploadCloseBtn()) // show close button
            .catch(error => console.error('Error uploading files: ', error)); // If any of the promises in the uploadPromises array are rejected (i.e., an error occurs during file upload), the error is logged to the console.
    };

    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        // Accept only '.jpeg', '.jpg', '.png','gif', 'pdf','bmp', 'tiff', 'heif', 'docx', 'xlsx', 'pptx', 'html'
        accept: {
            'application/pdf': ['.pdf'],
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png'],
            'image/gif': ['.gif'],
            'image/bmp': ['.bmp'],
            'image/tiff': ['.tiff'],
            'image/heif': ['.heif'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
            'text/html': ['.html'],
        },
        maxSize: 30 * 1024 * 1024, // 30MB
        minSize: 1024, // 1kb min size - if 0kb it errors out.
        onDrop,
        onDropRejected: (fileRejections) => {
            // Initialize an array to hold messages for each rejected file
            const rejectionMessages = fileRejections.map(rejection => {
                // Get the file name
                const fileName = rejection.file.name;
                // Map each error to a readable message, we can customize these messages as needed
                const errorMessages = rejection.errors.map(error => {
                    switch (error.code) {
                        case 'file-too-large':
                            return `${fileName} is too large. Maximum size is 30MB.`;
                        case 'file-too-small':
                            return `${fileName} is reading as 0kb. It may be corrupt.`;
                        case 'file-invalid-type':
                            return `${fileName} is Not a supported file type.`;
                        // Add more cases as needed for different error codes
                        default:
                            return `File ${fileName} was rejected.`;
                    }
                }).join(' '); // Join messages for a single file if there are multiple errors

                return errorMessages;
            });

            // Set the detailed error messages as an array
            setDropErrorMessage(rejectionMessages);
        }
    });

    // function to clear the list of uploaded files
    useEffect(() => {
        if (indexName) {
            ClearUploadList();
        }
    }, [indexName]);

    const ClearUploadList = () => {
        setFiles([]);
        setUploadProgress(new Map());
        setUploadResponses(new Map());
        setHasUpload(false);
        setShowUploadList(false);
    }




    return (
        <div className={styles.container}>
            {/* Toaster */}
            <Toaster
                toasterId={toasterId} // toasterId allows multiple to be sent
                position='top' // position of the toaster on the screen
            />

            {/* conditionally render if indexName is filled */}
            {indexName ? (
                <>
                    <div className={styles.UploadArea}>
                        <h4>File Upload</h4>
                        <Field hint="When uploading files to the AI, please be aware that larger files and complex PDFs will take longer to process. Please remove any large CAD or Vector files from the PDF before uploading." size='medium'>
                            <div
                                {...getRootProps()}
                                style={{
                                    flexBasis: '70%',
                                    border: isDragReject ? '2px dashed red' : isDragActive ? '2px dashed #0c7479' : '2px dashed #c8c8c8'
                                }}
                                className={styles.dropzone}
                            >
                                <input {...getInputProps()} multiple />
                                <CloudArrowUp24Regular /> Click to select files or drop them here (max file size 30MB)
                                {/* {isDragActive && !isDragReject && <p>Drop the files in here ...</p>} */}
                                {isDragReject && <p style={{ color: 'red' }}>Warning! Some files will be rejected...</p>}
                            </div>
                        </Field>
                        {/* Upload URL */}
                        <h4>Webpage Reference</h4>
                        <Field hint={<p>Blue Edge AI can reference content of specific web pages.
                            <br></br>Note: This will online include Text on this single page. No images, files or linked pages will be added.</p>}>
                            <UploadUrl index_name={indexName} />
                        </Field>
                    </div>

                    {/* Map out each file */}
                    < div className={styles.uploadDisplayContainer}>
                        <div>
                            <div>
                                {/* Rejection list */}
                                {dropErrorMessage && dropErrorMessage.length > 0 && (
                                    <Dialog
                                        open={isRejectedDialogOpen}
                                        onOpenChange={toggleDialog}
                                    >
                                        <DialogSurface>
                                            <DialogTitle className={styles.errorHeader}>
                                                <Text
                                                    size={500}
                                                    className={styles.errorHeader}
                                                >
                                                    Attention!
                                                </Text>
                                            </DialogTitle>
                                            <DialogContent>
                                                <div className={styles.errorContainer}>
                                                    <List
                                                        className={styles.error}
                                                        items={dropErrorMessage}
                                                        onRenderCell={(item, index) => (
                                                            <div key={index} style={{ paddingLeft: '10px', textIndent: '-10px' }}>
                                                                • {item}
                                                            </div>
                                                        )}
                                                    />
                                                    <br />
                                                    <Text as='p' align='center'>Please Note: Only the following types are currently supported: <br /> .jpeg, .jpg, .png, .gif, .pdf, .bmp, .tiff, .heif, .docx, .xlsx, .pptx, .html</Text>
                                                    <Divider />
                                                </div>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button
                                                    appearance='outline'
                                                    onClick={toggleDialog}>Close</Button>
                                            </DialogActions>
                                        </DialogSurface>
                                    </Dialog>
                                )}
                            </div>
                            {showUploadList ?
                                <>
                                    <div>
                                        <h3 className={styles.subHeader}>{!uploadComplete ? "Uploading" : "Uploaded"} {uploadedDocsNumber} / {files.length}</h3>
                                    </div>

                                    <ol>
                                        {/* Map out files */}
                                        {files.map(file => (
                                            <li key={file.name}>
                                                <div className={styles.fileName} title={file.name}>{file.name}</div>
                                                {/* show progress bar */}
                                                {(uploadProgress.get(file.name) ?? 0) < 100 && (
                                                    <ProgressBar
                                                        className={styles.progressBar}
                                                        value={uploadProgress.get(file.name) || 0}
                                                        max={100}
                                                    />
                                                )}

                                                {uploadProgress.get(file.name) === 100 ? (
                                                    <>
                                                        <CloudCheckmark24Regular className={styles.statusIcon} />
                                                    </>
                                                ) : (
                                                    <Spinner size='extra-small' as='div' className={styles.statusIcon} />
                                                )}
                                            </li>
                                        ))}
                                    </ol>
                                    {dropErrorMessage.length > 0 ? <>
                                        <h3 className={styles.subHeader}>Failed to Upload</h3>
                                        <p>The following files failed to upload, please try again. If they continue to fail, please contact support</p>
                                        <br></br>

                                        <List
                                            className={styles.error}
                                            items={dropErrorMessage}
                                            onRenderCell={(item, index) => (
                                                <div key={index} style={{ paddingLeft: '10px', textIndent: '-10px' }}>
                                                    • {item}
                                                </div>

                                            )}
                                        /> </> : ''}



                                    {/* Display the response message */}
                                    <Button appearance='subtle' onClick={() => setDisplayUploadInfo(displayUploadInfo ? false : true)} icon={<InfoRegular />} />
                                    {displayUploadInfo &&
                                        <div>
                                            {Array.from(uploadResponses.entries()).map(([fileName, responseDetails]) => (
                                                <div key={fileName}>
                                                    <strong>{fileName}:</strong> {responseDetails.message}
                                                    <br />
                                                    <strong>Document ID:</strong> {responseDetails.documentId}
                                                    <br />
                                                    <strong>Collection:</strong> {responseDetails.index}
                                                </div>
                                            ))}
                                        </div>
                                    }
                                </>
                                :
                                ''
                            }
                        </div>

                    </div>

                </>) : (
                <p>Please select a collection to start</p>
            )
            }
        </div >
    );
};

export default UploadArea