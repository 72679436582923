/* eslint-disable @typescript-eslint/no-unused-vars */
// This component will display the terms of service for the app.
// The TOS is 30 pages, so with performance in mind we will load the text from a file.

// tosmd.md is in /public
import { useAuth0 } from "@auth0/auth0-react";
import { Accordion, AccordionHeader, AccordionItem, AccordionPanel, Button, Slider, } from '@fluentui/react-components';
import { ErrorCircle24Regular, SaveRegular } from '@fluentui/react-icons';
import React, { useEffect, useRef, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Approaches, AskResponse, chatApi, ChatRequest, ChatTurn, CollectionName, getTokensSinceResetApi, PersonaTemplate, Project, RetrievalMode, updateProjectFieldApi } from "../../api";
import { AnalysisPanelTabs } from "../../components/AnalysisPanel";
import { Answer, AnswerError, AnswerLoading } from "../../components/Answer";
import { UserChatMessage } from '../../components/UserChatMessage';
import GizmoComponentChangeBtn from '../GizmoComponentChangeBtn/GizmoComponentChangeBtn';
import { QuestionInput } from '../QuestionInput';
import styles from './GizmoComponentPrompt.module.css';

import { v4 as uuidv4 } from 'uuid'; // to generate a new thread_id for each chat session

interface Props {
    focusedComponent: boolean;
    setFocusedComponent: (componentName: number) => void;
    componentName: number;
    importSelectedCollections: CollectionName[];
    importSelectedPersona: PersonaTemplate;
    project: Project;
}
const maxInputLength = 8000;

const GizmoComponentPrompt: React.FC<Props> = ({ focusedComponent, componentName, setFocusedComponent, importSelectedCollections, importSelectedPersona, project }) => {
    const [openComponent, setOpenComponent] = useState(focusedComponent);
    const [openResultsComponent, setOpenResultsComponent] = useState(false);
    const [originalPrompt, setOriginalPrompt] = useState('');
    const { user, getAccessTokenSilently } = useAuth0();
    const [promptTemplate, setPromptTemplate] = useState<string>("");
    const [retrieveCount, setRetrieveCount] = useState<number>(10); // number of results to return from document collections
    const [retrieveWebCount, setRetrieveWebCount] = useState<number>(3); // number of web results to return
    const [retrievalMode, setRetrievalMode] = useState<RetrievalMode>(RetrievalMode.Hybrid);
    const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(true);
    const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState<boolean>(true);
    const [temperature, setTemperature] = useState<number>(0.6);
    const lastQuestionRef = useRef<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const [isChatLoading, setIsChatLoading] = useState<boolean>(false);
    const [error, setError] = useState<unknown>();
    const [activeCitation, setActiveCitation] = useState<string>();
    const [activeAnalysisPanelTab, setActiveAnalysisPanelTab] = useState<AnalysisPanelTabs | undefined>(undefined);
    const [selectedAnswer, setSelectedAnswer] = useState<number>(0);
    const [answers, setAnswers] = useState<[user: string, response: AskResponse, web: boolean, run_id: string][]>([]);
    const [inputLength, setInputLength] = useState<number>(0); // question input character limit
    const [personasList, setPersonasList] = useState<PersonaTemplate[]>([])
    const [selectedPersona, setSelectedPersona] = useState<PersonaTemplate>(personasList[0]);
    const [settingsButtonVisible, setSettingsButtonVisible] = useState<boolean>(false);
    const [NoRoleError, setNoRoleError] = useState<boolean>(false);
    const [tokensUsed, setTokensUsed] = useState<number | null>(null); // track tokens used
    const [tokenLimitReached, setTokenLimitReached] = useState<boolean>(false); // track token limit
    const [open, setOpen] = React.useState(false); // open the persona menu
    const [includeWebResults, setIncludeWebResults] = useState<boolean>(false); // set to false by default
    const [threadId, setThreadId] = useState(uuidv4());; // track the uuid thread_id for each chat session
    const [includeCompanyMemory, setIncludeCompanyMemory] = useState<boolean>(false); // track company memory
    const [includeUserMemory, setIncludeUserMemory] = useState<boolean>(false); // track user memory
    const [images, setImages] = useState<string[]>([]); // state for array of images up to 5
    const [followUpQuestionPrompt, setFollowUpQuestionPrompt] = useState<string>(""); // hold follow up question prompt
    const [promptHistory, setPromptHistory] = useState<string[]>([]);
    const [updateRecord, setUpdateRecord] = useState(false);
    const [selectedProject, setSelectedProject] = useState<Project>(project)
    // states for search type
    const [vectorSearch, setVectorSearch] = useState(false);
    const [semanticSearch, setSemanticSearch] = useState(false);
    const [hybridSearch, setHybridSearch] = useState(true); // default to hybrid

    // State for selected collections
    const [selectedCollections, setSelectedCollections] = useState<CollectionName[]>([]);
    // state for Search only mode
    const [searchOnlyMode, setSearchOnlyMode] = useState(false);
    const [hideImageUploader, setHideImageUploader] = useState(true); // hide image uploader by default
    const [disableWeb, setDisableWeb] = useState(false); // disable web results
    const [disableDocumentCollections, setDisableDocumentCollections] = useState(false); // disable document collections
    const [showCitationPanel, setShowCitationPanel] = useState(true); //citation panel show/hide
    const [refreshChatCollectionsList, setRefreshChatCollectionsList] = useState(false);

    // Load selected Endpoint from local storage - fallback to "langchain"
    const [selectedEndpoint, setSelectedEndpoint] = useState<string>(() => {
        const storedEndpoint = localStorage.getItem('selectedEndpoint');
        return storedEndpoint ? storedEndpoint : "langchain"; // default to langchain
    });

    // load selected gpt version from local storage - fallback to chat (gpt3) if blank
    const [gptVersion, setGptVersion] = useState<string>(() => {
        const storedVersion = localStorage.getItem('gptVersion');
        return storedVersion ? storedVersion : "gpt4-omni"; // chat is name of 3.5 model in portal
    });

    //update selected collections on change
    useEffect(() => {
        setSelectedCollections(importSelectedCollections);
    }, [selectedCollections, importSelectedCollections]);

    //update selected persona on change
    useEffect(() => {
        setSelectedPersona(importSelectedPersona);
    }, [selectedPersona, importSelectedPersona]);


    // Focus stuff
    useEffect(() => {
        setOpenComponent(focusedComponent);
    }, [focusedComponent])

    const handleFocusChange = () => {
        setFocusedComponent(componentName);
    };

    // fetch tokens used
    const fetchTokensUsed = async () => {
        if (user && user.name) {
            const accessToken = await getAccessTokenSilently(); // get access token
            const tokens = await getTokensSinceResetApi(user.name, accessToken);
            setTokensUsed(tokens);
        }
    };

    const onShowCitation = (citation: string, index: number) => {

        if (activeCitation === citation && activeAnalysisPanelTab === AnalysisPanelTabs.CitationTab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
            setShowCitationPanel(false);
        } else {
            setActiveCitation(citation);
            setActiveAnalysisPanelTab(AnalysisPanelTabs.CitationTab);
            setShowCitationPanel(true); // if not expanded, this will expand the panel to display the citation
        }
        setSelectedAnswer(index);
    };

    // Toggle citation panel
    const onToggleTab = (tab: AnalysisPanelTabs, index: number) => {
        if (activeAnalysisPanelTab === tab && selectedAnswer === index) {
            setActiveAnalysisPanelTab(undefined);
        } else {
            // setActiveAnalysisPanelTab(tab);
            setActiveAnalysisPanelTab(tab);
        }
        setSelectedAnswer(index);
    };

    //  set states based on persona
    const setStatesFromPersona = (selectedPersona: PersonaTemplate) => {
        setPromptTemplate(selectedPersona?.text ?? "");
        setIncludeCompanyMemory(String(selectedPersona?.company_memory) === 'true');
        setIncludeUserMemory(String(selectedPersona?.user_memory) === 'true');
        setVectorSearch(String(selectedPersona?.vector_search) === 'true');
        setSemanticSearch(String(selectedPersona?.semantic_search) === 'true');
        setHybridSearch(String(selectedPersona?.hybrid_search) === 'true');
        setSearchOnlyMode(String(selectedPersona?.search_only_mode) === 'true');
        setRetrieveWebCount(selectedPersona?.web_top_k ?? 3);
        setRetrieveCount(selectedPersona?.top_k ?? 10);
        setHideImageUploader(String(selectedPersona?.disable_image_uploads) === 'true');
        setDisableWeb(String(selectedPersona?.disable_web) === 'true');
        setDisableDocumentCollections(String(selectedPersona?.disable_document_collections) === 'true');
        setInputLength(selectedPersona?.input_character_limit ?? 8000);
        setFollowUpQuestionPrompt(selectedPersona?.follow_up_prompt ?? "");
        setUseSuggestFollowupQuestions(String(selectedPersona?.disable_follow_up_questions) === 'true');
    };

    // UseEffect to Update values when selectedPersona changes
    useEffect(() => {
        setStatesFromPersona(selectedPersona);
    }, [selectedPersona]);


    //fetch the project settings from saved record
    const setStatesFromProject = async (selectedProject: Project) => {
        const selectedAnswers = selectedProject.prompts; // get from storage
        try {
            const parsedAnswers = JSON.parse(selectedAnswers);
            setAnswers(parsedAnswers);
            setOriginalPrompt(parsedAnswers[0][0]);
        } catch (error) {
            console.error("Failed to parse project Answers as JSON: ", error);
        }
    }

    useEffect(() => {
        setStatesFromProject(selectedProject);
    }, [selectedProject]);

    // call the ai
    const makeApiRequest = async (question: string, original: boolean) => {
        lastQuestionRef.current = question;
        // clear fields
        error && setError(undefined);
        setIsChatLoading(true);
        setOpenResultsComponent(true);
        setOpenComponent(false);
        //add to the prompt history
        if (original) {
            setOriginalPrompt(question)
            setAnswers([]);
        }
        try {
            // build history
            const history: ChatTurn[] = answers.map(a => ({
                user: [{ type: 'text', text: a[0] }],
                bot: a[1].answer
            }));

            // build image messages
            const imageMessages = images.map(url => ({
                type: 'image_url' as const, image_url: { url }
            }));

            // build request with messages
            const request: ChatRequest = {
                history: [
                    ...history,
                    {
                        user: [
                            { type: 'text', text: question },
                            ...imageMessages
                        ],
                        bot: undefined
                    }
                ],
                approach: Approaches.ReadRetrieveRead, // default ReadRetrieveRead
                // map over collection_name from each item in selectedCollections array, to a new array of strings
                selectedIndexes: disableDocumentCollections ? [] : selectedCollections.map(collection => collection.collection_name),
                endpoint: selectedEndpoint, // default langchain
                user: user?.email ?? "unknown", // send user email if available
                threadId: threadId, // a uuid thread_id for each chat session
                companyMemory: Boolean(includeCompanyMemory), // boolean for company memory
                userMemory: Boolean(includeUserMemory), // boolean for user memory
                search_only_mode: Boolean(searchOnlyMode), // boolean for search only mode
                // Overrides are passed to the approach
                overrides: {
                    promptTemplate: promptTemplate.length === 0 ? undefined : promptTemplate,
                    followUpQuestionPrompt: followUpQuestionPrompt.length === 0 ? undefined : followUpQuestionPrompt,
                    excludeCategory: excludeCategory.length === 0 ? undefined : excludeCategory,
                    top: retrieveCount,
                    retrieveWebCount: retrieveWebCount, // number of web results to return
                    retrievalMode: retrievalMode,
                    semanticRanker: useSemanticRanker,
                    semanticCaptions: useSemanticCaptions,
                    disableFollowupQuestions: Boolean(false),
                    temperature: Number(temperature), // convert string to number
                    gpt_version: gptVersion,
                    webResults: Boolean(includeWebResults),
                    vectorSearch: Boolean(vectorSearch),
                    semanticSearch: Boolean(semanticSearch),
                    hybridSearch: Boolean(hybridSearch),
                }
            };
            const token = await getAccessTokenSilently(); // get token to pass to api
            const result = await chatApi(request, token); // call api and pass token
            // extract the run_id from the response
            const run_id = result.run_id;
            // Add response to array of answers
            setAnswers([...answers, [question, result, includeWebResults, run_id]]);
        } catch (e) {
            setError(e);
        } finally {
            setIsChatLoading(false);
            setUpdateRecord(true);
            fetchTokensUsed(); // update tokens used
        }
    };


    //update the recorded prompts when they change
    useEffect(() => {
        const updateRecordAsync = async () => {
            if (updateRecord) {
                console.log(answers);
                const accessToken = await getAccessTokenSilently();
                await updateProjectFieldApi(selectedProject.id, "prompts", JSON.stringify(answers), accessToken);
                setUpdateRecord(false);
            }
        };
    
        updateRecordAsync();
    }, [updateRecord, selectedProject]);

    return (
        <>

            <div className={styles.sectionFull} style={{ paddingTop: '0' }}>

                <div className="ms-Grid">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-lg4" id={!openComponent ? styles.Closed : ''}>
                            <h2 className={styles.gizmoComponentName} >Ask Anything</h2>
                            {openComponent ?
                                <p className={styles.gizmoComponentDescription}>Now it's time to ask the AI what you'd like to do. The best prompts for the AI have as much context as possible.</p>
                                : ''}
                        </div>
                        <div className="ms-Grid-col ms-lg8">
                            {openComponent ?
                                <>
                                    <p>max word count</p>
                                    <Slider defaultValue={80} min={5} max={200} />
                                    <div className={styles.chatInputContainer}>
                                        {tokenLimitReached ?
                                            <div className={styles.tokenLimitReached}>
                                                <ErrorCircle24Regular />
                                                <p >You have used your credit allowance.
                                                    <br />
                                                    Please contact your Super Admin to resolve this issue.
                                                </p>
                                            </div> : (
                                                <>

                                                    {/*chat area*/}
                                                    <div className={styles.chatInput}>
                                                        <QuestionInput
                                                            clearOnSend
                                                            placeholder={"Ask away..."}
                                                            disabled={isChatLoading}
                                                            onSend={question => makeApiRequest(question, true)}
                                                            inputLength={inputLength}
                                                            setInputLength={setInputLength}
                                                            maxInputLength={maxInputLength}
                                                            selectedPersona={selectedPersona}
                                                            originalPrompt={originalPrompt}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                    </div>


                                </> : <>

                                    <div className={styles.summaryContainer}>

                                        <h3 className={styles.summaryHeading}>Original Prompt:</h3>
                                        <p>{originalPrompt}</p>

                                    </div>
                                    <GizmoComponentChangeBtn setFocus={handleFocusChange} buttonText='Prompt' /><br></br>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.sectionFull}>

                <div className="ms-Grid">
                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-lg4" id={!openResultsComponent ? styles.Closed : ''}>
                            <h2 className={styles.gizmoComponentName}>Results</h2>
                            {openResultsComponent || originalPrompt ?
                                <>
                                    <p className={styles.gizmoComponentDescription}>Let's see the results...</p>
                                    <br></br>
                                    <p className={styles.gizmoComponentDescription}>Please make sure to check any important information.</p>
                                    <div className={styles.askFollowUpQuestion}>
                                        <NavLink to="/hello">
                                            <Button icon={<SaveRegular />}>Save and Close</Button>
                                        </NavLink>
                                        <h3>Ask follow up question?</h3>
                                        <QuestionInput
                                            clearOnSend
                                            placeholder={"Ask away..."}
                                            disabled={isChatLoading}
                                            onSend={question => makeApiRequest(question, false)}
                                            inputLength={inputLength}
                                            setInputLength={setInputLength}
                                            maxInputLength={maxInputLength}
                                            selectedPersona={selectedPersona}
                                            originalPrompt=""
                                        />
                                    </div>

                                </> : ''}
                        </div>
                        <div className="ms-Grid-col ms-lg8">
                            {openResultsComponent || originalPrompt ?
                                <>
                                    {/* RESULTS SECTION */}

                                    <div className={styles.chatPanel}>

                                        {/* Displayed When Empty */}
                                        {!originalPrompt ? (
                                            <div className={styles.chatEmptyState}>
                                                <div className={styles.chatEmptyStateIntro}>

                                                    <h3>No Results yet</h3>

                                                </div>
                                            </div>
                                        ) : (
                                            // Chat Stream
                                            <div className={styles.chatMessageStream}>
                                                <Accordion
                                                    collapsible={true}
                                                    multiple={true}
                                                    defaultOpenItems={answers.length > 0 ? [answers.length - 1] : []}
                                                >
                                                    {answers.map((answer, index) => (
                                                        <AccordionItem key={index} value={index}>
                                                            <AccordionHeader>
                                                                <UserChatMessage
                                                                    message={answer[0]}
                                                                />
                                                            </AccordionHeader>
                                                            <AccordionPanel>
                                                                <div className={styles.chatMessageGpt}>
                                                                    {/* Answer */}
                                                                    <Answer
                                                                        key={index}
                                                                        answer={answer[1]}
                                                                        isSelected={selectedAnswer === index && activeAnalysisPanelTab !== undefined}
                                                                        onCitationClicked={c => onShowCitation(c, index)}
                                                                        onThoughtProcessClicked={() => onToggleTab(AnalysisPanelTabs.ThoughtProcessTab, index)}
                                                                        onSupportingContentClicked={() => onToggleTab(AnalysisPanelTabs.SupportingContentTab, index)}
                                                                        onFollowupQuestionClicked={q => makeApiRequest(q, false)}
                                                                        showFollowupQuestions={useSuggestFollowupQuestions && answers.length - 1 === index}
                                                                        selectedIndexes={selectedCollections}
                                                                        settingsButtonVisible={settingsButtonVisible}
                                                                        webResultsIncluded={answer[2]}
                                                                        exportFollowupQuestions={() => { }}
                                                                    />
                                                                </div>
                                                            </AccordionPanel>
                                                        </AccordionItem>
                                                    ))}
                                                </Accordion>

                                                {/* loading indicator */}
                                                {isChatLoading && (
                                                    <>
                                                        {answers.length > 1 ?
                                                            <UserChatMessage message={lastQuestionRef.current} />
                                                            : ''}

                                                        <div className={styles.chatMessageGptMinWidth}>
                                                            <AnswerLoading />
                                                        </div>
                                                    </>
                                                )}
                                                {error ? (
                                                    // Error Message
                                                    <>
                                                        <UserChatMessage message={lastQuestionRef.current} />
                                                        <div className={styles.chatMessageGptMinWidth}>
                                                            <AnswerError error={error.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current, false)} />
                                                        </div>
                                                    </>
                                                ) : null}
                                                <div ref={chatMessageStreamEnd} />
                                            </div>
                                        )}
                                    </div>
                                </> : <>
                                    <p>No results yet.</p>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default GizmoComponentPrompt