export enum PowerLevel {
    Standard = "standard",
    Rapid = "rapid",
    Expanded = "expanded"
}

export const enum GptVersion {
    Gpt3Turbo = "gpt35-turbo",
    Gpt4o = "gpt4o",
    Gpt4Turbo = "gpt4-turbo"
}

export const enum Approaches {
    RetrieveThenRead = "rtr",
    ReadRetrieveRead = "rrr",
    ReadDecomposeAsk = "rda",
    Vanilla = "vanilla",
    LangChain = "langchain"
}

export interface CustomJwtPayload {
    permissions: string[];
}

export const enum Endpoints {
    Default = "chat",
    LangChain = "langchain",
    LangGraph = "langgraph"
}

export const enum RetrievalMode {
    Hybrid = "hybrid",
    Vectors = "vectors",
    Text = "text"
}

export type AskRequestOverrides = {
    retrievalMode?: RetrievalMode;
    semanticRanker?: boolean;
    semanticCaptions?: boolean;
    excludeCategory?: string;
    top?: number;
    temperature?: number;
    promptTemplate?: string;
    promptTemplatePrefix?: string;
    promptTemplateSuffix?: string;
    disableFollowupQuestions?: boolean;
    gpt_version?: string;
    webResults?: boolean;
    retrieveWebCount?: number;
    vectorSearch?: boolean;
    semanticSearch?: boolean;
    hybridSearch?: boolean;
    web_top_k?: number;
    followUpQuestionPrompt?: string;
    useGpt4oMini?: boolean;
};

export type AskRequest = {
    question: string;
    approach: Approaches;
    overrides?: AskRequestOverrides;
};

export type AskResponse = {
    answer: string;
    thoughts: string | null;
    data_points: SupportingContentItem[];
    error?: string;
    run_id: string;
};

type UserContent = { type: "text"; text: string } | { type: "image_url"; image_url: { url: string } };

export type ChatTurn = {
    user: UserContent[];
    bot?: string;
};

export type ChatRequest = {
    history: ChatTurn[];
    approach?: Approaches;
    overrides?: AskRequestOverrides;
    selectedIndexes?: string[] | string;
    gptVersion?: string;
    endpoint?: string;
    user: string;
    webResults?: boolean;
    retrieveWebCount?: number;
    threadId?: string;
    companyMemory?: boolean;
    userMemory?: boolean;
    vectorSearch?: boolean;
    semanticSearch?: boolean;
    hybridSearch?: boolean;
    search_only_mode?: boolean;
    web_top_k?: number;
};

export type IUploadFormData = {
    formData: FormData;
};

export type IUploadResponse = {
    success: string;
    message?: string;
};

// Prompt template / persona / gizmo / tool etc...
export type PersonaTemplate = {
    ask_anything_description?: string;
    color?: string;
    company_memory?: string;
    description?: string;
    disable_ask_anything?: string | boolean;
    disable_document_collections?: string | boolean;
    disable_follow_up_questions?: string | boolean;
    disable_image_uploads?: string;
    disable_in_less_than_x_words_section?: string | boolean;
    disable_web?: string | boolean;
    display_order?: number;
    document_collections_description?: string;
    endpoint?: string;
    follow_up_prompt?: string;
    hybrid_search?: boolean | string;
    icon?: string;
    iconName?: string;
    id: string;
    include_company_memory?: string | boolean;
    include_user_memory?: string | boolean;
    include_web_results?: string | boolean;
    input_character_limit?: number;
    long_description?: string;
    name: string;
    prompt_helper_prompt?: string;
    question1?: string;
    question2?: string;
    question3?: string;
    question4?: string;
    response_character_limit?: number;
    search_only_mode?: string | boolean;
    semantic_search?: boolean | string;
    template_category: string;
    temperature?: string;
    text: string;
    top_k?: number;
    use_suggest_followup_questions?: string | boolean;
    use_gpt4o_mini?: string | boolean;
    user_memory?: string;
    vector_search?: boolean | string;
    web_top_k?: number;
};

// Define the tags interface here
export interface SupportingContentItemPartitionTags {
    __document_id: string[];
    __file_type: string[];
    __file_id: string[];
    __file_part: string[];
    __part_n: string[];
    __sect_n: string[];
}

// Define the Partition interface here
export interface SupportingContentItemPartition {
    text: string;
    relevance: number;
    partitionNumber: number;
    sectionNumber: number;
    lastUpdate: string;
    tags: SupportingContentItemPartitionTags;
}

// Define the SupportingContentItem interface here
export interface SupportingContentItem {
    documentId: string;
    fileId: string;
    index: string;
    link: string;
    partitions: SupportingContentItemPartition[];
    sourceContentType: string;
    sourceName: string;
    sourceUrl: string;
}

// New Collections Form
export interface NewCollectionForm {
    file: File[] | File | null;
    index: string;
    documentId?: string;
    tags?: string[];
    displayName?: string;
}

// Interface for the new CollectionName objects from cosmos
export interface CollectionName {
    id: string;
    display_name: string;
    user_id: string;
    collection_name: string;
    entry_date: string;
    category: string;
    description: string;
}

// interface for a selected file in the datagrid
export interface SelectedFile {
    id: string;
    indexName: string;
    document_id: string;
}

export interface FileStatusItem {
    file: {
        label: string;
        icon: JSX.Element;
        id: string;
    };
    documentId: string;
    index_name: string;
    status: string;
    lastUpdated: {
        label: string;
        timestamp: Date; // Changed from number to Date
    };
}

export type FileCell = {
    label: string;
    icon: JSX.Element;
    id: string;
    documentId: string;
};

export type LastUpdatedCell = {
    label: string;
    timestamp: number;
};

export type DataGridItem = {
    file: FileCell;
    lastUpdated: LastUpdatedCell;
    status: string;
    documentId: string;
    number: number;
};

// Type for the response from the backend when renaming a collection
export type RenameCollectionResponse = {
    success: boolean;
    message?: string;
};

// Response for upload-status
export type UploadStatusApiResponse = {
    completed: boolean;
    completed_steps: string[];
    creation: string;
    document_id: string;
    empty: boolean;
    failed: boolean;
    index: string;
    last_update: string;
    remaining_steps: string[];
    steps: string[];
    tags: Record<string, unknown>;
};

//Project settings
export type Project = {
    id: string;
    project_name: string;
    project_description: string;
    owner: string;
    project_tool_name: string;
    project_tool_id: string;
    project_collections: string;
    last_updated: string;
    prompts: string;
    results: string[];
};

// TemplateCategory comes from cosmos db
export type TemplateCategory = {
    id: string;
    category_name: string;
    description: string;
    created_at: string;
    last_updated: string;
    color: string;
};

// type for collection category
export type CollectionCategory = {
    id: string;
    category_name: string;
    description: string;
    created_at: string;
    last_updated: string;
    color: string;
};

// index file status item - used when displaying the files from the indexer db query
export interface IndexFileStatusItem {
    id: string;
    name: string;
    created_by: {
        display_name: string;
        email: string;
    };
    created_date_time: string;
    last_modified_by: {
        display_name: string;
        email: string;
    };
    last_modified_date_time: string;
    download_url: string;
    status: string;
    collection_name: string;
}

// Interface for collections with external data connections
export type ExternalDataCollection = {
    id: string;
    display_name: string;
    user_id: string;
    collection_name: string;
    entry_date: string;
    site_id: string;
    drive_id: string;
    folder_id: string;
};
