// this will be similar to the CreateNewIndex except it will accept the extra fields for the external source
// External connections info
// 1. site_id = 'blueedge365.sharepoint.com,19def808-c255-4bea-bf65-a8838c6d0f99,cd31eb49-9c38-4076-a3a1-a464a1df510c'
// 2. drive_id = "b!CPjeGVXC6ku_ZaiDjG0PmUnrMc04nHZAo6GkZKHfUQyQQ_3ki9huSY0-YCyzV6H3"
// 3. folder_id = '012XM74OFNATW23T3HMZA2MHHLQFTHBI5S'


import { useAuth0 } from '@auth0/auth0-react';
import { IconButton, MessageBar, MessageBarType, Stack, TextField } from '@fluentui/react';
import { Button, Dialog, DialogActions, DialogContent, DialogSurface, DialogTrigger, Text } from '@fluentui/react-components';
import { CloudAdd24Regular, Dismiss24Regular } from '@fluentui/react-icons';
import { useEffect, useState } from 'react';
import { createCollectionApi } from '../../api';
import styles from './CreateExternalSource.module.css';

interface ErrorResponse {
    response?: {
        data?: {
            message?: string;
        };
    };
    message?: string;
}

interface CreateExternalSourceProps {
    get_external_collections: () => void;
}


const CreateExternalSource: React.FC<CreateExternalSourceProps> = ({ get_external_collections }) => {
    const { getAccessTokenSilently, user } = useAuth0();
    const [ showSuccessMessage, setShowSuccessMessage ] = useState<boolean>(false);
    const [ showErrorMessage, setShowErrorMessage ] = useState<boolean>(false);
    const [ errorMessage, setErrorMessage ] = useState<string | null>(null); // the error itself
    const [ indexName, setIndexName ] = useState<string>('');
    const [ isProcessing, setIsProcessing ] = useState(false);
    const [ displayName, setDisplayName ] = useState<string>(''); // display name [optional]
    const [ isDialogOpen, setIsDialogOpen ] = useState(false);
    const [ driveId, setDriveId ] = useState<string>('');
    const [ folderId, setFolderId ] = useState<string>('');
    const [ siteId, setSiteId ] = useState<string>('');

    const handleOpenDialog = () => {
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setIndexName('');
        setDisplayName('');
        setSiteId('');
        setDriveId('');
        setFolderId('');
    };



    // useEffect to reload page after creation
    useEffect(() => {
        if (showSuccessMessage) {
            handleCloseDialog(); // close dialog
        }
    }, [ showSuccessMessage ]);

    // handle create index
    const handleCreateIndex = async () => {
        setIsProcessing(true);
        try {
            const userTag = user?.name?.toString() || 'unknown'; // create a userTag with the user.name from auth0
            const token = await getAccessTokenSilently();

            const formData = new FormData(); // create new form

            formData.append('index', indexName);
            formData.append('displayName', displayName);
            formData.append('user', userTag);
            formData.append('site_id', siteId);
            formData.append('drive_id', driveId);
            formData.append('folder_id', folderId);

            await createCollectionApi(formData, token);
            get_external_collections();

            setShowSuccessMessage(true); // if successful, show success message
            // time out for 5 seconds
            setTimeout(() => {
                setShowSuccessMessage(false);
            }, 5000);
        } catch (error) {
            const typedError = error as ErrorResponse;
            console.error("Error at [createNewIndex]", typedError);
            // Extract the error message from the error object and set it in the state
            setErrorMessage(typedError.response?.data?.message || typedError.message || 'An unexpected error occurred');
            setShowErrorMessage(true); // if failed, show error message
        } finally {
            setIsProcessing(false);
            get_external_collections();
            setTimeout(() => {
                setShowSuccessMessage(false);
                setShowErrorMessage(false);
            }, 5000);
        }
    }



    // handleDisplayNameChange function modification
    const handleDisplayNameChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string
    ) => {
        if (newValue !== undefined) {
            // Update the display name state
            setDisplayName(newValue);

            // Convert to lowercase
            let backendName = newValue.toLowerCase();
            // Replace invalid characters with a dash
            backendName = backendName.replace(/[^a-z0-9-]/g, '-');
            // Trim leading and trailing dashes
            backendName = backendName.replace(/^-+|-+$/g, '');

            // Also update the real name (indexName) state to reflect these changes
            setIndexName(backendName);
        }
    };

    const handleSiteIdChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setSiteId(newValue || '');
    };

    const handleDriveIdChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setDriveId(newValue || '');
    };

    const handleFolderIdChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setFolderId(newValue || '');
    };

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            alert('Copied to clipboard!');
        }, (err) => {
            console.error('Could not copy text: ', err);
        });
    };



    // Real name must be lowercase letters, dashes, and numbers only!
    const isValidName = /^[a-z0-9-]+$/.test(indexName);



    return (
        <Dialog
            modalType='non-modal'
            open={isDialogOpen}
        >
            <DialogTrigger disableButtonEnhancement>
                <Button
                    appearance='primary'
                    icon={<CloudAdd24Regular />}
                    title='Add External Source'
                    onClick={handleOpenDialog}
                />
            </DialogTrigger>

            <DialogSurface>
                <DialogContent>
                    <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                        <Text size={500} weight='semibold'>Add an External data Source</Text>
                        <Button appearance="transparent" onClick={handleCloseDialog} icon={<Dismiss24Regular />} />
                    </Stack>
                    <br />
                    <div className={styles.dialogContainer}>
                        {showSuccessMessage && (
                            <MessageBar
                                messageBarType={MessageBarType.success}
                                isMultiline={false}
                                dismissButtonAriaLabel="Close"
                                onDismiss={() => setShowSuccessMessage(false)}
                            >
                                Collection created successfully!
                            </MessageBar>
                        )}
                        {showErrorMessage && (
                            <MessageBar
                                messageBarType={MessageBarType.error}
                                isMultiline={false}
                                dismissButtonAriaLabel="Close"
                                onDismiss={() => setShowSuccessMessage(false)}
                            >
                                Failed to create Collection.
                                {errorMessage}
                            </MessageBar>
                        )}

                        {/* Collection options container */}
                        <div className={styles.collectionOptionsContainer}>
                            {/* Display Name */}
                            <div>
                                <TextField
                                    label="Display Name. 32 characters max"
                                    placeholder="Display Name"
                                    onChange={handleDisplayNameChange}
                                />
                                <p>{indexName}</p>
                            </div>

                            {/* site_id */}
                            <div>

                                <TextField
                                    label="Site ID"
                                    placeholder="Site ID"
                                    value={siteId}
                                    onChange={handleSiteIdChange}
                                />
                                <MessageBar messageBarType={MessageBarType.info}>
                                    Example: yourcompany.sharepoint.com
                                </MessageBar>
                                {/* TODO: Remove before Production */}
                                <div>
                                    <MessageBar messageBarType={MessageBarType.warning}>
                                        This will be removed in production. Only available in development. Use the button below to copy and paste real data into Site ID.
                                    
                                    <MessageBar messageBarType={MessageBarType.info}>
                                        Working Example: blueedge365.sharepoint.com
                                        <IconButton
                                            text="Copy"
                                            iconProps={{ iconName: 'Copy' }}
                                            onClick={() => copyToClipboard('blueedge365.sharepoint.com')}
                                            style={{ marginLeft: '10px' }}
                                        />
                                    </MessageBar></MessageBar>
                                </div>
                            </div>
                            <div>
                                <TextField
                                    label="Drive ID"
                                    placeholder="Drive ID"
                                    value={driveId}
                                    onChange={handleDriveIdChange}
                                />
                                <MessageBar messageBarType={MessageBarType.info}>
                                    Starts with: b!...
                                </MessageBar>
                                {/* TODO: Remove before Production */}
                                <div>
                                    <MessageBar messageBarType={MessageBarType.warning}>
                                        This will be removed in production. Only available in development. Use the button below to copy and paste real data into Drive ID.
                                    
                                    <MessageBar messageBarType={MessageBarType.info}>
                                        Working Example: b!CPjeGVXC6ku_ZaiDjG0PmUnrMc04nHZAo6GkZKHfUQyQQ_3ki9huSY0-YCyzV6H3
                                        <IconButton
                                            text="Copy"
                                            iconProps={{ iconName: 'Copy' }}
                                            onClick={() => copyToClipboard('b!CPjeGVXC6ku_ZaiDjG0PmUnrMc04nHZAo6GkZKHfUQyQQ_3ki9huSY0-YCyzV6H3')}
                                            style={{ marginLeft: '10px' }}
                                        />
                                    </MessageBar></MessageBar>
                                </div>
                            </div>
                            <div>
                                <TextField
                                    label="Folder ID"
                                    placeholder="Folder ID"
                                    value={folderId}
                                    onChange={handleFolderIdChange}
                                />
                                <p>This will be the root of the collection</p>
                                <MessageBar messageBarType={MessageBarType.info}>
                                    Example: 012XM74OFNATW23T3HMZA2MHHLQFTHBI5S
                                </MessageBar>
                                {/* TODO: Remove before Production */}
                                <div>
                                    <MessageBar messageBarType={MessageBarType.warning}>
                                        This will be removed in production. Only available in development. Use the button below to copy and paste real data into Folder ID.
                                    
                                    <MessageBar messageBarType={MessageBarType.info}>
                                        Working Example: 012XM74OERWJFJZ4F3GZBKOK7CNXDLVLIL
                                        <IconButton
                                            text="Copy"
                                            iconProps={{ iconName: 'Copy' }}
                                            onClick={() => copyToClipboard('012XM74OERWJFJZ4F3GZBKOK7CNXDLVLIL')}
                                            style={{ marginLeft: '10px' }}
                                        />
                                    </MessageBar></MessageBar>
                                </div>
                            </div>
                        </div>

                        <br />

                        <DialogActions
                            as='div'
                            position='end'
                            fluid  // make the div take up the full width
                            className={styles.buttonsContainer}
                        >
                            {/* Submit - Disabled if no file or name not valid */}
                            <Button
                                onClick={handleCreateIndex}
                                disabled={!isValidName || isProcessing || !indexName || !siteId || !driveId || !folderId}
                                appearance='primary'
                            >
                                {isProcessing ? "Processing..." : "Create Collection"}
                            </Button>

                            {/* Close */}
                            <Button
                                onClick={handleCloseDialog}
                            >
                                Cancel
                            </Button>
                        </DialogActions>

                    </div >
                </DialogContent>
            </DialogSurface>
        </Dialog>

    );
}


export default CreateExternalSource;